var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"divider",style:({
    width: 'auto',
    height: _vm.height + 'px',
    backgroundColor: _vm.bgColor,
    marginTop: _vm.top + 'px',
    marginLeft: '-' + _vm.left + 'px',
    marginRight: '-' + _vm.right + 'px'
})})}
var staticRenderFns = []

export { render, staticRenderFns }