<template>
    <div class="orders">
        <div class="title">订单列表</div>
        <Button
            class="download-btn"
            type="primary"
            title="下载订单列表到本地"
            @click="downFile"
        >
            下载订单
        </Button>

        <Table
            class="order-table"
            border
            stripe
            :context="self"
            :columns="columns"
            :data="list"
        />

        <div v-if="list && list.length > 0 && total > rn" class="list-page">
            <Page
                :current="pn + 1"
                :total="total"
                :page-size="rn"
                show-total
                @on-change="onPageChange"
            />
        </div>
    </div>
</template>

<script>
import {downxls} from '@/utils/utils';

export default {
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        total: {
            type: [Number, String],
            default: 0
        },
        pn: {
            type: [Number, String],
            default: 0
        },
        rn: {
            type: [Number, String],
            default: 10
        }
    },
    components: {
    },
    data() {
        return {
            self: this,
            columns: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '订单号',
                    key: 'out_trade_no',
                    minWidth: 200,
                    align: 'center'
                },
                {
                    title: '用户头像',
                    key: 'src',
                    width: 100,
                    align: 'center',
                    render: (h, params) => {
                        const {src} = params.row.userInfo;
                        return h('div', {
                            class: 'list-photo'
                        }, [
                            src ? h('img', {
                                class: 'list-photo-img',
                                attrs: {
                                    src: src
                                }
                            }) : ''
                        ]);
                    }
                },
                {
                    title: '用户昵称',
                    key: 'nickname',
                    minWidth: 150,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {}, params.row.userInfo.nickname);
                    }
                },
                {
                    title: '所属团长',
                    key: 'zhangOpenid',
                    minWidth: 200,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {}, params.row.tzName || '无');
                    },
                    render: (h, params) => {
                        const {tzId, tzName} = params.row;
                        return h('span', {
                            class: `${tzName ? 'link' : ''}`,
                            on: {
                                click: () => {
                                    this.pushPage(tzId);
                                }
                            }
                        }, tzName || '无');
                    }
                },
                {
                    title: '店铺名称',
                    key: 'storeName',
                    minWidth: 250,
                    align: 'center'
                },
                {
                    title: '余额支付(元)',
                    key: 'youhui',
                    minWidth: 120,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {
                            class: 'table-total'
                        }, params.row.yue || 0);
                    }
                },
                {
                    title: '实际支付(元)',
                    key: 'total',
                    minWidth: 120,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {
                            class: 'table-total'
                        }, params.row.total);
                    }
                },
                {
                    title: '订单金额(元)',
                    key: 'shouldPay',
                    minWidth: 120,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {
                            class: 'table-total'
                        }, params.row.shouldPay);
                    }
                },
                {
                    title: '平台折扣金额(元)',
                    key: 'discountAmount',
                    minWidth: 150,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {
                            class: 'table-total'
                        }, params.row.discountAmount);
                    }
                },
                {
                    title: '优惠卷(元)',
                    key: 'youhui',
                    minWidth: 120,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {
                            class: 'table-total'
                        }, params.row.manjian ? params.row.youhui : 0);
                    }
                },
                {
                    title: '订单类型',
                    key: 'qianggou',
                    minWidth: 120,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {
                            class: `${params.row.qianggou ? 'table-total' : ''}`
                        }, params.row.qianggou ? '抢购订单' : '普通订单');
                    }
                },
                {
                    title: '下单时间',
                    key: 'dateTime',
                    minWidth: 190,
                    align: 'center'
                },
                {
                    title: '订单状态',
                    key: 'status',
                    align: 'center',
                    width: 110,
                    fixed: 'right',
                    render: (h, params) => {
                        const {qianggou, status} = params.row;
                        return h('Tag', {
                            props: {
                                color: `${status === '1' ? 'primary' : (status === '2' ? 'success' : 'error')}`
                            }
                        }, `${qianggou ? (status === '1' ? '待核销' : (status === '2' ? '已核销' : '已退款')) : (status === '1' ? '待支付' : (status === '2' ? '已支付' : '已退款'))}`)
                    }
                }
            ]
        }
    },
    computed: {
    },
    watch: {
    },
    mounted() {
    },
    methods: {
        onPageChange(pn) {
            this.$emit('pageChange', pn - 1);
        },
        downFile() {

            // 下载订单到本地
            downxls(this.list);
        },
        pushPage(id) {
            if (!id) {
                return;
            }
            this.$router.push({
                path: `/tuan/tuanDetail?id=${id}`
            });
        }
    }
}
</script>

<style lang="less" scoped>
    .orders {
        position: relative;
        margin-top: 20px;
    }
    .download-btn {
        position: absolute;
        right: 0;
        top: -7px;
    }

    .list-page {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }

    ::v-deep {
        .list-photo {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 40px;
            overflow: hidden;
            margin: 10px 0 4px;
            box-sizing: initial;
            border-radius: 5px;

            &-img {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                height: auto;
                border-radius: 5px;
            }
        }
        .table-total {
            color: #f65b1a;
            font-weight: bold;
        }
        .red {
            color: #f65b1a;
            font-weight: bold;
        }
        .fuhao {
            position: relative;
            top: -2px;
            font-size: 26px;
        }
        .link {
            color: #19be6b;
                font-weight: bold;
                cursor: pointer;
        }
    }
</style>
