<template>
    <div class="add-container">
        <Form
            class="add-form"
            ref="formItem"
            :model="formItem"
            :rules="ruleValidate"
            :label-width="150"
            label-position="right"
        >
            <Form-item prop="name" label="类型名称">
                <Input v-model="formItem.name" placeholder="请输入类型名称" />
            </Form-item>
            <Form-item prop="icon" label="图标">
                <CUpload
                    :default-list="formItem.icon"
                    :maxLength="1"
                    :size="300"
                    sizeTip
                    @change="onChange"
                />
            </Form-item>
            <Form-item prop="sort" label="排序">
                <Input v-model.number="formItem.sort" type="number" placeholder="请输入排序值" />
            </Form-item>
            <Form-item>
                <Button
                    class="add-submit"
                    type="primary"
                    size="large"
                    @click="handleSubmit"
                >
                    提交
                </Button>
            </Form-item>
        </Form>
    </div>
</template>

<script>
import CUpload from '@/components/upload/index';

export default {
    name: 'AddType',
    components: {
        CUpload
    },
    data() {
        const confirmIcon = (rule, value, callback) => {
            if (this.formItem.icon.length > 0) {
                callback();
            }
            else {
                callback(new Error('图标不能为空'));
            }
        };

        return {
            formItem: {
                name: '',
                icon: [],
                sort: 0
            },
            ruleValidate: {
                name: [
                    {required: true, message: '请输入店铺类型', trigger: 'change'}
                ],
                icon: [
                    {required: true, validator: confirmIcon, trigger: 'blur'}
                ]
            },
            cacheData: null
        }
    },
    mounted() {
        // 保存一份默认数据，方便添加完成后恢复到默认数据
        this.cacheData = JSON.parse(JSON.stringify(this.formItem));
        const {id} = this.$route.query;
        if (id) {
            this.$axios({
                url: '/api/storeType/list',
                method: 'post',
                data: {
                    id
                }
            }).then(res => {
                if (res.data.list.length === 0) {
                    return;
                }
                this.formItem = res.data.list[0];
            }).catch(() => {});
        }
    },
    methods: {
        handleSubmit() {
            this.$refs['formItem'].validate(valid => {
                if (!valid) {
                    this.$Message.error('存在填写错误，请检查');
                    return;
                }

                this.$axios({
                    url: '/api/storeType/addType',
                    method: 'post',
                    data: this.formItem
                }).then(res => {
                    if (res.data.code === 0) {
                        return;
                    }

                    this.$router.push({
                        name: 'TypeList',
                        params: {
                            pn: this.$route.query.pn
                        }
                    });

                    this.$Message.success(res.data.message);
                }).catch(() => {});
            })
        },
        onChange(value) {
            this.formItem.icon = value;
        }
    }
}
</script>

<style lang="less" scoped>
    .add-form {
        margin-top: 20px;

        .add-submit {
            width: 150px;
        }
    }
</style>
