<template>
    <div class="list-container">

        <!-- 营业状况 -->
        <div class="flexbox flexbox-align">
            <div class="flexbox-auto">
                <div class="flexbox trade-status">

                    <div v-if="todayTrade" class="flexbox-fixed trade-status-list">
                        <div class="trade-status-list-box list1">
                            <div class="flexbox flexbox-between trade-status-list-title">
                                <div>今日营业额</div>
                                <div class="trade-status-list-title-money">{{ todayTrade.total }}元</div>
                            </div>

                            <div class="trade-status-list-info">
                                <p>订单数量: {{ todayTrade.orderNumber }}个</p>
                                <p>店内优惠券: {{ todayTrade.inStoreCoupon }}个</p>
                                <p>平台优惠券: {{ todayTrade.coupon }}个</p>
                            </div>
                        </div>
                    </div>

                    <div v-if="allTrade" class="flexbox-fixed trade-status-list">
                        <div class="trade-status-list-box list2">
                            <div class="flexbox flexbox-between trade-status-list-title">
                                <div>总营业额</div>
                                <div class="trade-status-list-title-money">{{ allTrade.total }}元</div>
                            </div>

                            <div class="trade-status-list-info">
                                <p>订单数量: {{ allTrade.orderNumber }}个</p>
                                <p>店内优惠券: {{ allTrade.inStoreCoupon }}个</p>
                                <p>平台优惠券: {{ allTrade.coupon }}个</p>
                            </div>
                        </div>
                    </div>

                    <div v-if="cashout" class="flexbox-fixed trade-status-list">
                        <div class="trade-status-list-box list3">
                            <div class="flexbox flexbox-between trade-status-list-title">
                                <div>已提现</div>
                                <div class="trade-status-list-title-money">{{ cashout.total }}元</div>
                            </div>

                            <div class="trade-status-list-info">
                                <p>今日提现: {{ cashout.todayTotal }}元</p>
                                <p>本周提现: {{ cashout.weekTotal }}元</p>
                                <p>本月提现: {{ cashout.monthTotal }}元</p>
                            </div>
                            <!-- <Button class="cashout-btn" shape="circle" @click="jumpCash">提现</Button> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="flexbox-fixed">
                <div class="ewm">
                    <img
                        v-if="qrcodeUrl"
                        class="ewm-img"
                        :src="qrcodeUrl"
                        title="点击下载二维码"
                        @click="downloadEwm"
                    />
                </div>
            </div>
        </div>

        <Divider />

        <div class="home-count">
            <div class="home-title">营收统计</div>
            <Form
                class="search-form"
                ref="searchItem"
                :model="searchItem"
                :label-width="70"
                label-position="left"
            >
                <Form-item prop="time" label="筛选范围">
                    <Select
                        v-model="searchItem.time"
                        class="search-select"
                        placeholder="请选择筛选范围"
                        @on-change="onSelect"
                    >
                        <Option
                            v-for="(item, index) in timeList"
                            :value="item.value"
                            :key="item.text + index"
                        >{{ item.text }}</Option>
                    </Select>
                </Form-item>
            </Form>
            <div class="home-count-content">
                <div id="echart"></div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import Divider from '@/components/divider/index';
import {drawImageAndText} from '@/utils/utils';

export default {
    name: 'StoreHome',
    components: {
        Divider
    },
    data() {
        return {
            todayTrade: null,
            allTrade: null,
            cashout: null,
            qrcodeUrl: '',
            searchItem: {
                time: 1
            },
            timeList: [
                {
                    value: 1,
                    text: '今天'
                },
                {
                    value: 2,
                    text: '本周'
                },
                {
                    value: 3,
                    text: '本月'
                },
                {
                    value: 4,
                    text: '今年'
                },
                {
                    value: 5,
                    text: '全部'
                }
            ],
            xAxis: [],
            series: []
        }
    },
    computed: {
        account() {
            return this.$store.state.account;
        },
        getStoreData() {
            return this.$store.state.storeData;
        }
    },
    watch: {
        getStoreData: {
            immediate: true,
            async handler(storeData) {
                if (!storeData) return;
                console.log('storeData', storeData);
            }
        }
    },
    mounted() {
        console.log('searchItem', this.searchItem);
        console.log('timeList', this.timeList);
        // this.getData();
        this.$axios.all([this.getData(), this.getCount(), this.getQRCode()]).then(res => {
            console.log('promise.all', res);
            if (res.length === 0) {
                return;
            }
            if (res[0] && res[0].data.list && res[0].data.list[0]) {
                const {todayTrade, allTrade, cashout} = res[0].data.list[0];
                this.todayTrade = todayTrade;
                this.allTrade = allTrade;
                this.cashout = cashout;
            }

            if (res[1] && res[1].data.list && res[1].data.list[0]) {
                res[1].data.list.forEach(item => {
                    this.xAxis.push(item.time);
                    this.series.push(item.total);
                });
                this.initEchart();
            }

            if (res[2] && res[2].data.src) {
                this.qrcodeUrl = res[2].data.src;
            }
        });
    },
    methods: {
        async onSelect() {
            console.log('value', this.searchItem);
            const counts = await this.getCount();
            console.log('counts', counts);
            if (counts.data.list && counts.data.list[0]) {
                this.xAxis = [];
                this.series = [];
                counts.data.list.forEach(item => {
                    this.xAxis.push(item.time);
                    this.series.push(item.total);
                });
                this.initEchart();
            }
        },
        getData() {
            return this.$axios({
                url: '/api/storeSetup/storeHome',
                method: 'post',
                data: {
                    storeId: this.getStoreData.id
                }
            });
        },
        getCount() {
            return this.$axios({
                url: '/api/storeSetup/count',
                method: 'post',
                data: Object.assign({}, this.searchItem, {
                    storeId: this.getStoreData.id
                })
            });
        },

        // 初始化图标
        initEchart() {
            let myChart = echarts.init(document.getElementById('echart'));

            myChart.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                legend: {
                    data: ['收益统计(元)']
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.xAxis,
                    // data: ['2023-03-09', '2023-03-10', '2023-03-11', '2023-03-12', '2023-03-13'],
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: '收益统计(元)',
                        data: this.series,
                        // data: [0, 0.32, 0.03, 0.01, 0.05],
                        type: 'line',
                        areaStyle: {}
                    }
                ]
            });
        },
        jumpCash() {
            this.$router.push({
                path: '/capital/finance'
            });
        },
        getQRCode() {
            return this.$axios({
                url: '/api/QRCode',
                method: 'post',
                data: {
                    path: 'pages/buy/buy',
                    scene: this.getStoreData.id
                }
            });
        },
        downloadEwm() {
            drawImageAndText(this.qrcodeUrl, this.getStoreData.name);
        }
    }
}
</script>

<style lang="less" scoped>
    .trade-status {
        width: 100%;
        align-items: stretch;
        
        .trade-status-list {
            width: 33.33333333%;
        }
        .trade-status-list-box {
            position: relative;
            height: 100%;
            color: #fff;
            border-radius: 10px;
            padding: 15px 25px;
            margin: 0 10px;
            overflow: hidden;
            box-sizing: border-box;
        }
        .list1 {
            background: linear-gradient(to right, #4b9fff , #75b5fb);
        }
        .list2 {
            background: linear-gradient(to right, #24dcb3 , #50dfc3);
        }
        .list3 {
            background: linear-gradient(to right, #ffb64f , #ffc675);
        }
        .trade-status-list-title {
            font-size: 24px;
            line-height: 34px;
            font-weight: bold;
        }
        .trade-status-list-title-money {
            margin-left: 60px;
        }

        .trade-status-list-info {
            margin-top: 10px;
            font-weight: bold;

            & p {
                margin-top: 5px;
            }
        }
        .cashout-btn {
            position: absolute;
            right: 15px;
            bottom: 15px;
        }
    }
    .ewm {
        width: 140px;
        height: 140px;
        margin: 0 15px;

        &-img {
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }
    .home-title {
        font-size: 18px;
        font-weight: bold;
        line-height: 1;
        padding-bottom: 20px;
    }
    .home-count {
        margin-top: 20px;
    }
    .home-count-content {
        height: 400px;
    }
    .home-count-content #echart {
        width: 100%;
        height: 100%;
    }
    .search-form {
        padding: 0 15px;

        .search-select {
            width: 250px;
        }
    }
</style>
