<template>
    <div class="list-container">
        <Filters
            showTime
            show-input
            placeholder="请输入推荐员名称"
            @search="searchFun"
        />

        <Divider :top="0" />

        <div class="list">
            <div class="title">推荐员列表</div>

            <Table
                border
                stripe
                :context="self"
                :columns="columns"
                :data="list"
            />

            <div v-if="list && list.length > 0 && total > rn" class="list-page">
                <Page
                    :current="pn + 1"
                    :total="total"
                    :page-size="rn"
                    show-total
                    @on-change="onPageChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Divider from '@/components/divider/index';
import Filters from '../components/filters';

export default {
    name: 'Tuijianyuan',
    components: {
        Divider,
        Filters
    },
    data() {
        return {
            self: this,
            searchItem: {
                query: '',
                time: []
            },
            columns: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '推荐员编号',
                    key: 'number',
                    align: 'center',
                    minWidth: 110,
                    render: (h, params) => {
                        return h('span', {
                            class: 'list-number'
                        }, params.row.number);
                    }
                },
                {
                    title: '推荐员头像',
                    key: 'src',
                    width: 110,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'list-photo'
                        }, [
                            params.row.src ? h('img', {
                                class: 'list-photo-img',
                                attrs: {
                                    src: params.row.src
                                }
                            }) : ''
                        ]);
                    }
                },
                {
                    title: '推荐员名称',
                    key: 'nickname',
                    align: 'center',
                    minWidth: 200
                },
                // {
                //     title: '推荐员ID',
                //     key: 'openid',
                //     align: 'center',
                //     minWidth: 280
                // },
                {
                    title: '推荐员收入(元)',
                    key: 'moneySum',
                    align: 'center',
                    minWidth: 120,
                    render: (h, params) => {
                        return h('span', {
                            class: 'table-total'
                        }, params.row.moneySum);
                    }
                },
                {
                    title: '店铺总流水(元)',
                    key: 'allLiushui',
                    align: 'center',
                    minWidth: 120,
                    render: (h, params) => {
                        return h('span', {
                            class: 'table-total'
                        }, params.row.allLiushui);
                    }
                },
                {
                    title: '详情',
                    key: 'action',
                    align: 'center',
                    width: 100,
                    fixed: 'right',
                    render: (h, params) => {
                        return h('Button', {
                            class: 'ivu-btn-primary ivu-btn-small class-btn',
                            on: {
                                click: () => {
                                    this.tuijianyuanPush(params.row);
                                }
                            }
                        }, '查看')
                    }
                }
            ],
            list: [],
            total: 0,
            pn: 0,
            rn: 10
        }
    },
    computed: {
    },
    mounted() {
        this.getData(this.pn);
    },
    methods: {
        searchFun(options) {
            this.searchItem = options;
            this.pn = 0;
            this.getData(this.pn);
        },
        onPageChange(pn) {
            console.log('onPageChange => pn', pn);
            this.pn = pn - 1;
            this.getData(this.pn);
        },
        getData(pn) {
            this.$axios({
                url: '/api/capital/tuijianyuan/list',
                method: 'post',
                data: Object.assign({}, this.searchItem, {
                    time: JSON.stringify(this.searchItem.time),
                    pn,
                    rn: this.rn
                })
            }).then(res => {
                const {list, total} = res.data;
                this.list = list;
                if (+this.total !== +total) {
                    this.total = total;
                }
            }).catch(() => {});
        },
        tuijianyuanPush(data) {
            this.$router.push({
                path: '/capital/tuijianyuanDetail',
                query: {
                    number: data.number,
                    time: JSON.stringify(this.searchItem.time)
                }
            });
        }
    }
}
</script>

<style lang="less" scoped>
    .list {
        position: relative;
        margin-top: 20px;
    }
    .list-page {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }
    ::v-deep {
        .list-number {
            color: #19be6b;
            font-weight: bold;
        }
        .list-photo {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 40px;
            overflow: hidden;
            margin: 10px 0 4px;
            box-sizing: initial;
            border-radius: 5px;

            &-img {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                height: auto;
                border-radius: 5px;
            }
        }
        .class-btn {
            margin: 0 5px;
        }
        .table-total {
            color: #f65b1a;
            font-weight: bold;
        }
    }
</style>
