import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import iView from 'view-design';
import 'view-design/dist/styles/iview.css';
import axios from 'axios';
import md5 from 'js-md5';

// 生成二维码插件
import VueQrcode from '@chenfengyuan/vue-qrcode';
Vue.component(VueQrcode.name, VueQrcode);

Vue.config.productionTip = false;

// 引入iView UI库
Vue.use(iView);

// axios.defaults.baseURL = 'http://capi.paohui.vip';
// axios.defaults.baseURL = 'http://localhost:3000';
// axios.defaults.timeout = 0;

Vue.prototype.$axios = axios;
Vue.prototype.$md5 = md5;

// 隐藏页面loading图标定时器
let iTimerLoading = null;

// 禁止移动端safri浏览器缩放页面
// document.addEventListener('gesturestart', function (e) {
//     e.preventDefault();
// });

router.beforeEach(async (to, from, next) => {

    clearTimeout(iTimerLoading);
    // 在页面切换前显示loading组件
    store.commit('updataSpinShow', true);

    // 防止控制台报错 [WDS] Disconnected! 实际没啥影响看着不好看
    if (!localStorage.getItem('loglevel:webpack-dev-server')) {
        localStorage.setItem('loglevel:webpack-dev-server', 'SILENT');
    }

    const token = store.state.token;
    const account = store.state.account;

    if (to.path === '/login' || to.path === '/adminRegister') {
        if (token && account) {
            store.commit('updataShow', true);
            next({
                path: '/'
            });
        }
        else {
            store.commit('updataShow', false);
            next();
        }
    }
    else {
        if (token && account) {
            store.commit('updataShow', true);
            const {id, isStore} = to.query;
            
            if (+account.rank === 1) {
                if (!isStore) {
                    store.commit('updataStoreData', null);
                }
                store.commit('updataIsStore', isStore || '');
            }

            // 更新一级导航数据列表
            store.commit('getNavData', {
                routes: router.options.routes,
                query: to.query
            });

            // 总后台进入店铺或者店铺账号进入后台，根据id不同来更新店铺数据
            if (+account.rank === 2 || (+account.rank === 1 && id && isStore)) {
                const storeData = await function() {
                    return new Promise((resolve, reject) => {
                        axios({
                            url: '/api/store/list',
                            method: 'post',
                            data: {
                                id: id || account.storeId
                            }
                        }).then(res => {
                            if (res.data.code === 0 || res.data.list.length === 0) {
                                reject({});
                            }
                            else {
                                resolve(res.data.list[0]);
                            }
                        }).catch(() => {});
                    })
                }();
                store.commit('updataStoreData', storeData);
            }
            
            next();
        }
        else {
            store.commit('updataShow', false);
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            });
        }
    }
});

router.afterEach((to, from) => {

    // 在页面切换完成300毫秒后隐藏loading组件
    iTimerLoading = setTimeout(() => {
        store.commit('updataSpinShow', false);
    }, 500);
})

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
axios.interceptors.request.use(config => {
    iView.LoadingBar.start();

    const token = store.state.token;
    const account = store.state.account;
    if (token && account) {
        config.headers['token'] = token;
    }
    else {
        if (config.url !== '/api/login') {
            const {pathname, search} = location;
            router.push({
                path: '/login',
                query: {
                    redirect: pathname + search
                }
            });
            config.cancelToken = source.token;
            source.cancel();    // 取消请求
        }
    }

    return config;
}, err => {
    return Promise.reject(err);
});

axios.interceptors.response.use(response => {
    iView.LoadingBar.finish();

    if (response.headers['token']) {
        store.commit('updataToken', response.headers['token']);
    }

    if (response.data.timeout) {
        store.commit('updataToken', null);
        store.commit('updataAccount', null);

        const {pathname, search} = location;
        router.push({
            path: '/login',
            query: {
                redirect: pathname + search
            }
        });
    }

    // code返回0时 出提示框
    if (response.data.code === 0) {
        iView.Message.error(response.data.message);
    }

    return response;
}, err => {
    iView.LoadingBar.finish();
    return Promise.reject(err);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
