var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(_vm.isShow)?_c('div',{attrs:{"id":"head"}},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"header-pc"},[_c('div',{staticClass:"header-left"},[_c('img',{attrs:{"src":require("./assets/logo.png")}}),_c('span',[_vm._v(_vm._s(_vm.title))])]),(_vm.account && +_vm.account.rank === 1)?_c('div',{staticClass:"header-back"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("返回首页")])],1):_vm._e(),(_vm.account)?_c('div',{staticClass:"header-right"},[_c('ul',{staticClass:"account"},[_c('li',{staticClass:"account-li"},[_c('div',{staticClass:"account-box"},[_c('Icon',{staticClass:"account-icon",attrs:{"type":"md-contact","size":"18"}}),_vm._v(" "+_vm._s(_vm.account.nickname ? _vm.account.nickname : _vm.account.name)+"("+_vm._s(_vm.account.rank === '1' ? '系统超级管理员' : '商铺管理员')+") ")],1)]),_c('li',{staticClass:"account-li account-li-close",on:{"click":_vm.logout}},[_c('Tooltip',{attrs:{"content":"退出登录","placement":"bottom"}},[_c('Icon',{staticClass:"account-close",attrs:{"type":"md-power","size":"16"}})],1)],1)])]):_vm._e()]),_c('div',{staticClass:"header-wise"},[_c('Icon',{staticClass:"header-wise-menu",style:({
                        color: ("" + (_vm.isMobile && !_vm.openWiseNav ? '' : '#fff')),
                        backgroundColor: ("" + (_vm.isMobile && !_vm.openWiseNav ? '' : '#24303c'))
                    }),attrs:{"type":"md-menu"},on:{"click":_vm.openNav}}),_c('div',{staticClass:"header-wise-title"},[_vm._v(_vm._s(_vm.title))])],1)])]):_vm._e(),(_vm.isShow && _vm.navData && _vm.navData.length > 0)?_c('div',{staticClass:"nav",style:({
            width: (_vm.navWidth + "px"),
            transform: ("translateX(" + (_vm.isMobile && !_vm.openWiseNav ? '-100%' : '0') + ")")
        })},[(_vm.isStore || +_vm.account.rank === 2)?_c('div',{staticClass:"avatar-wrap"},[_c('Avatar',{staticClass:"avatar-phone",attrs:{"src":_vm.storeData && _vm.storeData.src,"shape":"square","icon":"ios-person"}}),_c('div',{staticClass:"clamp2 avatar-name"},[_vm._v(_vm._s(_vm.storeData && _vm.storeData.name))])],1):_vm._e(),_c('ul',_vm._l((_vm.navData),function(item,index){return _c('li',{key:item.meta.name + index},[(item.meta)?_c('router-link',{style:({
                        width: '100%'
                    }),attrs:{"to":{
                        path: item.path,
                        query: item.meta.query ? item.meta.query : {}
                    }},nativeOn:{"click":function($event){return _vm.closeNav.apply(null, arguments)}}},[_c('Icon',{staticClass:"nav-icon",attrs:{"type":item.meta.iconfont,"size":"20"}}),_vm._v(_vm._s(item.meta.title)+" ")],1):_vm._e()],1)}),0)]):_vm._e(),_c('div',{staticClass:"container",style:({
            paddingLeft: ((_vm.isMobile ? 0 : _vm.navWidth) + "px")
        })},[_c('router-view'),(_vm.spinShow)?_c('Spin',{staticClass:"spin",style:({
                left: ((_vm.navWidth + _vm.subNavWidth) + "px !important"),
                right: '0 !important',
                width: 'auto'
            }),attrs:{"size":"large","fix":""}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }