<template>
    <div class="list-container">
        <Form
            class="add-form"
            ref="formItem"
            :model="formItem"
            :rules="ruleValidate"
            :label-width="labelWidth"
            label-position="right"
        >
            <!-- <Form-item prop="shareTitle" label="分享标题">
                <Input v-model="formItem.shareTitle" placeholder="请输入分享标题" />
            </Form-item> -->

            <Form-item prop="sharePic" label="分享图片">
                <CUpload
                    :default-list="formItem.sharePic"
                    :maxLength="1"
                    :width="250"
                    :height="200"
                    :size="200"
                    sizeTip
                    :ratio="[500, 400]"
                    @change="onChange($event, 'sharePic')"
                />
            </Form-item>

            <Form-item>
                <Button
                    class="add-submit"
                    type="primary"
                    size="large"
                    @click="handleSubmit"
                >
                    保存
                </Button>
            </Form-item>
        </Form>
    </div>
</template>

<script>
import CUpload from '@/components/upload/index';

export default {
    name: 'Share',
    components: {
        CUpload
    },
    data() {
        return {
            formItem: {
                shareTitle: '',
                sharePic: []
            },
            ruleValidate: {
                payPoster: [
                ]
            },
            labelWidth: 120
        }
    },
    computed: {
    },
    mounted() {
        this.$axios({
            url: '/api/setup/share',
            method: 'post'
        }).then(res => {
            if (res.data.list.length === 0) {
                return;
            }
            this.formItem = res.data.list[0];
            console.log('this.formItem', this.formItem);
        });
    },
    methods: {
        handleSubmit() {
            console.log('formItem', this.formItem);
            this.$refs['formItem'].validate(valid => {
                if (!valid) {
                    this.$Message.error('存在填写错误，请检查');
                    return;
                }

                this.$axios({
                    url: '/api/setup/updataShare',
                    method: 'post',
                    data: this.formItem
                }).then(res => {
                    if (res.data.code === 0) {
                        return;
                    }
                    
                    this.$Message.success(res.data.message);
                }).catch(() => {});
            })
        },
        onChange(value, name) {
            this.formItem[name] = value;
        }
    }
}
</script>

<style lang="less" scoped>
    .add-form {
        // margin-top: 20px;

        .add-submit {
            width: 150px;
        }
    }
    .add-250 {
        width: 250px;
    }
    .add-150 {
        width: 150px;
    }
    .form-title {
        font-weight: bold;
        padding-top: 20px;
    }
    .form-title:first-of-type {
        padding-top: 0;
    }
    .tips {
        color: #999;
        margin-left: 15px;
    }
    ::v-deep {
        .form-title {
            .ivu-form-item-label {
                font-size: 18px;
            }
        }
    }
</style>
