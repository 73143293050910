<template>
    <div>
        <SubPage :nav-name="navName" :sub-name="subName" />
    </div>
</template>

<script>
import SubPage from '@/components/subPage/index';

export default {
    name: 'Comment',
    components: {
        SubPage
    },
    data() {
        return {
            navName: 'Comment',
            subName: ''
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.subName = to.name;
        });
    }
}
</script>
