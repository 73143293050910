<template>
    <div class="list-container">
        <Form
            class="add-form"
            ref="formItem"
            :model="formItem"
            :rules="ruleValidate"
            :label-width="labelWidth"
            label-position="right"
        >
            <Form-item class="form-title" label=""></Form-item>

            <Form-item prop="payPoster" label="收款码海报">
                <CUpload
                    :default-list="formItem.payPoster"
                    :maxLength="1"
                    :width="323"
                    :height="433"
                    :ratio="[969, 1299]"
                    @change="onChange"
                />
            </Form-item>

            <Form-item>
                <Button
                    class="add-submit"
                    type="primary"
                    size="large"
                    @click="handleSubmit"
                >
                    保存
                </Button>
            </Form-item>
        </Form>
    </div>
</template>

<script>
import CUpload from '@/components/upload/index';

export default {
    name: 'PayPoster',
    components: {
        CUpload
    },
    data() {
        const confirmTuanPoster = (rule, value, callback) => {
            if (value.length === 0) {
                callback(new Error('请上传团长海报图片'));
            }
            else {
                callback();
            }
        }

        return {
            formItem: {
                payPoster: []
            },
            ruleValidate: {
                payPoster: [
                    {required: true, validator: confirmTuanPoster, trigger: 'blur'}
                ]
            },
            labelWidth: 120
        }
    },
    computed: {
    },
    mounted() {
        this.$axios({
            url: '/api/setup/getPayPoster',
            method: 'post'
        }).then(res => {
            if (res.data.list.length === 0) {
                return;
            }
            this.formItem = res.data.list[0];
            console.log('this.formItem', this.formItem);
        });
    },
    methods: {
        handleSubmit() {
            console.log('formItem', this.formItem);
            this.$refs['formItem'].validate(valid => {
                if (!valid) {
                    this.$Message.error('存在填写错误，请检查');
                    return;
                }

                this.$axios({
                    url: '/api/setup/updataPayPoster',
                    method: 'post',
                    data: this.formItem
                }).then(res => {
                    if (res.data.code === 0) {
                        return;
                    }
                    
                    this.$Message.success(res.data.message);
                }).catch(() => {});
            })
        },
        onChange(value) {
            this.formItem.payPoster = value;
        }
    }
}
</script>

<style lang="less" scoped>
    .add-form {
        // margin-top: 20px;

        .add-submit {
            width: 150px;
        }
    }
    .add-250 {
        width: 250px;
    }
    .add-150 {
        width: 150px;
    }
    .form-title {
        font-weight: bold;
        padding-top: 20px;
    }
    .form-title:first-of-type {
        padding-top: 0;
    }
    .tips {
        color: #999;
        margin-left: 15px;
    }
    ::v-deep {
        .form-title {
            .ivu-form-item-label {
                font-size: 18px;
            }
        }
    }
</style>
