<template>
    <Modal
        v-model="showModal"
        :title="status ? '编辑优惠券' : '添加优惠券'"
        :footer-hide="true"
        @on-cancel="close"
    >
        <Form
            class="add-form"
            :label-width="labelWidth"
            label-position="right"
        >
            <Form-item prop="type" label="店铺类型">
                <Select v-model="formItem.type" @on-change="onChange">
                    <Option
                        v-for="item in typeList"
                        :value="item.value"
                        :key="item.id"
                    >{{ item.name }}</Option>
                </Select>
            </Form-item>
        </Form>

        <Form
            class="add-form"
            ref="formItem"
            :model="formItem"
            :rules="ruleValidate"
            :label-width="labelWidth"
            label-position="right"
        >
            <Form-item prop="title" label="优惠券标题">
                <Input
                    v-model="formItem.title"
                    placeholder="请输入优惠券标题"
                />
            </Form-item>

            <Form-item prop="content" label="优惠券内容">
                <Input
                    v-model="formItem.content"
                    type="textarea"
                    :rows="4"
                    placeholder="请输入优惠券内容"
                />
            </Form-item>
            
            <Form-item v-if="!store" prop="amount" label="满足优惠金额">
                <Input
                    v-model="formItem.amount"
                    placeholder="请输入满足优惠条件的金额"
                    search
                    type="number"
                    enter-button="元"
                />
            </Form-item>
            
            <Form-item v-if="formItem.type === '1'" prop="manjian" label="满减优惠">
                <div class="flexbox">
                    <Input
                        v-model="formItem.manjian"
                        class="manjian-input"
                        search
                        type="number"
                        enter-button="元 减"
                        placeholder="0"
                    >
                        <span slot="prepend">满</span>
                    </Input>
                    <Input
                        v-model="formItem.youhui"
                        class="youhui-input"
                        search
                        type="number"
                        enter-button="元"
                        placeholder="0"
                    />
                </div>
            </Form-item>

            <Form-item v-else prop="youhui" label="免单金额">
                <Input
                    v-model="formItem.youhui"
                    placeholder="请输入免单金额"
                    search
                    type="number"
                    enter-button="元"
                />
            </Form-item>

            <Form-item
                v-if="store"
                prop="number"
                label="优惠券数量"
            >
                <Input
                    v-model="formItem.number"
                    type="number"
                    number
                    placeholder="请输入发布优惠券数量"
                />
            </Form-item>

            <Form-item prop="endTime" label="截止日期">
                <DatePicker
                    :value="formItem.endTime"
                    show-week-numbers
                    format="yyyy-MM-dd"
                    placeholder="选择优惠券活动截止日期"
                    @on-change="getTime"
                />
            </Form-item>
            
            <Form-item>
                <Button
                    class="add-submit"
                    type="primary"
                    @click="handleSubmit"
                >
                    {{ status ? '保存' : '添加' }}
                </Button>
                <Button
                    class="add-submit cancel"
                    @click="close"
                >
                    取消
                </Button>
            </Form-item>
        </Form>
    </Modal>
</template>

<script>
export default {
    props: {
        visibility: {
            type: Boolean,
            value: false
        },
        data: {
            type: Object,
            value: {}
        },
        store: {
            type: Boolean,
            default: false
        }
    },
    data() {
        const confirmManjian = (rule, value, callback) => {
            const {type, youhui} = this.formItem;
            if (value < 0) {
                callback(new Error('满减价格不能<0'));
            }
            else if (!value) {
                callback(new Error('请输入满减价格'));
            }
            else if (youhui < 0) {
                callback(new Error('优惠金额不能<0'));
            }
            else if (!youhui) {
                callback(new Error('请输入优惠金额'));
            }
            else if (+value < +youhui) {
                callback(new Error('优惠价格不能大于或等于满减金额'));
            }
            else {
                callback();
            }
        }
        const confirmYouhui = (rule, value, callback) => {
            const {type} = this.formItem;
            if (type === '2' && !value) {
                callback(new Error('请输入免单金额'));
            }
            else {
                callback();
            }
        }

        const confirmNumber = (rule, value, callback) => {
            if (value < 0) {
                callback(new Error('优惠券发布数量不能少于0张'));
            }
            else if (value > 10000) {
                callback(new Error('优惠券发布数量不能大于10000张'));
            }
            else {
                callback();
            }
        }

        return {
            showModal: false,
            formItem: {
                type: '1',
                title: '',
                content: '',
                amount: '',
                manjian: '',
                youhui: '',
                number: 0,
                endTime: ''
            },
            ruleValidate: {
                title: [
                    {required: true, message: '请输入优惠券标题', trigger: 'change'}
                ],
                content: [
                    {required: true, message: '请输入优惠券内容', trigger: 'change'}
                ],
                amount: [
                    {required: true, message: '金额不能为空', trigger: 'change'}
                ],
                manjian: [
                    {required: true, validator: confirmManjian, trigger: 'change'}
                ],
                youhui: [
                    {required: true, validator: confirmYouhui, trigger: 'change'}
                ],
                number: [
                    {required: true, validator: confirmNumber, trigger: 'change'}
                ],
                endTime: [
                    {required: false, message: '请选择优惠券活动截止日期', trigger: 'blur'}
                ]
            },
            labelWidth: 120,
            typeList: [
                {
                    value: '1',
                    name: '满减优惠'
                },
                {
                    value: '2',
                    name: '免单'
                }
            ],
            cacheDate: null,
            status: ''
        }
    },
    watch: {
        visibility: {
            immediate: true,
            handler(value) {
                this.showModal = value;
            }
        },
        data: {
            immediate: true,
            handler(value) {
                this.status = value.id;
                if (value) {
                    this.formItem = Object.assign({}, this.formItem, value);
                }
            }
        },
        store: {
            immediate: true,
            handler(value) {
                if (!value) {
                    return;
                }
                delete this.formItem.amount;
                delete this.ruleValidate.amount;
            }
        }
    },
    mounted() {
        console.log('this.formItem', this.formItem);
        this.cacheDate = JSON.parse(JSON.stringify(this.formItem));
    },
    methods: {
        onChange() {
            this.resetFields();
        },

        // 初始化校验错误
        resetFields() {
            this.$refs['formItem'].resetFields();
        },
        close() {
            this.$emit('close');
            
            setTimeout(() => {
                this.status = '';
                this.formItem = JSON.parse(JSON.stringify(this.cacheDate));
                setTimeout(() => {
                    this.resetFields();
                }, 100);
            }, 100);
        },
        handleSubmit() {
            console.log('formItem', this.formItem);
            this.$refs['formItem'].validate(valid => {
                if (!valid) {
                    this.$Message.error('存在填写错误，请检查');
                    return;
                }
                
                this.$emit('ok', this.formItem);
                this.close();
            })
        },
        getTime(value) {
            this.formItem.endTime = value;
        }
    }
}
</script>

<style lang="less" scoped>
    .add-form {
        margin-right: 20px;

        .add-submit {
            width: 100px;
        }
    }
    .add-form:nth-of-type(1) {
        margin-top: 15px;
    }
    .cancel {
        margin-left: 20px;
    }
    .manjian-input {
        width: 60%;
    }
    .youhui-input {
        width: 40%;
    }
    ::v-deep {
        .manjian-input {
            .ivu-input-group-prepend {
                color: #fff;
                padding-left: 16px;
                padding-right: 16px;
                background-color: #2d8cf0;
            }
            .ivu-input {
                border-left: 0;
            }

            .ivu-input-group-append {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        .youhui-input {
            .ivu-input {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-left: 0;
            }
        }
    }
</style>
