<template>
    <div class="add-container">
        <Form
            class="add-form"
            ref="formItem"
            :model="formItem"
            :rules="ruleValidate"
            :label-width="150"
            label-position="right"
        >
            <Form-item prop="name" label="账号">
                <div class="account-name">{{ formItem.name }}</div>
            </Form-item>

            <Form-item prop="oldPassword" label="旧密码">
                <Input
                    v-model="formItem.oldPassword"
                    class="add-200"
                    type="password"
                    placeholder="请输入旧密码"
                />
            </Form-item>

            <Form-item prop="password" label="新密码">
                <Input
                    v-model="formItem.password"
                    class="add-200"
                    type="password"
                    placeholder="请输入新密码"
                />
            </Form-item>

            <Form-item prop="againPassword" label="确认密码">
                <Input
                    v-model="formItem.againPassword"
                    class="add-200"
                    type="password"
                    placeholder="请再次输入密码"
                />
            </Form-item>

            <Form-item prop="nickname" label="昵称">
                <Input v-model="formItem.nickname" class="add-200" placeholder="请输入昵称"/>
            </Form-item>

            <Form-item prop="pic" label="头像">
                <CUpload
                    :default-list="formItem.pic"
                    :maxLength="1"
                    :size="300"
                    sizeTip
                    :ratio="[150, 150]"
                    @change="onChange"
                />
            </Form-item>

            <Form-item prop="phone" label="联系方式">
                <Input v-model="formItem.phone" class="add-200" placeholder="请输入店铺联系方式" />
            </Form-item>

            <Form-item>
                <Button
                    class="add-submit"
                    type="primary"
                    size="large"
                    @click="handleSubmit"
                >
                    提交
                </Button>
            </Form-item>
        </Form>
    </div>
</template>

<script>
import CUpload from '@/components/upload/index';

export default {
    name: 'Account',
    components: {
        CUpload
    },
    data() {
        const confirmOldPassword = (rule, value, callback) => {
            if (!value && this.formItem.password) {
                callback(new Error('请输入旧密码'));
            }
            else {
                callback();
            }
        };

        const confirmAgainPassword = (rule, value, callback) => {
            if (value === this.formItem.password) {
                callback();
            }
            else {
                callback(new Error('两次输入的密码不一致'));
            }
        };

        const validatePhone = (rule, value, callback) => {
            const isPhone = /^1[3456789]\d{9}$/;
            const isMob=/^((0\d{2,3})-)?(\d{7,8})$/;
            if (!value || (value && (isMob.test(value) || isPhone.test(value)))) {
                callback();
            }
            else {
                callback(new Error('联系方式格式错误！'));
            }
        };

        return {
            formItem: {
                oldPassword: ''
            },
            ruleValidate: {
                oldPassword: [
                    {min: 6, message: '密码不能低于6位数', trigger: 'blur'},
                    {required: false, validator: confirmOldPassword, trigger: 'change'}
                ],
                password: [
                    {min: 6, message: '密码不能低于6位数', trigger: 'blur'},
                    {required: false, message: '请输入密码', trigger: 'change'}
                ],
                againPassword: [
                    {required: false, validator: confirmAgainPassword, trigger: 'blur'}
                ],
                phone: [
                    {required: false, validator: validatePhone, trigger: 'blur'}
                ]
            }
        }
    },
    computed: {
        account() {
            return this.$store.state.account;
        }
    },
    watch: {
        account: {
            immediate: true,
            async handler(account) {
                if (!account) return;
                this.formItem = Object.assign({}, this.formItem, account);
                console.log('account', account);
                console.log('this.formItem', this.formItem);
            }
        }
    },
    mounted() {
    },
    methods: {
        handleSubmit() {
            console.log('this.formItem', this.formItem);
            this.$refs['formItem'].validate(valid => {
                if (!valid) {
                    this.$Message.error('存在填写错误，请检查');
                    return;
                }

                // let password = '';
                // let oldPassword = '';
                // let againPassword = '';

                // if (this.formItem.password) {
                //     oldPassword = this.$md5(this.formItem.oldPassword);
                //     password = this.$md5(this.formItem.password);
                //     againPassword = this.$md5(this.formItem.againPassword);
                // }

                this.$axios({
                    url: '/api/account/add',
                    method: 'post',
                    data: this.formItem
                }).then(res => {
                    if (res.data.code === 0) {
                        return;
                    }
                    console.log('res', res);
                    this.formItem.oldPassword = '';
                    this.formItem.password = '';
                    this.formItem.againPassword = '';
                    this.$Message.success(res.data.message);
                }).catch(() => {});
            });
        },
        onChange(value) {
            this.formItem.pic = value;
        }
    }
}
</script>

<style lang="less" scoped>
    // .add-container {
    //     padding-right: 50px;
    // }
    .add-form {
        margin-top: 20px;

        .add-submit {
            width: 150px;
        }
    }
    .add-200 {
        width: 200px;
    }
    .account-name {
        color: #515a6e;
        padding: 0 8px;
    }
    .add-title {
        font-weight: bold;
        padding-top: 20px;
    }
</style>
