<template>
    <div class="divider" :style="{
        width: 'auto',
        height: height + 'px',
        backgroundColor: bgColor,
        marginTop: top + 'px',
        marginLeft: '-' + left + 'px',
        marginRight: '-' + right + 'px'
    }"></div>
</template>

<script>
export default {
    props: {
        height: {
            type: [String, Number],
            default: 20
        },
        top: {
            type: [String, Number],
            default: 20
        },
        left: {
            type: [String, Number],
            default: 15
        },
        right: {
            type: [String, Number],
            default: 15
        },
        bgColor: {
            type: String,
            default: '#f5f7f9'
        }
    },
    data() {
        return {
        }
    },
    mounted() {
    }
}
</script>

<style lang="less" scoped></style>
