<template>
    <div class="list-container">
        <Button
            class="add-btn"
            type="primary"
            @click="handleModal"
        >
            添加店铺
        </Button>
        <Form
            class="search-form"
            ref="searchItem"
            inline
            :model="searchItem"
            @keydown.native.enter.prevent
        >
            <Form-item prop="name">
                <Select
                    v-model="searchItem.type"
                    class="search-select"
                    placeholder="请选择店铺类型"
                    filterable
                    clearable
                    @on-change="onSearch"
                >
                    <Option
                        v-for="item in typeList"
                        :value="item.name"
                        :key="item.id"
                    >{{ item.name }}</Option>
                </Select>
            </Form-item>

            <Form-item prop="name">
                <Input
                    v-model="searchItem.name"
                    class="search-input"
                    clearable
                    search
                    enter-button="搜索"
                    placeholder="请输入店铺名称"
                    @on-search="onSearch"
                    @on-clear="onSearch"
                />
            </Form-item>
        </Form>
        <Table border :context="self" :columns="columns" :data="list"/>
        <div v-if="list && list.length > 0 && total > rn" class="list-page">
            <Page
                :current="pn + 1"
                :total="total"
                :page-size="rn"
                show-total
                @on-change="onPageChange"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'StoreList',
    components: {
    },
    data() {
        return {
            self: this,
            columns: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '排序',
                    key: 'sort',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '店铺封面',
                    key: 'src',
                    width: 100,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'list-photo'
                        }, [
                            params.row.src ? h('img', {
                                class: 'list-photo-img',
                                attrs: {
                                    src: params.row.src
                                }
                            }) : ''
                        ]);
                    }
                },
                {
                    title: '店铺名称',
                    key: 'name',
                    align: 'center',
                    minWidth: 250
                },
                {
                    title: '推荐员编号',
                    key: 'tuijianyuanNumber',
                    align: 'center',
                    minWidth: 110,
                    render: (h, params) => {
                        return h('span', {
                            class: 'list-number',
                            on: {
                                click: () => {
                                    this.pushPage(params.row.tuijianyuanNumber);
                                }
                            }
                        }, params.row.tuijianyuanNumber);
                    }
                },
                {
                    title: '店铺类型',
                    key: 'type',
                    align: 'center',
                    minWidth: 150,
                    render: (h, params) => {
                        return h('div', {
                        }, params.row.type.join(' / '));
                    }
                },
                // {
                //     title: '佣金比例',
                //     key: 'yongjin',
                //     align: 'center',
                //     width: 96,
                //     render: (h, params) => {
                //         return h('span', {}, `${params.row.yongjin}%`)
                //     }
                // },
                // {
                //     title: '折扣',
                //     key: 'discount',
                //     align: 'center',
                //     width: 80,
                //     render: (h, params) => {
                //         return h('span', {}, `${params.row.discount}折`)
                //     }
                // },
                {
                    title: '创建时间',
                    key: 'dateTime',
                    align: 'center',
                    width: 200
                },
                {
                    title: '店铺状态',
                    key: 'showStore',
                    align: 'center',
                    width: 110,
                    fixed: 'right',
                    render: (h, params) => {
                        return h('Button', {
                            class: `${params.row.showStore === '1' ? 'ivu-btn-success' : 'ivu-btn-error'} ivu-btn-small class-btn`,
                            on: {
                                click: () => {
                                    this.storeStatus(params.row, params.index);
                                }
                            }
                        }, `${params.row.showStore === '1' ? '已上架' : '已下架'}`)
                    }
                },
                {
                    title: '自动提现',
                    key: 'stopCashout',
                    align: 'center',
                    width: 110,
                    fixed: 'right',
                    render: (h, params) => {
                        return h('Button', {
                            class: `${params.row.stopCashout ? 'ivu-btn-error' : 'ivu-btn-success'} ivu-btn-small class-btn`,
                            on: {
                                click: () => {
                                    this.storeCashoutStatus(params.row, params.index);
                                }
                            }
                        }, `${params.row.stopCashout ? '已关闭' : '已开启'}`)
                    }
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 150,
                    align: 'center',
                    fixed: 'right',
                    render: (h, params) => {
                        return h('div', {}, [
                            h('Button', {
                                class: 'ivu-btn-primary ivu-btn-small class-btn',
                                on: {
                                    click: () => {
                                        this.edit(params.row.id);
                                    }
                                }
                            }, '编辑'),
                            h('Button', {
                                class: 'ivu-btn-error ivu-btn-small class-btn',
                                on: {
                                    click: () => {
                                        this.remove(params.index);
                                    }
                                }
                            }, '删除')
                        ]);
                    }
                },
                {
                    title: '商家入口',
                    key: 'storeEntry',
                    align: 'center',
                    width: 100,
                    fixed: 'right',
                    render: (h, params) => {
                        return h('Button', {
                            class: 'ivu-btn-warning ivu-btn-small class-btn',
                            on: {
                                click: () => {
                                    this.storePush(params.row);
                                }
                            }
                        }, '管理')
                    }
                }
            ],
            list: [],
            total: 0,
            pn: 0,
            rn: 10,
            pageValue: 1,
            searchItem: {
                name: '',
                type: ''
            },
            typeList: null
        }
    },
    mounted() {
        const {pn} = this.$route.params;
        if (pn) {
            this.pn = +pn;
        }
       
        const getTypeList = () => {
            return this.$axios({
                url: '/api/storeType/list',
                method: 'post'
            })
        }
        this.$axios.all([getTypeList()]).then(res => {
            if (res.length === 0) {
                return;
            }
            this.typeList = res[0].data.list;
        });
        
        this.getData(this.pn);
    },
    methods: {
        
        // 编辑当前分类
        edit(id) {
            this.$router.push({
                path: '/store/addStore',
                query: {
                    id,
                    pn: this.pn
                }
            });
        },

        // 删除当前店铺
        remove(index) {
            this.$Modal.confirm({
                title: '提示',
                content: '确认要删除吗？',
                onOk: () => {
                    console.log('this.list[index]', this.list[index]);
                    this.$axios({
                        url: '/api/account/delete',
                        method: 'post',
                        data: {
                            storeId: this.list[index].id
                        }
                    }).then(res => {
                        const {total, message} = res.data;
                        if (this.pn > 1) {
                            this.pn = Math.ceil(total / this.rn) > this.pn ? this.pn : Math.ceil(total / this.rn);
                        }
                        this.getData(this.pn);
                        this.$Message.success(message);
                    }).catch(() => {});
                }
            });
        },
        storeStatus(data, index) {
            let tips = '确定要下架店铺吗!';
            let showStore = '0';
            if (data.showStore === '0') {
                tips = '确定要上架店铺吗!';
                showStore = '1';
            }
            this.$Modal.confirm({
                title: '提示',
                content: tips,
                onOk: () => {
                    this.$axios({
                        url: '/api/store/updataStoreStatus',
                        method: 'post',
                        data: {
                            id: data.id,
                            showStore
                        }
                    }).then(res => {
                        this.list[index].showStore = showStore;
                        this.$Message.success(res.data.message);
                    }).catch(() => {});
                }
            });
        },
        storeCashoutStatus(data, index) {
            let tips = '确定要停止店铺自动提现功能吗！';
            let stopCashout = '1';
            if (data.stopCashout) {
                tips = '确定要开启店铺自动提现功能吗！';
                stopCashout = '';
            }
            this.$Modal.confirm({
                title: '提示',
                content: tips,
                onOk: () => {
                    this.$axios({
                        url: '/api/store/updataStoreCashoutStatus',
                        method: 'post',
                        data: {
                            id: data.id,
                            stopCashout
                        }
                    }).then(res => {
                        this.list[index].stopCashout = stopCashout;
                        this.$Message.success(res.data.message);
                    }).catch(() => {});
                }
            });
        },
        storePush(data) {
            this.$router.push({
                path: '/storeSetup/storeHome',
                query: {
                    id: data.id,
                    isStore: 1
                }
            });
        },
        onPageChange(pn) {
            this.pn = pn - 1;
            this.getData(this.pn);
        },
        getData(pn) {
            this.$axios({
                url: '/api/store/list',
                method: 'post',
                data: Object.assign({}, this.searchItem, {
                    pn,
                    rn: this.rn
                })
            }).then(res => {
                const {list, total} = res.data;
                this.list = list;
                if (+this.total !== +total) {
                    this.total = total;
                }
            }).catch(() => {});
        },
        onSearch() {
            this.pn = 0;
            this.getData(this.pn);
        },
        handleModal() {
            this.$router.push({
                path: '/store/addStore'
            });
        },
        pushPage(number) {
            this.$router.push({
                path: `/capital/tuijianyuanDetail?number=${number}`
            });
        }
    }
}
</script>

<style lang="less" scoped>
    .search-form {
        padding-top: 8px;
    }
    .search-input {
        width: 350px;
    }
    .search-select {
        width: 200px;
    }
    .list-page {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }
    ::v-deep {
        .list-number {
            color: #19be6b;
            font-weight: bold;
            cursor: pointer;
        }
        .list-photo {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 40px;
            overflow: hidden;
            margin: 10px 0 4px;
            box-sizing: initial;
            border-radius: 5px;

            &-img {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                height: auto;
                border-radius: 5px;
            }
        }

        .class-btn {
            margin: 0 5px;
        }
    }
</style>
