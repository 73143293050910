<template>
    <div class="list-container">
        <Form
            class="search-form"
            ref="searchItem"
            inline
            @keydown.native.enter.prevent="onSearch"
        >
            <Form-item>
                <!-- 按日期检索 -->
                <DatePicker
                    :value="searchItem.time"
                    class="search-form-datepicker"
                    type="datetimerange"
                    confirm
                    split-panels
                    placement="bottom-end"
                    placeholder="默认不限制"
                    @on-change="getTime"
                    @on-ok="searchFun"
                />
                <Button
                    class="search-form-btn"
                    type="primary"
                    @click="searchFun"
                >
                    查找
                </Button>
            </Form-item>
        </Form>

        <Table
            class="order-table"
            border
            stripe
            :context="self"
            :columns="columns"
            :data="list"
        />

        <div v-if="list && list.length > 0 && total > rn" class="list-page">
            <Page
                :current="pn + 1"
                :total="total"
                :page-size="rn"
                show-total
                @on-change="onPageChange"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'VipOrder',
    components: {
    },
    data() {
        return {
            self: this,
            columns: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '订单号',
                    key: 'out_trade_no',
                    minWidth: 240,
                    align: 'center'
                },
                {
                    title: '店铺名称',
                    key: 'storeName',
                    minWidth: 250,
                    align: 'center'
                },
                {
                    title: '实际支付(元)',
                    key: 'total',
                    minWidth: 120,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'table-total'
                        }, params.row.total);
                    }
                },
                {
                    title: '订单金额(元)',
                    key: 'shouldPay',
                    minWidth: 120,
                    align: 'center'
                },
                {
                    title: '平台折扣金额(元)',
                    key: 'discountAmount',
                    minWidth: 150,
                    align: 'center'
                },
                {
                    title: '优惠卷(元)',
                    key: 'youhui',
                    minWidth: 120,
                    align: 'center'
                },
                {
                    title: '下单时间',
                    key: 'dateTime',
                    minWidth: 190,
                    align: 'center'
                },
                {
                    title: '订单状态',
                    key: 'status',
                    align: 'center',
                    width: 110,
                    fixed: 'right',
                    render: (h, params) => {
                        const {status} = params.row;
                        return h('Tag', {
                            props: {
                                color: `${status === '1' ? 'primary' : (status === '2' ? 'success' : 'error')}`
                            }
                        }, `${ status === '1' ? '待核销' : (status === '2' ? '已核销' : '已退款') }`)
                    }
                }
            ],
            searchItem: {
                openid: '',
                time: []
            },
            list: [],
            total: 0,
            pn: 0,
            rn: 10
        }
    },
    computed: {
    },
    async mounted() {
        const {id} = this.$route.query;
        if (!id) {
            this.backVipList();
        }

        /**
         * 先获取vip信息，拿到openid
         */
        const vipInfo = (await this.$axios({
            url: '/api/vip/getVip',
            method: 'post',
            data: {
                id
            }
        })).data.list[0];
        this.searchItem.openid = vipInfo.openid;

        this.getData(this.pn);
    },
    methods: {
        backVipList(data) {
            this.$router.push({
                path: '/vip/vipList',
                query: data
            });
        },
        searchFun() {
            console.log('搜索', this.searchItem);
            this.pn = 0;
            this.getData(this.pn);
        },
        getTime(value, isDate) {
            this.searchItem.time = value;
            if (!isDate) {
                this.searchFun();
            }
        },
        onPageChange(pn) {
            this.pn = pn - 1;
            this.getData(this.pn);
        },
        getData(pn) {
            console.log('this.searchItem', this.searchItem);

            this.$axios({
                url: '/api/order/list',
                method: 'post',
                data: Object.assign({}, this.searchItem, {
                    time: JSON.stringify(this.searchItem.time),
                    pn,
                    rn: this.rn
                })
            }).then(res => {
                const {list, total} = res.data;
                this.list = list;
                console.log('this.list', this.list);
                if (+this.total !== +total) {
                    this.total = total;
                }
            }).catch(() => {});
        }
    }
}
</script>

<style lang="less" scoped>
    .list-page {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }
    .search-form-datepicker {
        width: 360px;
    }
    .search-form-btn {
        border-radius: 0 4px 4px 0;
    }
    ::v-deep {
        .search-form-datepicker .ivu-input {
            border-radius: 4px 0 0 4px;
        }
        .table-total {
            color: #f65b1a;
            font-weight: bold;
        }
    }
</style>
