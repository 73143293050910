<template>
    <div class="list-container">
        <Button
            class="add-btn"
            type="primary"
            @click="addTui"
        >
            添加推荐员
        </Button>

        <Form
            class="search-form"
            ref="searchItem"
            inline
            :model="searchItem"
            @keydown.native.enter.prevent
        >
            <Form-item class="flexbox" prop="query">
                <Input
                    v-model="searchItem.query"
                    class="flexbox-fixed search-input"
                    clearable
                    search
                    enter-button="搜索"
                    placeholder="请输入关键字"
                    @on-search="onSearch"
                    @on-clear="onSearch"
                />
            </Form-item>
        </Form>
        <Table border :context="self" :columns="columns" :data="list"/>
        <div v-if="list && list.length > 0 && total > rn" class="list-page">
            <Page
                :current="pn + 1"
                :total="total"
                :page-size="rn"
                show-total
                @on-change="onPageChange"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'TuijianyuanList',
    components: {
    },
    data() {
        return {
            self: this,
            columns: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '推荐员编号',
                    key: 'number',
                    align: 'center',
                    minWidth: 110,
                    // render: (h, params) => {
                    //     return h('span', {
                    //         class: 'list-number'
                    //     }, params.row.number);
                    // }
                    render: (h, params) => {
                        return h('span', {
                            class: 'list-number',
                            on: {
                                click: () => {
                                    this.pushPage(params.row.number);
                                }
                            }
                        }, params.row.number);
                    }
                },
                {
                    title: '推荐员头像',
                    key: 'src',
                    width: 110,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'list-photo'
                        }, [
                            params.row.src ? h('img', {
                                class: 'list-photo-img',
                                attrs: {
                                    src: params.row.src
                                }
                            }) : ''
                        ]);
                    }
                },
                {
                    title: '推荐员名称',
                    key: 'nickname',
                    align: 'center',
                    minWidth: 200
                },
                {
                    title: '创建时间',
                    key: 'dateTime',
                    align: 'center',
                    width: 200
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 150,
                    align: 'center',
                    fixed: 'right',
                    render: (h, params) => {
                        return h('div', {}, [
                            h('Button', {
                                class: 'ivu-btn-primary ivu-btn-small class-btn',
                                on: {
                                    click: () => {
                                        this.edit(params.row.id);
                                    }
                                }
                            }, '编辑'),
                            h('Button', {
                                class: 'ivu-btn-error ivu-btn-small class-btn',
                                on: {
                                    click: () => {
                                        this.remove(params.index);
                                    }
                                }
                            }, '删除')
                        ]);
                    }
                }
            ],
            list: [],
            total: 0,
            pn: 0,
            rn: 10,
            searchItem: {
                query: ''
            },
            cacheItem: null
        }
    },
    computed: {
    },
    mounted() {
        const {pn} = this.$route.params;
        if (pn) {
            this.pn = +pn;
        }
        this.getData(this.pn);
    },
    methods: {
        onPageChange(pn) {
            this.pn = pn - 1;
            this.getData(this.pn);
        },
        getData(pn) {
            this.$axios({
                url: '/api/tuijianyuan/list',
                method: 'post',
                data: Object.assign({}, this.searchItem, {
                    pn,
                    rn: this.rn
                })
            }).then(res => {
                const {list, total} = res.data;
                this.list = list;
                if (+this.total !== +total) {
                    this.total = total;
                }
            }).catch(() => {});
        },
        onSearch() {
            this.pn = 0;
            this.getData(this.pn);
        },

        // 编辑当前推荐员
        edit(id) {
            this.$router.push({
                path: '/tuijianyuan/addTuijianyuan',
                query: {
                    id,
                    pn: this.pn
                }
            });
        },

        // 删除当前推荐员
        remove(index) {
            this.$Modal.confirm({
                title: '提示',
                content: '确认要删除吗？',
                onOk: () => {
                    console.log('this.list[index]', this.list[index]);
                    this.$axios({
                        url: '/api/tuijianyuan/delete',
                        method: 'post',
                        data: {
                            id: this.list[index].id,
                            vipId: this.list[index].vipId
                        }
                    }).then(res => {
                        const {total, message} = res.data;
                        if (this.pn > 1) {
                            this.pn = Math.ceil(total / this.rn) > this.pn ? this.pn : Math.ceil(total / this.rn);
                        }
                        this.getData(this.pn);
                        this.$Message.success(message);
                    }).catch(() => {});
                }
            });
        },
        addTui() {
            this.$router.push({
                path: '/tuijianyuan/addTuijianyuan'
            });
        },
        pushPage(number) {
            this.$router.push({
                path: `/capital/tuijianyuanDetail?number=${number}`
            });
        }
    }
}
</script>

<style lang="less" scoped>
    .search-form {
        padding-top: 8px;
    }
    .search-input {
        width: 350px;
    }
    .list-page {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }
    ::v-deep {
        .list-number {
            color: #19be6b;
            font-weight: bold;
            cursor: pointer;
        }
        .list-photo {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 40px;
            overflow: hidden;
            margin: 10px 0 4px;
            box-sizing: initial;
            border-radius: 5px;

            &-img {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                height: auto;
                border-radius: 5px;
            }
        }
        .class-btn {
            margin: 0 5px;
        }
    }
</style>
