<template>
    <div class="list-container">
        <Content orderType="2" />
    </div>
</template>

<script>
import Content from '../components/content';

export default {
    name: 'GoodsOrder',
    components: {
        Content
    },
    data() {
        return {
            
        }
    },
    computed: {
        
    },
    mounted() {
        
    },
    methods: {
        
    }
}
</script>

<style lang="less" scoped>
</style>
