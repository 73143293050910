<template>
    <div class="list-container">
        <Filters
            showTime
            :show-input="!searchItem.storeId"
            @search="searchFun"
        />

        <Divider :top="0" />

        <Count :columns="countColumns" :list="countList" />

        <Divider />

        <OrderList
            :list="list"
            :total="total"
            :pn="pn"
            :rn="rn"
            @pageChange="onPageChange"
        />
    </div>
</template>

<script>
import Divider from '@/components/divider/index';
import Filters from '../components/filters';
import Count from '../components/count';
import OrderList from '../components/orderList';

export default {
    name: 'Cashier',
    components: {
        Divider,
        Filters,
        Count,
        OrderList
    },
    data() {
        return {
            // storeId: this.$route.query.id,
            searchItem: {
                time: []
            },
            countColumns: this.$route.query.id ? [
                {
                    title: '店铺实收',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'red'
                        }, `￥${params.row.storeMoney}`);
                    }
                },
                {
                    title: ' ',
                    align: 'center',
                    width: 60,
                    render: (h, params) => {
                        return h('div', {
                            class: 'fuhao'
                        }, '=');
                    }
                },
                {
                    title: '用户消费',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'red'
                        }, `￥${params.row.storeTotal}`);
                    }
                },
                {
                    title: ' ',
                    align: 'center',
                    width: 60,
                    render: (h, params) => {
                        return h('div', {
                            class: 'fuhao'
                        }, '+');
                    }
                },
                {
                    title: '平台优惠券',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'red'
                        }, `￥${params.row.storePingYouhui}`);
                    }
                },
                {
                    title: ' ',
                    align: 'center',
                    width: 60,
                    render: (h, params) => {
                        return h('div', {
                            class: 'fuhao'
                        }, '+');
                    }
                },
                {
                    title: '余额支付',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'red'
                        }, `￥${params.row.storeYue}`);
                    }
                },
                {
                    title: ' ',
                    align: 'center',
                    width: 60,
                    render: (h, params) => {
                        return h('div', {
                            class: 'fuhao'
                        }, '-');
                    }
                },
                {
                    title: '平台抽成',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'red'
                        }, `￥${params.row.storePingDiscountAmount}`);
                    }
                }
            ] : [
                {
                    title: '平台实收',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'red'
                        }, `￥${params.row.pingMoney}`);
                    }
                },
                {
                    title: ' ',
                    align: 'center',
                    width: 60,
                    render: (h, params) => {
                        return h('div', {
                            class: 'fuhao'
                        }, '=');
                    }
                },
                {
                    title: '用户消费',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'red'
                        }, `￥${params.row.storeTotal}`);
                    }
                },
                {
                    title: ' ',
                    align: 'center',
                    width: 60,
                    render: (h, params) => {
                        return h('div', {
                            class: 'fuhao'
                        }, '+');
                    }
                },
                {
                    title: '平台优惠券',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'red'
                        }, `￥${params.row.storePingYouhui}`);
                    }
                },
                {
                    title: ' ',
                    align: 'center',
                    width: 60,
                    render: (h, params) => {
                        return h('div', {
                            class: 'fuhao'
                        }, '+');
                    }
                },
                {
                    title: '余额支付',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'red'
                        }, `￥${params.row.storeYue}`);
                    }
                },
                {
                    title: ' ',
                    align: 'center',
                    width: 60,
                    render: (h, params) => {
                        return h('div', {
                            class: 'fuhao'
                        }, '-');
                    }
                },
                {
                    title: '店铺收入',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'red'
                        }, `￥${params.row.storeMoney}`);
                    }
                },
                {
                    title: ' ',
                    align: 'center',
                    width: 60,
                    render: (h, params) => {
                        return h('div', {
                            class: 'fuhao'
                        }, '-');
                    }
                },
                {
                    title: '团长收入',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'red'
                        }, `￥${params.row.zhangTotal}`);
                    }
                },
                {
                    title: ' ',
                    align: 'center',
                    width: 60,
                    render: (h, params) => {
                        return h('div', {
                            class: 'fuhao'
                        }, '-');
                    }
                },
                {
                    title: '商户平台手续费',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'red'
                        }, `￥${params.row.wxDiscountAmount}`);
                    }
                }
            ],
            countList: [],
            list: [],
            total: 0,
            pn: 0,
            rn: 10
        }
    },
    computed: {
        getStoreData() {
            return this.$store.state.storeData;
        }
    },
    watch: {
        getStoreData: {
            immediate: true,
            handler(storeData) {
                console.log('storeData', storeData);
                if (storeData.id) {
                    this.searchItem.storeId = storeData.id;
                }

                this.getStoreList();
                this.getCount();
                this.getData(this.pn);
            }
        }
    },
    mounted() {
    },
    methods: {
        searchFun(options) {
            console.log('搜索', options);
            this.searchItem = Object.assign({}, options, {
                storeName: options.query
            });
            this.pn = 0;
            this.getCount();
            this.getData(this.pn);
        },
        onPageChange(pn) {
            console.log('onPageChange => pn', pn);
            this.pn = pn;
            this.getData(this.pn);
        },
        getCount() {
            this.$axios({
                url: '/api/capital/cashier/count',
                method: 'post',
                data: Object.assign({}, this.searchItem, {
                    // storeId: this.storeId,
                    time: JSON.stringify(this.searchItem.time)
                })
            }).then(res => {
                const {list} = res.data;
                this.countList = list;
                console.log('this.countList', this.countList);
            }).catch(() => {});
        },
        getData(pn) {
            console.log('this.searchItem', this.searchItem);

            this.$axios({
                url: '/api/capital/cashier/list',
                method: 'post',
                data: Object.assign({}, this.searchItem, {
                    // storeId: this.storeId,
                    time: JSON.stringify(this.searchItem.time),
                    pn,
                    rn: this.rn
                })
            }).then(res => {
                const {list, total} = res.data;
                this.list = list;
                console.log('this.list', this.list);
                if (+this.total !== +total) {
                    this.total = total;
                }
            }).catch(() => {});
        },
        getStoreList() {
            // storeList
            this.$axios({
                url: '/api/store/list',
                method: 'post',
            }).then(res => {
                console.log('res', res);
            });
        }
    }
}
</script>

<style lang="less" scoped>
</style>
