import { render, staticRenderFns } from "./index.vue?vue&type=template&id=a30b9754&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./index.vue?vue&type=style&index=1&id=a30b9754&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a30b9754",
  null
  
)

export default component.exports