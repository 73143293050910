import Vue from 'vue';
import VueRouter from 'vue-router';
import routerConfig from './config';

Vue.use(VueRouter);

// 解决报错
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
}

// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routerConfig.routes
})

export default router;
