<template>
    <div class="list-container">
        <Button
            class="add-btn"
            type="primary"
            @click="openAdd"
        >
            增加
        </Button>

        <Table border :context="self" :columns="columns" :data="list"/>

        <Modal
            v-model="showModal"
            :title="isEdit ? '编辑店铺抽成类型' : '添加店铺抽成类型'"
            :footer-hide="true"
            @on-cancel="closeModal"
        >
            <Form
                class="add-form"
                ref="formItem"
                :model="formItem"
                :rules="ruleValidate"
                :label-width="120"
                label-position="right"
            >
                <Form-item prop="title" label="店铺类别">
                    <Input
                        v-model="formItem.title"
                        placeholder="A类店铺..."
                    />
                </Form-item>

                <Form-item prop="discount" label="平台总抽成">
                    <Input
                        v-model="formItem.discount"
                        placeholder="0"
                        search
                        type="number"
                        enter-button="%"
                    />
                    <div class="tips">注意：平台总抽成包含团长抽成和推荐员抽成</div>
                </Form-item>

                <Form-item prop="yongjin" label="团长抽成">
                    <Input
                        v-model="formItem.yongjin"
                        placeholder="0"
                        search
                        type="number"
                        enter-button="%"
                    />
                </Form-item>

                <Form-item prop="sellYongjin" label="推荐员抽成">
                    <Input
                        v-model="formItem.sellYongjin"
                        placeholder="0"
                        search
                        type="number"
                        enter-button="%"
                    />
                </Form-item>

                <Form-item>
                    <Button
                        class="add-submit"
                        type="primary"
                        @click="handleSubmit"
                    >
                        {{ isEdit ? '保存' : '添加' }}
                    </Button>
                    <Button
                        class="add-submit cancel"
                        @click="closeModal"
                    >
                        取消
                    </Button>
                </Form-item>
            </Form>
        </Modal>
    </div>
</template>

<script>
import CouponModal from '@/components/couponModal/index';

export default {
    name: 'ClassStore',
    components: {
        CouponModal
    },
    data() {
        const confirmDiscount = (rule, value, callback) => {
            if (!value) {
                callback(new Error('平台总抽成不能为空'));
            }
            else if (+value > 100 || +value <=0) {
                callback(new Error('平台总抽成需要在0-100之间'));
            }
            else {
                callback();
            }
        }

        const confirmYongjin = (rule, value, callback) => {
            const {discount, sellYongjin} = this.formItem;
            if (!value) {
                callback(new Error('团长抽成不能为空'));
            }
            else if (+value > 100 || +value <=0) {
                callback(new Error('团长抽成需要在0-100之间'));
            }
            else if (+value + +sellYongjin > +discount) {
                callback(new Error('团长抽成和推荐员抽成相加不能大于平台总抽成'));
            }
            else {
                callback();
            }
        }

        const confirmSellYongjin = (rule, value, callback) => {
            const {discount, yongjin} = this.formItem;
            if (+value > 100 || +value <0) {
                callback(new Error('推荐员抽成需要在0-100之间'));
            }
            else if (+value + +yongjin > +discount) {
                callback(new Error('团长抽成和推荐员抽成相加不能大于平台总抽成'));
            }
            else {
                callback();
            }
        }

        return {
            self: this,
            columns: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '店铺类别',
                    key: 'title',
                    minWidth: 200,
                    align: 'center'
                },
                {
                    title: '平台总抽成',
                    key: 'discount',
                    minWidth: 120,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {}, `${params.row.discount}%`);
                    }
                },
                {
                    title: '团长抽成',
                    key: 'yongjin',
                    minWidth: 120,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {}, `${params.row.yongjin}%`);
                    }
                },
                {
                    title: '推荐员抽成',
                    key: 'sellYongjin',
                    minWidth: 120,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {}, `${params.row.sellYongjin}%`);
                    }
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 150,
                    align: 'center',
                    fixed: 'right',
                    render: (h, params) => {
                        return h('div', {}, [
                            h('Button', {
                                class: 'ivu-btn-primary ivu-btn-small class-btn',
                                on: {
                                    click: () => {
                                        this.edit(params.index);
                                    }
                                }
                            }, '编辑'),
                            h('Button', {
                                class: 'ivu-btn-error ivu-btn-small class-btn',
                                on: {
                                    click: () => {
                                        this.remove(params.index);
                                    }
                                }
                            }, '删除')
                        ]);
                    }
                }
            ],
            list: [],
            formItem: {
                title: '',
                discount: '',
                yongjin: '',
                sellYongjin: ''
            },
            ruleValidate: {
                title: [
                    {required: true, message: '请输入店铺类别', trigger: 'change'}
                ],
                discount: [
                    {required: true, validator: confirmDiscount, trigger: 'change'}
                ],
                yongjin: [
                    {required: true, validator: confirmYongjin, trigger: 'change'}
                ],
                sellYongjin: [
                    {required: true, validator: confirmSellYongjin, trigger: 'change'}
                ]
            },
            showModal: false,
            isEdit: false
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        openAdd() {
            this.isEdit = true;
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
            this.isEdit = false;
            this.$refs['formItem'].resetFields();
        },
        edit(index) {
            this.formItem = JSON.parse(JSON.stringify(this.list[index]));
            console.log('this.formItem', this.formItem);
            this.showModal = true;
        },
        remove(index) {
            this.$Modal.confirm({
                title: '提示',
                content: '确认要删除吗？',
                onOk: () => {
                    this.$axios({
                        url: '/api/setup/classStore/delete',
                        method: 'post',
                        data: {
                            id: this.list[index].id
                        }
                    }).then(res => {
                        const {message} = res.data;
                        this.getData();
                        this.$Message.success(message);
                    }).catch(() => {});
                }
            });
        },
        getData() {
            this.$axios({
                url: '/api/setup/classStore/list',
                method: 'post'
            }).then(res => {
                const {list} = res.data;
                if (list.length === 0) {
                    return;
                }
                this.list = list;
            }).catch(() => {});
        },
        handleSubmit() {
            console.log('formItem', this.formItem);
            this.$refs['formItem'].validate(valid => {
                if (!valid) {
                    this.$Message.error('存在填写错误，请检查');
                    return;
                }

                this.$axios({
                    url: '/api/setup/classStore/add',
                    method: 'post',
                    data: this.formItem
                }).then(res => {
                    this.closeModal();
                    
                    if (res.data.code === 0) {
                        return;
                    }

                    this.getData();
                    this.$Message.success(res.data.message);
                }).catch(() => {});
            })
        }
    }
}
</script>

<style lang="less" scoped>
    .add-form {
        margin-right: 20px;

        .add-submit {
            width: 100px;
        }
    }
    .add-form:nth-of-type(1) {
        margin-top: 15px;
    }
    .cancel {
        margin-left: 20px;
    }
    .manjian-input {
        width: 60%;
    }
    .youhui-input {
        width: 40%;
    }

    .list-page {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }
    .tips {
        position: absolute;
        font-size: 13px;
        color: #999;
        line-height: 26px;
    }
    
    ::v-deep {
        .class-btn {
            margin: 0 5px;
        }

        .manjian-input {
            .ivu-input-group-prepend {
                color: #fff;
                padding-left: 16px;
                padding-right: 16px;
                background-color: #2d8cf0;
            }
            .ivu-input {
                border-left: 0;
            }

            .ivu-input-group-append {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        .youhui-input {
            .ivu-input {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-left: 0;
            }
        }
    }
</style>
