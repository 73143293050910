<template>
    <div class="list-container">
        <Form
            class="add-form"
            ref="formItem"
            :model="formItem"
            :rules="ruleValidate"
            :label-width="labelWidth"
            label-position="right"
        >
            <Form-item class="form-title" label=""></Form-item>

            <Form-item prop="defaultPyPoster" label="支付封面图">
                <CUpload
                    :default-list="formItem.defaultPyPoster"
                    :maxLength="1"
                    :size="300"
                    sizeTip
                    @change="onChange($event, 'defaultPyPoster')"
                />
            </Form-item>

            <Form-item prop="defaultPyPoster" label="小程序顶部背景图">
                <CUpload
                    :default-list="formItem.topbar"
                    :maxLength="1"
                    :size="300"
                    sizeTip
                    @change="onChange($event, 'topbar')"
                />
            </Form-item>

            <Form-item prop="phone" label="客服电话">
                <Input
                    v-model="formItem.phone"
                    class="add-210"
                    placeholder="请输入店铺联系方式"
                />
            </Form-item>

            <!-- <Form-item prop="raffleText" label="抽奖文案">
                <div v-for="item in formItem.raffleText" :key="item.title" class="flexbox raffle">
                    <div class="flexbox-fixed raffle-title">{{ item.title }}</div>
                    <div class="flexbox-auto">
                        <Input
                            v-model="item.text"
                            class="add-210"
                            placeholder="请输入店铺联系方式"
                        />
                    </div>
                </div>
            </Form-item> -->

            <Form-item class="form-title" label="申请入驻"></Form-item>

            <Form-item prop="rzPhone" label="入驻电话">
                <Input
                    v-model="formItem.rzPhone"
                    class="add-210"
                    placeholder="请输入店铺联系方式"
                />
            </Form-item>

            <Form-item prop="rzName" label="入驻联系人">
                <Input
                    v-model="formItem.rzName"
                    class="add-210"
                    placeholder="请输入店铺联系方式"
                />
            </Form-item>

            <Form-item prop="rzQRCode" label="二维码">
                <CUpload
                    :default-list="formItem.rzQRCode"
                    :maxLength="1"
                    :size="300"
                    sizeTip
                    @change="onChange($event, 'rzQRCode')"
                />
            </Form-item>
            
            <Form-item>
                <Button
                    class="add-submit"
                    type="primary"
                    size="large"
                    @click="handleSubmit"
                >
                    保存
                </Button>
            </Form-item>
        </Form>
    </div>
</template>

<script>
import CUpload from '@/components/upload/index';

export default {
    name: 'SetupInfo',
    components: {
        CUpload
    },
    data() {
        const confirmDefaultPyPoster = (rule, value, callback) => {
            if (value.length === 0) {
                callback(new Error('请上传默认支付顶部封面图'));
            }
            else {
                callback();
            }
        }

        const validatePhone = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入客服电话'));
            }
            else {
                const isPhone = /^1[3456789]\d{9}$/;
                const isMob=/^((0\d{2,3})-)?(\d{7,8})$/;
                if (isMob.test(value) || isPhone.test(value)) {
                    callback();
                }
                else{
                    callback(new Error('电话格式错误！'));
                }
            }
        };

        const validateRzPhone = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入店铺联系方式'));
            }
            else {
                const isPhone = /^1[3456789]\d{9}$/;
                const isMob=/^((0\d{2,3})-)?(\d{7,8})$/;
                if (isMob.test(value) || isPhone.test(value)) {
                    callback();
                }
                else{
                    callback(new Error('联系方式格式错误！'));
                }
            }
        };

        const validateRzQRCode = (rule, value, callback) => {
            if (value.length === 0) {
                callback(new Error('请上传入驻联系人二维码'));
            }
            else {
                callback();
            }
        }

        // const validateRaffle = (rule, value, callback) => {
        //     let tag = false;
        //     for (const item of value) {
        //         if (!item.text) {
        //             tag = true;
        //             break;
        //         }
        //     }

        //     if (tag) {
        //         callback(new Error('请补充抽奖文案'));
        //     }
        //     else {
        //         callback();
        //     }
        // }

        return {
            formItem: {
                defaultPyPoster: [],
                phone: '',
                raffleText: [
                    {
                        title: '左上文案',
                        text: '抽奖文案1'
                    },
                    {
                        title: '左中文案',
                        text: '抽奖文案2'
                    },
                    {
                        title: '左下文案',
                        text: '抽奖文案3'
                    },
                    {
                        title: '中下文案',
                        text: '抽奖文案4'
                    },
                    {
                        title: '右下文案',
                        text: '抽奖文案5'
                    },
                    {
                        title: '右中文案',
                        text: '抽奖文案6'
                    },
                    {
                        title: '右上文案',
                        text: '抽奖文案7'
                    },
                    {
                        title: '中上文案',
                        text: '抽奖文案8'
                    }
                ],
                rzPhone: '',
                rzName: '',
                rzQRCode: []
            },
            ruleValidate: {
                defaultPyPoster: [
                    {required: true, validator: confirmDefaultPyPoster, trigger: 'blur'}
                ],
                phone: [
                    {required: true, validator: validatePhone, trigger: 'blur'}
                ],
                // raffleText: [
                //     {required: true, validator: validateRaffle, trigger: 'change'}
                // ],
                rzPhone: [
                    {required: true, validator: validateRzPhone, trigger: 'blur'}
                ],
                rzName: [
                    {required: true, message: '入驻联系人不能为空', trigger: 'change'}
                ],
                rzQRCode: [
                    {required: true, validator: validateRzQRCode, trigger: 'blur'}
                ]
            },
            labelWidth: 150
        }
    },
    computed: {
    },
    mounted() {
        this.$axios({
            url: '/api/setup/list',
            method: 'post'
        }).then(res => {
            if (res.data.list.length === 0) {
                return;
            }
            this.formItem = res.data.list[0];
            console.log('this.formItem', this.formItem);
        });
    },
    methods: {
        handleSubmit() {
            console.log('formItem', this.formItem);
            this.$refs['formItem'].validate(valid => {
                if (!valid) {
                    this.$Message.error('存在填写错误，请检查');
                    return;
                }

                this.$axios({
                    url: '/api/setup/update',
                    method: 'post',
                    data: this.formItem
                }).then(res => {
                    if (res.data.code === 0) {
                        return;
                    }
                    
                    this.$Message.success(res.data.message);
                }).catch(() => {});
            })
        },
        onChange(value, name) {
            this.formItem[name] = value;
        }
    }
}
</script>

<style lang="less" scoped>
    .add-form {
        // margin-top: 20px;

        .add-submit {
            width: 150px;
        }
    }
    .add-150 {
        width: 150px;
    }
    .add-210 {
        width: 210px;
    }
    .add-250 {
        width: 250px;
    }
    .form-title {
        font-weight: bold;
        padding-top: 20px;
    }
    .form-title:first-of-type {
        padding-top: 0;
    }
    .tips {
        color: #999;
        margin-left: 15px;
    }
    .raffle {
        margin-top: 15px;
    }
    .raffle:first-child {
        margin-top: 0;
    }
    .raffle-title {
        margin-right: 10px;
    }
    ::v-deep {
        .form-title {
            .ivu-form-item-label {
                font-size: 18px;
            }
        }
    }
</style>
