<template>
    <div class="list-container">
        <Form
            class="search-form"
            ref="searchItem"
            inline
            :model="searchItem"
            @keydown.native.enter.prevent
        >
            <Form-item class="flexbox" prop="query">
                <Input
                    v-model="searchItem.query"
                    class="search-input"
                    clearable
                    search
                    enter-button="搜索"
                    placeholder="请输入关键字"
                    @on-search="onSearch"
                    @on-clear="onSearch"
                />
            </Form-item>
        </Form>
        <Table border :context="self" :columns="columns" :data="list"/>
        <div v-if="list && list.length > 0 && total > rn" class="list-page">
            <Page
                :current="pn + 1"
                :total="total"
                :page-size="rn"
                show-total
                @on-change="onPageChange"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'TuanList',
    components: {
    },
    data() {
        return {
            self: this,
            columns: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '团长头像',
                    key: 'src',
                    width: 100,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'list-photo'
                        }, [
                            params.row.src ? h('img', {
                                class: 'list-photo-img',
                                attrs: {
                                    src: params.row.src
                                }
                            }) : ''
                        ]);
                    }
                },
                {
                    title: '团长名称',
                    key: 'xingming',
                    align: 'center',
                    width: 100
                },
                {
                    title: '团长昵称',
                    key: 'nickname',
                    align: 'center',
                    minWidth: 200
                },
                {
                    title: '团长电话',
                    key: 'dianhua',
                    align: 'center',
                    minWidth: 130
                },
                {
                    title: '总流水',
                    key: 'zls',
                    align: 'center',
                    minWidth: 120,
                    render: (h, params) => {
                        return h('div', {
                            class: 'table-total'
                        }, `${params.row.zls}元`);
                    }
                },
                {
                    title: '今日流水(元)',
                    key: 'jrls',
                    align: 'center',
                    minWidth: 120,
                    render: (h, params) => {
                        return h('div', {
                            class: 'table-total'
                        }, `${params.row.jrls}元`);
                    }
                },
                {
                    title: '已提现(元)',
                    key: 'cashoutEd',
                    align: 'center',
                    minWidth: 120,
                    render: (h, params) => {
                        return h('div', {
                            class: 'table-total'
                        }, `${params.row.cashoutEd}元`);
                    }
                },
                {
                    title: '未提现(元)',
                    key: 'notCash',
                    align: 'center',
                    minWidth: 120,
                    render: (h, params) => {
                        return h('div', {
                            class: 'table-total'
                        }, `${params.row.notCash}元`);
                    }
                },
                {
                    title: '团员列表',
                    key: 'yuanList',
                    align: 'center',
                    width: 100,
                    fixed: 'right',
                    render: (h, params) => {
                        return h('Button', {
                            class: 'ivu-btn-primary ivu-btn-small class-btn',
                            on: {
                                click: () => {
                                    this.vipPush(params.row);
                                }
                            }
                        }, '管理')
                    }
                }
            ],
            list: [],
            total: 0,
            pn: 0,
            rn: 10,
            searchItem: {
                query: ''
            },
            cacheItem: null
        }
    },
    computed: {
    },
    mounted() {
        this.cacheItem = JSON.parse(JSON.stringify(this.searchItem));
        this.getData(this.pn);
    },
    methods: {
        onPageChange(pn) {
            this.pn = pn - 1;
            this.getData(this.pn);
        },
        getData(pn) {
            this.$axios({
                url: '/api/tuan/tuanList',
                method: 'post',
                data: Object.assign({}, this.searchItem, {
                    pn,
                    rn: this.rn
                })
            }).then(res => {
                const {list, total} = res.data;
                this.list = list;
                if (+this.total !== +total) {
                    this.total = total;
                }
            }).catch(() => {});
        },
        onSearch() {
            this.pn = 0;
            this.getData(this.pn);
        },
        vipPush(data) {
            this.$router.push({
                path: '/tuan/tuanDetail',
                query: {
                    id: data.id
                }
            });
        }
    }
}
</script>

<style lang="less" scoped>
    .search-form {
        padding-top: 8px;
    }
    .search-input {
        width: 350px;
    }
    .list-page {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }
    ::v-deep {
        .list-photo {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 40px;
            overflow: hidden;
            margin: 10px 0 4px;
            box-sizing: initial;
            border-radius: 5px;

            &-img {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                height: auto;
                border-radius: 5px;
            }
        }
        .class-btn {
            margin: 0 5px;
        }
        .table-total {
            color: #f65b1a;
            font-weight: bold;
        }
    }
</style>
