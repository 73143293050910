<template>
    <div class="list-container">
        <Form
            class="search-form"
            ref="searchItem"
            inline
            @keydown.native.enter.prevent="onSearch"
        >
            <Form-item>
                <Input
                    v-model="searchItem.openid"
                    class="search-input"
                    placeholder="请输入用户ID"
                    clearable
                    @on-clear="onSearch"
                />
            </Form-item>

            <Form-item>
                <span class="search-coupon-label">优惠券状态</span>
                <Select
                    v-model="searchItem.isuse"
                    class="search-coupon"
                    placeholder="优惠券状态"
                    @on-change="onSearch"
                >
                    <Option
                        v-for="item in useList"
                        :value="item.id"
                        :key="item.id"
                    >{{ item.text }}</Option>
                </Select>
            </Form-item>

            <div class="search-div">
                <Form-item>
                    <DatePicker
                        :value="searchItem.time"
                        class="datepicker"
                        type="datetimerange"
                        split-panels
                        placement="bottom-end"
                        placeholder="默认不限制"
                        @on-change="getTime"
                        @on-ok="onSearch"
                        @on-clear="celarTime"
                    />
                </Form-item>

                <Form-item>
                    <Button
                        class="search-submit"
                        type="primary"
                        @click="onSearch"
                    >
                        搜索
                    </Button>
                </Form-item>
                <Form-item>
                    <Button
                        @click="onClear"
                    >
                        清空
                    </Button>
                </Form-item>
            </div>
        </Form>

        <Table
            border
            stripe
            :context="self"
            :columns="columns"
            :data="list"
        />

        <div v-if="list && list.length > 0 && total > rn" class="list-page">
            <Page
                :current="pn + 1"
                :total="total"
                :page-size="rn"
                show-total
                @on-change="onPageChange"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'CouponStoreList',
    components: {
    },
    data() {
        return {
            self: this,
            columns: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '类型',
                    key: 'type',
                    minWidth: 100,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {}, params.row.type === '1' ? '满减优惠' : '免单');
                    }
                },
                {
                    title: '优惠券标题',
                    key: 'title',
                    minWidth: 200,
                    align: 'center'
                },
                {
                    title: '优惠券内容',
                    key: 'content',
                    minWidth: 300,
                    align: 'center'
                },
                {
                    title: '用户ID',
                    key: 'openid',
                    minWidth: 280,
                    align: 'center'
                },
                {
                    title: '使用店铺',
                    key: 'useStoreId',
                    minWidth: 200,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {}, params.row.store ? params.row.store.name : '');
                    }
                },
                {
                    title: '满减(元)',
                    key: 'manjian',
                    minWidth: 100,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {}, params.row.type === '1' ? params.row.manjian : '暂无');
                    }
                },
                {
                    title: '优惠(元)',
                    key: 'youhui',
                    minWidth: 100,
                    align: 'center'
                },
                {
                    title: '日期',
                    key: 'dateTime',
                    minWidth: 180,
                    align: 'center'
                },
                {
                    title: '截止日期',
                    key: 'endTime',
                    minWidth: 180,
                    align: 'center'
                },
                {
                    title: '优惠券状态',
                    key: 'isuse',
                    minWidth: 100,
                    align: 'center',
                    fixed: 'right',
                    render: (h, params) => {
                        return h('div', {}, [
                            h('Tag', {
                                props: {
                                    color: `${+params.row.isuse === 2 ? 'error' : 'success'}`
                                }
                            }, +params.row.isuse === 2 ? '已使用' : '未使用'),
                        ]);
                    }
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 150,
                    align: 'center',
                    fixed: 'right',
                    render: (h, params) => {
                        return h('div', {}, [
                            h('Button', {
                                class: 'ivu-btn-primary ivu-btn-small class-btn',
                                on: {
                                    click: () => {
                                        this.edit(params.index);
                                    }
                                }
                            }, '编辑'),
                            h('Button', {
                                class: 'ivu-btn-error ivu-btn-small class-btn',
                                on: {
                                    click: () => {
                                        this.remove(params.index);
                                    }
                                }
                            }, '删除')
                        ]);
                    }
                }
            ],
            list: [],
            total: 0,
            pn: 0,
            rn: 10,
            searchItem: {
                storeId: '',
                openid: '',
                isuse: '0',
                time: []
            },
            cacheData: null,
            storeList: [],

            // 优惠券使用状态
            useList: [
                {
                    id: '0',
                    text: '全部'
                },
                {
                    id: '1',
                    text: '未使用'
                },
                {
                    id: '2',
                    text: '已使用'
                }
            ]
        }
    },
    computed: {
        getStoreData() {
            return this.$store.state.storeData;
        }
    },
    watch: {
        getStoreData: {
            immediate: true,
            handler(storeData) {
                if (!storeData) return;
                console.log('storeData', storeData);
                
                this.searchItem.storeId = storeData.id;

                this.cacheData = JSON.parse(JSON.stringify(this.searchItem));
                this.getData(this.pn);
            }
        }
    },
    mounted() {
        // this.cacheData = JSON.parse(JSON.stringify(this.searchItem));
        // const {pn} = this.$route.params;
        // if (pn) {
        //     this.pn = +pn;
        // }

        // this.$axios.all([this.getShopList()]).then(res => {
        //     if (res.length === 0) {
        //         return;
        //     }
        //     console.log('res', res);
        //     this.storeList = res[0];
        // });
        
        // this.getData(this.pn);
    },
    methods: {
        edit(index) {
            this.formItem = JSON.parse(JSON.stringify(this.list[index]));
            this.visibility = true;
        },
        remove(index) {
            this.$Modal.confirm({
                title: '提示',
                content: '确认要删除吗？',
                onOk: () => {
                    this.$axios({
                        url: '/api/coupon/delete',
                        method: 'post',
                        data: {
                            id: this.list[index].id
                        }
                    }).then(res => {
                        this.list.splice(index, 1);
                        this.$Message.success(res.data.message);
                    }).catch(() => {});
                }
            });
        },

        onPageChange(pn) {
            this.pn = pn - 1;
            this.getData(this.pn);
        },
        getData(pn) {
            console.log('this.searchItem', this.searchItem);

            this.$axios({
                url: '/api/coupon/list',
                method: 'post',
                data: Object.assign({}, this.searchItem, {
                    time: JSON.stringify(this.searchItem.time),
                    pn,
                    rn: this.rn
                })
            }).then(res => {
                const {list, total} = res.data;
                this.list = list;
                if (+this.total !== +total) {
                    this.total = total;
                }
            }).catch(() => {});
        },
        onSearch() {
            console.log('searchItem', JSON.stringify(this.searchItem));
            this.pn = 0;
            this.getData(this.pn);
        },
        onClear() {
            this.searchItem = this.cacheData;
            this.$set(this.searchItem, 'time', this.cacheData.time);
            console.log('this.searchItem', this.searchItem);
            this.pn = 0;
            this.getData(this.pn);
        },
        getTime(value) {
            this.searchItem.time = value;
        },
        celarTime() {
            this.searchItem.time = [];
            this.onSearch();
        }
    }
}
</script>

<style lang="less" scoped>
    .add-form {
        margin-top: 20px;
        margin-right: 20px;
    }
    .search-input {
        width: 270px;
    }
    .search-submit {
        width: 120px;
    }
    .search-coupon {
        width: 100px;
    }
    .search-coupon-label {
        margin-right: 10px;
    }
    .list-page {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }
    .search-div {
        margin-top: -10px;
    }
    .datepicker {
        width: 360px;
    }
    ::v-deep {
        .class-btn {
            margin: 0 5px;
        }
    }
</style>
