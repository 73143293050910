<template>
    <div class="editor-wrap">
        <Toolbar
            class="editor-tool"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
        />
        <Editor
            class="editor"
            :style="{
                height: height + 'px'
            }"
            v-model="html"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
            @onChange="onChange"
        />
    </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';

export default {
    name: 'editor',
    components: {
        Editor,
        Toolbar
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        height: {
            type: [String, Number],
            default: '420'
        }
    },
    data () {
        return {
            editor: null,
            html: this.value,
            toolbarConfig: {},
            editorConfig: {
                placeholder: '请输入内容',
                MENU_CONF: {
                    // 配置上传图片
                    uploadImage: {
                        customUpload: this.uploadImg
                    }
                }
            },
            mode: 'default'
        }
    },
    watch: {
        value(val) {
            this.html = val;
        }
    },
    mounted() {
    },
    methods: {
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
        onChange(editor) {
            this.$emit('change', editor.getHtml());
        },
        uploadImg(file, insertFn) {
            console.log('file', file);
            let formData = new FormData();
            formData.append("file", file);
            this.$axios({
                url: '/api/upload',
                method: 'post',
                data: formData
            }).then(res => {
                insertFn(res.data.url);
            }).catch(() => {});
        }
    },
    beforeDestroy() {
        const editor = this.editor;
        if (editor == null) return;
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped lang="less">
    .editor-wrap {
        border: 1px solid #ccc;
    }
    .editor-tool {
        border-bottom: 1px solid #ccc
    }
    .editor {
        overflow-y: hidden;
    }
    ::v-deep .w-e-text-container [data-slate-editor] p {
        margin: 4px 0;
    }
    ::v-deep .w-e-text-placeholder {
        top: -1px;
    }
</style>
