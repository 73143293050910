<template>
    <div class="list-container">
        <Button
            class="add-btn"
            type="primary"
            @click="backPage"
        >
            返回
        </Button>

        <Filters
            show-input
            placeholder="请输入店铺名称"
            @search="searchFun"
        />

        <Divider :top="0" />

        <div class="list">
            <div class="title">店铺列表</div>

            <Button
                class="download-btn"
                type="primary"
                title="下载订单列表到本地"
                @click="downFile"
            >
                下载店铺
            </Button>

            <Table
                border
                stripe
                :context="self"
                :columns="columns"
                :data="list"
            />

            <div v-if="list && list.length > 0 && total > rn" class="list-page">
                <Page
                    :current="pn + 1"
                    :total="total"
                    :page-size="rn"
                    show-total
                    @on-change="onPageChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Divider from '@/components/divider/index';
import Filters from '../components/filters';
import {downStore} from '@/utils/utils';

export default {
    name: 'TuijianyuanDetail',
    components: {
        Divider,
        Filters
    },
    data() {
        return {
            self: this,
            searchItem: {
                query: ''
            },
            columns: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '店铺封面',
                    key: 'src',
                    width: 100,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'list-photo'
                        }, [
                            params.row.src ? h('img', {
                                class: 'list-photo-img',
                                attrs: {
                                    src: params.row.src
                                }
                            }) : ''
                        ]);
                    }
                },
                {
                    title: '店铺名称',
                    key: 'name',
                    align: 'center',
                    minWidth: 250
                },
                {
                    title: '推荐员编号',
                    key: 'tuijianyuanNumber',
                    align: 'center',
                    minWidth: 110,
                    render: (h, params) => {
                        return h('span', {
                            class: 'list-number'
                        }, params.row.tuijianyuanNumber);
                    }
                },
                {
                    title: '推荐员收入(元)',
                    key: 'moneySum',
                    align: 'center',
                    minWidth: 120,
                    render: (h, params) => {
                        return h('span', {
                            class: 'table-total'
                        }, params.row.moneySum);
                    }
                }
            ],
            list: [],
            total: 0,
            pn: 0,
            rn: 10
        }
    },
    computed: {
    },
    mounted() {
        this.getData(this.pn);
    },
    methods: {
        searchFun(options) {
            this.searchItem = options;
            this.pn = 0;
            this.getData(this.pn);
        },
        onPageChange(pn) {
            console.log('onPageChange => pn', pn);
            this.pn = pn - 1;
            this.getData(this.pn);
        },
        getData(pn) {
            this.$axios({
                url: '/api/capital/tuijianyuan/storeList',
                method: 'post',
                data: Object.assign({}, this.searchItem, {
                    number: this.$route.query.number,
                    time: this.$route.query.time,
                    pn,
                    rn: this.rn
                })
            }).then(res => {
                const {list, total} = res.data;
                this.list = list;
                if (+this.total !== +total) {
                    this.total = total;
                }
            }).catch(() => {});
        },
        backPage() {
            this.$router.go(-1);
        },
        downFile() {

            // 下载订单到本地
            downStore(this.list);
        }
    }
}
</script>

<style lang="less" scoped>
    .search-input {
        width: 350px;
    }
    .list {
        position: relative;
        margin-top: 20px;
    }
    .download-btn {
        position: absolute;
        right: 0;
        top: -7px;
    }
    .list-page {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }
    ::v-deep {
        .list-number {
            color: #19be6b;
            font-weight: bold;
        }
        .list-photo {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 40px;
            overflow: hidden;
            margin: 10px 0 4px;
            box-sizing: initial;
            border-radius: 5px;

            &-img {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                height: auto;
                border-radius: 5px;
            }
        }
        .class-btn {
            margin: 0 5px;
        }
        .table-total {
            color: #f65b1a;
            font-weight: bold;
        }
    }
</style>
