<template>
    <div class="list-container">
        <Button
            class="add-btn"
            type="primary"
            @click="backPage"
        >
            返回
        </Button>
        
        <Content v-if="this.openid" :openid="this.openid" />
    </div>
</template>

<script>
import Divider from '@/components/divider/index';
import OrderContent from '../../order/components/content';
import Content from '../../order/components/content';

export default {
    name: 'YuanDetail',
    components: {
        Divider,
        Content
    },
    data() {
        return {
            self: this,
            columns: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '团长头像',
                    key: 'src',
                    width: 100,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'list-photo'
                        }, [
                            params.row.src ? h('img', {
                                class: 'list-photo-img',
                                attrs: {
                                    src: params.row.src
                                }
                            }) : ''
                        ]);
                    }
                },
                {
                    title: '团长昵称',
                    key: 'nickname',
                    align: 'center',
                    minWidth: 200
                },
                {
                    title: '团长ID',
                    key: 'openid',
                    align: 'center',
                    minWidth: 280
                },
                {
                    title: '总流水(元)',
                    key: 'zls',
                    align: 'center',
                    minWidth: 120
                },
                {
                    title: '今日流水(元)',
                    key: 'jrls',
                    align: 'center',
                    minWidth: 120
                }
            ],
            list: [],
            total: 0,
            pn: 0,
            rn: 10,
            searchItem: {
                query: ''
            },
            openid: ''
        }
    },
    async mounted() {
        console.log('this.$route.query', this.$route.query);
        const {id, subId} = this.$route.query;
        if (!id) {
            this.backTuanList();
        }
        else if (!subId) {
            this.backPage();
        }
        const vipInfo = (await this.getData(subId)).data.list;
        console.log('vipInfo', vipInfo);
        this.openid = vipInfo[0].openid;
    },
    methods: {
        getData(id) {
            return this.$axios({
                url: '/api/vip/getVip',
                method: 'post',
                data: {
                    id
                }
            });
        },
        backPage() {
            this.$router.push({
                path: '/tuan/tuanDetail',
                query: {
                    id: this.$route.query.id
                }
            });
        },
        backTuanList() {
            this.$router.push({
                path: '/tuan/tuanList',
                query: {
                    id: this.$route.query.id
                }
            });
        }
    }
}
</script>

<style lang="less" scoped>
</style>
