<template>
    <div class="order-container">
        <div class="order-filter">
            <div class="order-filter-head">

                <!-- 搜索框 -->
                <Input
                    v-model="searchItem.query"
                    class="order-filter-foo-search"
                    search
                    enter-button="查找"
                    placeholder="订单编号/店铺名称"
                    @on-search="searchFun"
                />

                <div class="order-filter-status">
                    <template v-for="item in filterStatus">
                        <Button
                            :key="item.text"
                            class="order-filter-status-btn"
                            type="primary"
                            :ghost="searchItem.status === item.value ? false : true"
                            @click="getStatus(item.value)"
                        >
                            {{ item.text }}
                        </Button>
                    </template>
                </div>
            </div>

            <div class="order-filter-foo">

                <!-- 昨天、今天、7天内检索条件 -->
                <div class="order-filter-foo-time">
                    <Button
                        v-for="item in filterTime"
                        :key="item.text"
                        class="order-filter-status-btn"
                        type="primary"
                        :ghost="JSON.stringify(searchItem.time) === JSON.stringify(item.value) ? false : true"
                        @click="getTime(item.value)"
                    >
                        {{ item.text }}
                    </Button>
                </div>

                <!-- 按日期检索 -->
                <div class="order-filter-foo-date">
                    <DatePicker
                        :value="searchItem.time"
                        class="order-filter-foo-datepicker"
                        type="datetimerange"
                        confirm
                        split-panels
                        placement="bottom-end"
                        placeholder="默认不限制"
                        @on-change="getTime"
                        @on-ok="searchFun"
                        @on-clear="celarTime"
                    />
                </div>
                <Button
                    class="order-filter-foo-btn"
                    type="primary"
                    @click="searchFun"
                >
                    查找
                </Button>
            </div>
        </div>

        <Divider />

        <div class="orders">
            <div class="title">订单列表</div>

            <Button
                class="download-btn"
                type="primary"
                title="下载订单列表到本地"
                @click="downFile"
            >
                下载订单
            </Button>

            <Table
                class="order-table"
                border
                stripe
                :context="self"
                :columns="columns"
                :data="list"
            />

            <div v-if="list && list.length > 0 && total > rn" class="list-page">
                <Page
                    :current="pn + 1"
                    :total="total"
                    :page-size="rn"
                    show-total
                    @on-change="onPageChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import {downxls} from '@/utils/utils';
import Divider from '@/components/divider/index';

export default {
    props: {

        // 评论的分类 为''表示全部订单 '1'表示店内订单 '2'表示商品订单
        orderType: {
            type: String,
            default: ''
        },
        openid: {
            type: String,
            defaule: ''
        }
    },
    components: {
        Divider
    },
    data() {
        return {
            self: this,
            searchItem: {
                status: '',
                query: '',
                time: []
            },
            filterStatus: [
                {
                    value: '1',
                    orderType: '2',
                    text: '待核销'
                },
                {
                    value: '2',
                    orderType: '2',
                    text: '已核销'
                },
                {
                    value: '3',
                    text: '已退款'
                },
                {
                    value: '',
                    text: '全部订单'
                }
            ],
            filterTime: [
                {
                    value: [moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss')],
                    text: '昨天'
                },
                {
                    value: [moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')],
                    text: '今天'
                },
                {
                    // value: [moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment().add(7, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss')],
                    value: [moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')],
                    text: '近七天'
                }
            ],
            cacheData: null,
            columns: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '订单号',
                    key: 'out_trade_no',
                    minWidth: 200,
                    align: 'center'
                },
                {
                    title: '用户头像',
                    key: 'src',
                    width: 100,
                    align: 'center',
                    render: (h, params) => {
                        const {src} = params.row.userInfo;
                        return h('div', {
                            class: 'list-photo'
                        }, [
                            src ? h('img', {
                                class: 'list-photo-img',
                                attrs: {
                                    src: src
                                }
                            }) : ''
                        ]);
                    }
                },
                {
                    title: '用户昵称',
                    key: 'nickname',
                    minWidth: 150,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {}, params.row.userInfo.nickname);
                    }
                },
                // {
                //     title: '所属团长',
                //     key: 'zhangOpenid',
                //     minWidth: 200,
                //     align: 'center',
                //     render: (h, params) => {
                //         return h('span', {}, params.row.zhangOpenid || '无');
                //     }
                // },
                {
                    title: '所属团长',
                    key: 'zhangOpenid',
                    minWidth: 200,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {}, params.row.tzName || '无');
                    },
                    render: (h, params) => {
                        const {tzId, tzName} = params.row;
                        return h('span', {
                            class: `${tzName ? 'link' : ''}`,
                            on: {
                                click: () => {
                                    this.pushPage(tzId);
                                }
                            }
                        }, tzName || '无');
                    }
                },
                {
                    title: '店铺名称',
                    key: 'storeName',
                    minWidth: 250,
                    align: 'center'
                },
                {
                    title: '余额支付(元)',
                    key: 'youhui',
                    minWidth: 120,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {
                            class: 'table-total'
                        }, params.row.yue);
                    }
                },
                {
                    title: '实际支付(元)',
                    key: 'total',
                    minWidth: 120,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {
                            class: 'table-total'
                        }, params.row.total);
                    }
                },
                {
                    title: '订单金额(元)',
                    key: 'shouldPay',
                    minWidth: 120,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {
                            class: 'table-total'
                        }, params.row.shouldPay);
                    }
                },
                {
                    title: '平台折扣金额(元)',
                    key: 'discountAmount',
                    minWidth: 150,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {
                            class: 'table-total'
                        }, params.row.discountAmount);
                    }
                },
                {
                    title: '优惠卷(元)',
                    key: 'youhui',
                    minWidth: 120,
                    align: 'center',
                    // render: (h, params) => {
                    //     return h('span', {
                    //         class: 'table-total'
                    //     }, `${params.row.youhui}(店铺满减)`); // params.row.manjian ? params.row.youhui : ''
                    // },
                    render: (h, params) => {
                        const {manjian, youhui, isStoreCoupon} = params.row;
                        return h('div', {
                            class: 'table-total'
                        }, [
                            h('div', {}, youhui || '无'),
                            youhui ? h('div', {}, `${isStoreCoupon ? '店铺' : '通用'}${manjian ? '满减' : '无限制'}`) : null
                        ]);
                    }
                },
                {
                    title: '订单类型',
                    key: 'qianggou',
                    minWidth: 120,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {
                            class: `${params.row.qianggou ? 'table-total' : ''}`
                        }, params.row.qianggou ? '抢购订单' : '普通订单');
                    }
                },
                {
                    title: '下单时间',
                    key: 'dateTime',
                    minWidth: 190,
                    align: 'center'
                },
                {
                    title: '订单状态',
                    key: 'status',
                    align: 'center',
                    width: 110,
                    fixed: 'right',
                    render: (h, params) => {
                        const {qianggou, status} = params.row;
                        return h('Tag', {
                            props: {
                                color: `${status === '1' ? 'primary' : (status === '2' ? 'success' : 'error')}`
                            }
                        }, `${qianggou ? (status === '0' ? '取消支付' : (status === '1' ? '待核销' : (status === '2' ? '已核销' : '已退款'))) : (status === '0' ? '取消支付' : (status === '1' ? '待支付' : (status === '2' ? '已支付' : '已退款')))}`)
                    }
                }
            ],
            list: [],
            total: 0,
            pn: 0,
            rn: 10
        }
    },
    computed: {
        getStoreData() {
            return this.$store.state.storeData;
        }
    },
    watch: {
        orderType: {
            immediate: true,
            handler(value) {
                console.log('orderType', value);
                this.filterStatus = [
                    {
                        value: '1',
                        text: value ? (value === '1' ? '待支付' : '待核销') : '待支付(待核销)'
                    },
                    {
                        value: '2',
                        text: value ? (value === '1' ? '已支付' : '已核销') : '已支付(已核销)'
                    },
                    {
                        value: '3',
                        text: '已退款'
                    },
                    {
                        value: '',
                        text: '全部订单'
                    }
                ]
                this.searchItem.orderType = value;
            }
        },
        openid: {
            immediate: true,
            handler(value) {
                this.searchItem.openid = value;
            }
        },
        getStoreData: {
            immediate: true,
            handler(storeData) {
                console.log('storeData', storeData);
                if (storeData.id) {
                    this.searchItem.storeId = storeData.id;
                }

                this.cacheData = JSON.parse(JSON.stringify(this.searchItem));
                this.getData(this.pn);
            }
        }
    },
    mounted() {
        console.log('filterTime', this.filterTime);
    },
    methods: {
        getStatus(value) {
            console.log('value', value);
            // if (!this.searchItem.orderType) {
            //     if (+value !== 3) {
            //         this.searchItem.orderType = 2;
            //     }
            //     else {
            //         this.searchItem.orderType = '';
            //     }
            // }
            this.searchItem.status = value;
            this.searchFun();
        },
        searchFun() {
            console.log('搜索', this.searchItem);
            this.pn = 0;
            this.getData(this.pn);
        },
        getTime(value, isDate) {
            this.searchItem.time = value;
            if (!isDate) {
                this.searchFun();
            }
        },
        onPageChange(pn) {
            this.pn = pn - 1;
            this.getData(this.pn);
        },
        getData(pn) {
            console.log('this.searchItem', this.searchItem);

            this.$axios({
                url: '/api/order/list',
                method: 'post',
                data: Object.assign({}, this.searchItem, {
                    time: JSON.stringify(this.searchItem.time),
                    pn,
                    rn: this.rn
                })
            }).then(res => {
                const {list, total} = res.data;
                this.list = list;
                console.log('this.list', this.list);
                if (+this.total !== +total) {
                    this.total = total;
                }
            }).catch(() => {});
        },
        downFile() {

            // 下载订单到本地
            downxls(this.list);
        },
        celarTime() {
            this.searchItem.time = [];
            this.searchFun();
        },
        pushPage(id) {
            if (!id) {
                return;
            }
            this.$router.push({
                path: `/tuan/tuanDetail?id=${id}`
            });
        }
    }
}
</script>

<style lang="less" scoped>
    .order-container {
        position: relative;
    }
    .order-filter-status {
        float: left;
        margin-left: 20px;
    }
    .order-filter-status-btn {
        margin-right: 10px;
    }
    .order-filter-foo {
        margin-top: 15px;
    }
    .order-filter-head::after,
    .order-filter-foo::after,
    .order-filter-foo-time::after {
        content: " ";
        display: block;
        clear: both;
    }
    .order-filter-foo-search {
        float: left;
        width: 300px;
    }
    .order-filter-foo-time {
        float: left;
    }
    .order-filter-foo-date {
        float: left;
        padding-left: 10px;
    }
    .order-filter-foo-datepicker {
        width: 360px;
    }
    .order-filter-foo-btn {
        border-radius: 0 4px 4px 0;
    }

    .order-table {
        margin-top: 15px;
    }
    .orders {
        position: relative;
        margin-top: 20px;
    }
    .download-btn {
        position: absolute;
        right: 0;
        top: -7px;
    }
    
    .list-page {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }
    ::v-deep {
        .order-filter-foo-datepicker .ivu-input {
            border-radius: 4px 0 0 4px;
        }

        .list-photo {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 40px;
            overflow: hidden;
            margin: 10px 0 4px;
            box-sizing: initial;
            border-radius: 5px;

            &-img {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                height: auto;
                border-radius: 5px;
            }
        }
        .table-total {
            color: #f65b1a;
            font-weight: bold;
        }
        .link {
            color: #19be6b;
            font-weight: bold;
            cursor: pointer;
        }
    }
</style>
