import Vue from 'vue';
import Vuex from 'vuex';
import VueXAlong from 'vuex-along';
import router from '../router';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        show: false,
        navData: null,

        // 一级导航宽度
        navWidth: 150,

        // 二级导航宽度
        subNavWidth: 130,
        storeData: null,
        account: null,
        isStore: null,
        token: '',
        spinShow: false,
        isMobile: false,
        openWiseNav: false
    },
    mutations: {
        updataShow(state, bool) {
            state.show = bool;
        },

        /**
         * 获取一级导航数据
         * @param {*} state 
         * @param {*} params 一级导航需要的参数，可以在main.js中进行查看
         */
        getNavData(state, params) {

            // 由于route执行速度快于页面跳转，所以这里需要在调用方法时传入参数
            let {routes, query} = params;

            // if总后台账号进入店铺展示逻辑 else总后台面板和店铺账号单独登录后展示逻辑
            if (query.isStore) {
                state.navData = routes.filter(item => {
                    if (item.meta.isShow && (item.meta.rank.indexOf(2) >= 0 || item.meta.rank.indexOf(3) >= 0)) {

                        // 增加新逻辑，3:总平台账号进入店铺有权限展示，商户账户不展示
                        if (state.account && state.account.rank === '2' && item.meta.rank.indexOf(3) >= 0) {
                            return;
                        }
                        item.meta.query = Object.assign({}, item.meta.query, query);

                        if (item.children && item.children.length > 0) {
                            item.children = item.children.filter(val => {
                                if (!val.redirect) {
                                    val.meta.query = Object.assign({}, val.meta.query, query);
                                }
                                return val;
                            });
                        }

                        return item;
                    }
                });
            }
            else {
                state.navData = routes.filter(item => {
                    if (item.meta.isShow && (!item.meta.rank || item.meta.rank.indexOf(+state.account.rank) >= 0)) {
                        item.meta.query = {};
                        if (item.children && item.children.length > 0) {
                            item.children = item.children.map(val => {
                                if (!val.redirect) {
                                    val.meta.query = {};
                                }
                                return val;
                            });
                        }
                        return item;
                    }
                });
            }
        },
        updataAccount(state, data) {
            state.account = data;
        },
        updataToken(state, data) {
            state.token = data;
        },
        updataIsStore(state, value) {
            state.isStore = value;
        },
        updataStoreData(state, data) {
            if (data && data.logo[0] && data.logo[0].url !== data.src) {
                data.src = data.logo[0].url;
            }
            state.storeData = Object.assign({}, data);
        },
        updataSpinShow(state, value) {
            state.spinShow = value;
        },
        getIsMobile(state) {
            let width = window.innerWidth;
            // let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
            if (width <= 767) {
                state.isMobile = true;
            }
            // state = false;
        },
        openWiseNavFn(state, data) {
            console.log('data', data);
            state.openWiseNav = data;
        },

        // 阻止页面滑动
        disableScroll() {

            // 获取当前滚动位置
            const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

            // 为<body>元素添加样式，禁止滚动
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
            document.body.style.top = `-${scrollPosition}px`;
        },

        // 接触阻止页面滑动
        enableScroll() {

            // 获取之前保存的滚动位置
            const scrollPosition = Math.abs(parseInt(document.body.style.top));

            // 移除<body>元素的样式，恢复滚动
            document.body.style.overflow = '';
            document.body.style.position = '';
            document.body.style.top = '';

            // 恢复之前的滚动位置
            window.scrollTo(0, scrollPosition);
        }
    },
    actions: {
    },
    modules: {
    },
    plugins: [VueXAlong({
        name: 'shopData',
        local: {
            list: ['account', 'storeData', 'token']
        },
        session: false
    })]
});
