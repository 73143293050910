<template>
    <div class="list-container">
        <Button
            class="add-btn"
            type="primary"
            @click="backPage"
        >
            返回
        </Button>

        <Table border :context="self" :columns="tuanColumns" :data="tuanList"/>

        <Divider />

        <div class="yuan">
            <div class="yuan-title">团员列表</div>
            <Form
                class="search-form"
                ref="searchItem"
                inline
                :model="searchItem"
                @keydown.native.enter.prevent="onSearch"
            >
                <Form-item prop="query">
                    <Input
                        v-model="searchItem.query"
                        class="search-input"
                        placeholder="请输入关键字"
                    />
                </Form-item>
                <Form-item>
                    <Button
                        class="search-submit"
                        type="primary"
                        @click="onSearch"
                    >
                        搜索
                    </Button>
                </Form-item>
            </Form>
            <Table class="yuan-table" border :context="self" :columns="yuanColumns" :data="yuanList"/>
            <div v-if="yuanList && yuanList.length > 0 && total > rn" class="list-page">
                <Page
                    :current="pn + 1"
                    :total="total"
                    :page-size="rn"
                    show-total
                    @on-change="onPageChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Divider from '@/components/divider/index';

export default {
    name: 'TuanDetail',
    components: {
        Divider
    },
    data() {
        return {
            self: this,
            tuanColumns: [
                {
                    title: '总流水(元)',
                    key: 'zls',
                    align: 'center',
                    minWidth: 120
                },
                {
                    title: '今日流水(元)',
                    key: 'jrls',
                    align: 'center',
                    minWidth: 120
                },
                {
                    title: '已提现(元)',
                    key: 'cashoutEd',
                    align: 'center',
                    minWidth: 120
                },
                {
                    title: '未提现(元)',
                    key: 'notCash',
                    align: 'center',
                    minWidth: 120
                }
            ],
            tuanList: [],
            yuanColumns: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '团员头像',
                    key: 'src',
                    width: 100,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'list-photo'
                        }, [
                            params.row.src ? h('img', {
                                class: 'list-photo-img',
                                attrs: {
                                    src: params.row.src
                                }
                            }) : ''
                        ]);
                    }
                },
                {
                    title: '团员昵称',
                    key: 'nickname',
                    align: 'center',
                    minWidth: 200
                },
                {
                    title: '团员ID',
                    key: 'openid',
                    align: 'center',
                    minWidth: 280
                },
                {
                    title: '总流水(元)',
                    key: 'zls',
                    align: 'center',
                    minWidth: 120
                },
                {
                    title: '今日流水(元)',
                    key: 'jrls',
                    align: 'center',
                    minWidth: 120
                },
                {
                    title: '订单列表',
                    key: 'action',
                    align: 'center',
                    width: 100,
                    fixed: 'right',
                    render: (h, params) => {
                        return h('Button', {
                            class: 'ivu-btn-primary ivu-btn-small class-btn',
                            on: {
                                click: () => {
                                    this.vipPush(params.row);
                                }
                            }
                        }, '查看')
                    }
                },
                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    width: 150,
                    fixed: 'right',
                    render: (h, params) => {
                        return h('Button', {
                            class: 'ivu-btn-primary ivu-btn-small class-btn',
                            on: {
                                click: () => {
                                    this.unbindTuan(params.row);
                                }
                            }
                        }, '解除团员关系')
                    }
                }
            ],
            yuanList: [],
            total: 0,
            pn: 0,
            rn: 10,
            searchItem: {
                query: ''
            }
        }
    },
    async mounted() {
        const {id} = this.$route.query;
        if (!id) {
            this.backPage();
        }
        const tuanDetail = (await this.getTuanDetail(id)).data.list;
        this.tuanList = tuanDetail;
        console.log('tuanDetail', tuanDetail);
        this.searchItem.zhangOpenid = tuanDetail[0].openid;
        await this.getData();
    },
    methods: {
        backPage() {
            this.$router.push({
                path: '/tuan/tuanList'
            });
            // this.$router.go(-1);
        },
        getTuanDetail(id) {
            return this.$axios({
                url: '/api/tuan/tuanList',
                method: 'post',
                data: {
                    id
                }
            });
        },
        getData() {
            this.$axios({
                url: '/api/tuan/yuanList',
                method: 'post',
                data: Object.assign({}, this.searchItem, {
                    pn: this.pn,
                    rn: this.rn
                })
            }).then(res => {
                const {list, total} = res.data;
                this.yuanList = list;
                if (+this.total !== +total) {
                    this.total = total;
                }
            }).catch(() => {});
        },
        onSearch() {
            this.pn = 0;
            this.getData();
        },
        onPageChange(pn) {
            this.pn = pn - 1;
            this.getData();
        },
        vipPush(data) {
            this.$router.push({
                path: '/tuan/yuanDetail',
                query: {
                    id: this.$route.query.id,
                    subId: data.id
                }
            });
        },
        unbindTuan(data) {
            console.log('data', data);
            this.$Modal.confirm({
                title: '提示',
                content: '确认要删除吗？',
                onOk: () => {
                    this.$axios({
                        url: '/api/tuan/unbindTuan',
                        method: 'post',
                        data: {
                            id: this.$route.query.id,
                            subId: data.id
                        }
                    }).then(res => {
                        const {total, message} = res.data;
                        if (this.pn > 1) {
                            this.pn = Math.ceil(total / this.rn) > this.pn ? this.pn : Math.ceil(total / this.rn);
                        }
                        this.getData();
                        this.$Message.success(message);
                    }).catch(() => {});
                }
            });
        }
    }
}
</script>

<style lang="less" scoped>
    .yuan {
        overflow: hidden;
    }
    .yuan-title {
        font-size: 18px;
        font-weight: bold;
        line-height: 1;
        padding-top: 20px;
    }
    .search-form {
        margin-top: 20px;
    }
    .search-input {
        width: 200px;
    }
    .search-submit {
        width: 120px;
    }
    .list-page {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }
    ::v-deep .list-photo {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 40px;
        overflow: hidden;
        margin: 10px 0 4px;
        box-sizing: initial;
        border-radius: 5px;

        &-img {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            height: auto;
            border-radius: 5px;
        }
        .class-btn {
            margin: 0 5px;
        }
    }
</style>
