<template>
    <div class="list-container">
        <Button
            class="add-btn"
            type="primary"
            @click="openCoupon"
        >
            添加优惠券
        </Button>

        <Table
            border
            stripe
            :context="self"
            :columns="columns"
            :data="list"
        />

        <div v-if="list && list.length > 0 && total > rn" class="list-page">
            <Page
                :current="pn + 1"
                :total="total"
                :page-size="rn"
                show-total
                @on-change="onPageChange"
            />
        </div>

        <!-- 添加优惠券 -->
        <CouponModal
            :data="formItem"
            :visibility="visibility"
            :store="true"
            @ok="okModal"
            @close="closeModal"
        />
    </div>
</template>

<script>
import CouponModal from '@/components/couponModal/index';

export default {
    name: 'CouponStoreSetup',
    components: {
        CouponModal
    },
    data() {
        return {
            self: this,
            columns: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '类型',
                    key: 'type',
                    minWidth: 100,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {}, params.row.type === '1' ? '满减优惠' : '免单');
                    }
                },
                {
                    title: '优惠券标题',
                    key: 'title',
                    minWidth: 200,
                    align: 'center'
                },
                {
                    title: '优惠券内容',
                    key: 'content',
                    minWidth: 300,
                    align: 'center'
                },
                {
                    title: '满减(元)',
                    key: 'manjian',
                    minWidth: 100,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {}, params.row.type === '1' ? params.row.manjian : '暂无');
                    }
                },
                {
                    title: '优惠(元)',
                    key: 'youhui',
                    minWidth: 100,
                    align: 'center'
                },
                {
                    title: '发布数量(张)',
                    key: 'number',
                    minWidth: 100,
                    align: 'center'
                },
                {
                    title: '截止日期',
                    key: 'endTime',
                    minWidth: 180,
                    align: 'center'
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 150,
                    align: 'center',
                    fixed: 'right',
                    render: (h, params) => {
                        return h('div', {}, [
                            h('Button', {
                                class: 'ivu-btn-primary ivu-btn-small class-btn',
                                on: {
                                    click: () => {
                                        this.edit(params.index);
                                    }
                                }
                            }, '编辑'),
                            h('Button', {
                                class: 'ivu-btn-error ivu-btn-small class-btn',
                                on: {
                                    click: () => {
                                        this.remove(params.index);
                                    }
                                }
                            }, '删除')
                        ]);
                    }
                }
            ],
            list: [],
            total: 0,
            pn: 0,
            rn: 10,
            formItem: {},
            visibility: false
        }
    },
    computed: {
        getStoreData() {
            return this.$store.state.storeData;
        }
    },
    watch: {
        getStoreData: {
            immediate: true,
            handler(storeData) {
                if (!storeData) return;
                console.log('storeData', storeData);
                
                this.getData(this.pn);
            }
        }
    },
    mounted() {
        // this.$axios({
        //     url: '/api/coupon/storeCouponList',
        //     method: 'post'
        // }).then(res => {
        //     if (res.data.list.length === 0) {
        //         return;
        //     }
        //     this.list = res.data.list;
        // });
    },
    methods: {
        openCoupon() {
            this.visibility = true;
        },
        okModal(data) {
            this.closeModal();

            this.$axios({
                url: '/api/coupon/addStore',
                method: 'post',
                data: Object.assign({}, {
                    storeId: this.getStoreData.id,
                    pn: this.pn,
                    rn: this.rn
                }, data)
            }).then(res => {
                if (res.data.code === 0) {
                    return;
                }

                this.list = res.data.list;
                
                this.$Message.success(res.data.message);
            }).catch(() => {});
        },
        closeModal() {
            this.visibility = false;
        },
        edit(index) {
            this.formItem = JSON.parse(JSON.stringify(this.list[index]));
            console.log('this.formItem', this.formItem);
            this.visibility = true;
        },
        remove(index) {
            this.$Modal.confirm({
                title: '提示',
                content: '确认要删除吗？',
                onOk: () => {
                    this.$axios({
                        url: '/api/coupon/deleteStoreSetup',
                        method: 'post',
                        data: {
                            id: this.list[index].id
                        }
                    }).then(res => {
                        this.list.splice(index, 1);
                        this.$Message.success(res.data.message);
                    }).catch(() => {});
                }
            });
        },
        onPageChange(pn) {
            this.pn = pn - 1;
            this.getData(this.pn);
        },
        getData(pn) {
            this.$axios({
                url: '/api/coupon/storeCouponList',
                method: 'post',
                data: {
                    storeId: this.getStoreData.id,
                    pn,
                    rn: this.rn
                }
            }).then(res => {
                const {list, total} = res.data;
                this.list = list;
                if (+this.total !== +total) {
                    this.total = total;
                }
            }).catch(() => {});
        }
    }
}
</script>

<style lang="less" scoped>
    .list-page {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }
    ::v-deep {
        .class-btn {
            margin: 0 5px;
        }
    }
</style>
