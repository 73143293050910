<template>
    <div class="add-container">
        <Form
            class="add-form"
            ref="formItem"
            :model="formItem"
            :rules="ruleValidate"
            :label-width="150"
            label-position="right"
        >
            <Form-item prop="name" label="商品名称">
                <Input v-model="formItem.name" placeholder="请输入商品名称" />
            </Form-item>

            <!-- <Form-item prop="goodsClass" label="商品分类">
                <Select
                    v-model="formItem.goodsClass"
                    placeholder="商品分类"
                    filterable
                    multiple
                >
                    <Option
                        v-for="item in goodsClass"
                        :value="item.name"
                        :key="item.id"
                    >{{ item.name }}</Option>
                </Select>
            </Form-item> -->

            <Form-item prop="goodsType" label="商品类型">
                <Select v-model="formItem.goodsType" class="width-150">
                    <Option value="2">限时抢购</Option>
                    <Option value="1">不限时抢购</Option>
                </Select>
            </Form-item>

            <Form-item prop="sort" label="排序">
                <Input v-model.number="formItem.sort" class="width-150" type="number" placeholder="请输入排序值" />
            </Form-item>

            <Form-item ref="formDate" prop="goodsDate" label="抢购时间">
                <DatePicker
                    :value="formItem.goodsDate"
                    :disabled="formItem.goodsType !== '2'"
                    class="goods-datepicker"
                    type="datetimerange"
                    format="yyyy-MM-dd hh:mm:ss"
                    split-panels
                    placement="bottom-end"
                    placeholder="请选择抢购商品时间范围"
                    @on-change="changeDate"
                />
            </Form-item>

            <Form-item prop="logo" label="商品图片">
                <CUpload
                    :default-list="formItem.logo"
                    :maxLength="5"
                    width="256"
                    height="192"
                    sizeTip
                    :ratio="[640, 480]"
                    @change="onChange"
                />
            </Form-item>

            <Form-item prop="goodsStock" label="商品库存">
                <Input
                    v-model.number="formItem.goodsStock"
                    class="width-150"
                    type="number"
                    placeholder="请输入商品库存"
                />
            </Form-item>

            <!-- <Form-item prop="salesVolume" label="商品销量">
                <Input
                    v-model.number="formItem.salesVolume"
                    class="width-150"
                    type="number"
                    placeholder="请输入商品销量"
                />
            </Form-item> -->

            <Form-item prop="price" label="商品价格">
                <div class="goods-box">
                    <div class="goods-box-label goods-box-label1">原价</div>
                    <Input
                        v-model.number="formItem.price"
                        class="goods-box-input"
                        type="number"
                        placeholder="请输入商品原价"
                    />
                    <div class="goods-box-label goods-box-label2">元</div>
                    <div class="goods-box-label goods-box-label3">现价</div>
                    <Input
                        v-model.number="formItem.memberPrice"
                        class="goods-box-input"
                        type="number"
                        placeholder="请输入商品现价"
                    />
                    <div class="goods-box-label goods-box-label4">元</div>
                </div>
            </Form-item>

            <Form-item prop="goodsStatus" label="商品状态">
                <RadioGroup v-model="formItem.goodsStatus">
                    <Radio class="add-radio" label="1">上架</Radio>
                    <Radio class="add-radio" label="0">下架</Radio>
                </RadioGroup>
            </Form-item>

            <Form-item prop="detail" label="商品详情">
                <WEditor @change="getValue" :value="formItem.detail"/>
            </Form-item>

            <Form-item>
                <Button
                    class="add-submit"
                    type="primary"
                    size="large"
                    @click="handleSubmit"
                >
                    提交
                </Button>
            </Form-item>
        </Form>
    </div>
</template>

<script>
import CUpload from '@/components/upload/index';
import WEditor from '@/components/editor/index';

export default {
    name: 'AddStoreGoods',
    components: {
        CUpload,
        WEditor
    },
    data() {
        const confirmClass = (rule, value, callback) => {
            if (value.length > 0) {
                callback();
            }
            else {
                callback(new Error('请选择商品分类'));
            }
        }

        const confirmType = (rule, value, callback) => {
            console.log('dsadsadsadasd');
            if (value === '1') {
                this.formItem.goodsDate = [];
                this.$refs['formItem'].validateField('goodsDate');
            }
           
            callback();
        }

        const confirmDate = (rule, value, callback) => {
            if (this.formItem.goodsType === '2' && value.length === 0) {
                callback(new Error('抢购商品需要选择活动日期范围'));
            }
            else {
                callback();
            }
        }

        const confirmLogo = (rule, value, callback) => {
            if (value.length > 0) {
                callback();
            }
            else {
                callback(new Error('商品logo不能为空'));
            }
        };

        const confirmGoodsStock = (rule, value, callback) => {
            if (value >= 0) {
                callback();
            }
            else {
                callback(new Error('请输入商品库存'));
            }
        };

        const confirmPrice = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请输入商品原价'));
            }
            else if (!this.formItem.memberPrice) {
                callback(new Error('请输入商品现价'));
            }
            else if (value < this.formItem.memberPrice) {
                callback(new Error('商品原价不能大于现价'));
            }
            else {
                callback();
            }
        };

        return {
            formItem: {
                storeId: this.$route.query.id,
                name: '',
                // goodsClass: [],
                goodsType: '2',
                sort: 0,
                logo: [],
                goodsStock: null,
                salesVolume: 0,
                price: null,
                memberPrice: null,
                goodsStatus: '1',
                detail: '',
                goodsDate: []
            },
            ruleValidate: {
                name: [
                    {required: true, message: '请输入商品名称', trigger: 'change'}
                ],
                // goodsClass: [
                //     {required: true, validator: confirmClass, trigger: 'change'}
                // ],
                goodsType: [
                    {required: true, validator: confirmType, trigger: 'change'}
                ],
                goodsDate: [
                    {required: false, validator: confirmDate, trigger: 'blur'}
                ],
                logo: [
                    {required: true, validator: confirmLogo, trigger: 'change'}
                ],
                goodsStock: [
                    {required: true, validator: confirmGoodsStock, trigger: 'change'}
                ],
                price: [
                    {required: true, validator: confirmPrice, trigger: 'change'}
                ]
            },
            // goodsClass: null
        }
    },
    computed: {
        getStoreData() {
            return this.$store.state.storeData;
        }
    },
    watch: {
        getStoreData: {
            immediate: true,
            async handler(storeData) {
                if (!storeData) return;
                console.log('storeData2', storeData);

                // 获取商品分类
                // const classList = await this.getClassList(storeData.type);
                // console.log('classList', classList);
                // this.goodsClass = classList.data.list;
            }
        }
    },
    async mounted() {
        const {goodsId} = this.$route.query;
        if (goodsId) {
            this.$axios({
                url: '/api/goods/getGoodsList',
                method: 'post',
                data: {
                    id: goodsId
                }
            }).then(res => {
                if (res.data.list.length === 0) {
                    return;
                }
                this.formItem = Object.assign({}, this.formItem, res.data.list[0]);
            }).catch(() => {});
        }
    },
    methods: {
        handleSubmit() {
            console.log('formItem', this.formItem);
            
            this.$refs['formItem'].validate(valid => {
                if (!valid) {
                    this.$Message.error('存在填写错误，请检查');
                    return;
                }
                console.log('this.formItem', this.formItem);
                // return;

                this.$axios({
                    url: '/api/goods/addGoods',
                    method: 'post',
                    data: this.formItem
                }).then(res => {
                    if (res.data.code === 0) {
                        return;
                    }

                    this.$router.push({
                        path: '/storeGoods/storeGoodsList',
                        query: {
                            id: this.$route.query.id,
                            isStore: 1
                        }
                    });

                    this.$Message.success(res.data.message);
                }).catch(() => {});
            })
        },
        changeDate(data) {
            this.formItem.goodsDate = data;
        },
        onChange(value) {
            this.formItem.logo = value;
        },

        // 获取编辑器内容
        getValue(data) {
            this.formItem.detail = data;
        },
        getClassList(navs) {
            return this.$axios({
                url: '/api/secNav/goods/list',
                method: 'post',
                data: {
                    navs
                }
            });
        },

        // 搜索商品分类
        // changeSelect(query) {
        //     if (this.iTimer) {
        //         clearTimeout(this.iTimer);
        //     }
        //     query = query.trim();
        //     console.log('query', query);
        //     this.iTimer = setTimeout(async () => {
        //         this.goodsClass = (await this.getClassList({
        //             name: query
        //         })).data.list;
        //     }, 500);
        // }
        // onCreate(query) {
        //     console.log('query', query);
        //     this.goodsClass.push({
        //         name: query,
        //         id: this.goodsClass.length
        //     });
        // }
    }
}
</script>

<style lang="less" scoped>
    .add-form {
        margin-top: 20px;

        .setup-radio {
            margin-right: 20px;
        }

        .add-submit {
            width: 150px;
        }
    }
    .goods-box:after {
        content: " ";
        display: block;
        clear: both;
    }
    .width-150 {
        width: 150px;
    }
    .width-300 {
        width: 300px;
    }
    .goods-box-label,
    .goods-box-input {
        float: left;
    }
    .goods-box-label {
        padding: 0 10px;
        min-width: 70px;
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        color: #333;
        text-align: center;
        border: 1px solid #dcdee2;
        background-color: #f2f2f2;
    }
    .goods-box-input {
        width: 130px;
    }
    .goods-box-label1,
    .goods-box-label2,
    .goods-box-label3 {
        border-right: none;
    }
    .goods-box-label2,
    .goods-box-label3,
    .goods-box-label4 {
        border-left: none;
    }
    .goods-box-label2 {
        text-align: left;
    }
    .goods-datepicker {
        max-width: 432px;
        width: 100%;
    }
    ::v-deep .goods-box-input .ivu-input {
        border-radius: 0 !important;
    }
</style>
