<template>
    <div class="add-container">
        <Form
            class="ad-form"
            ref="formItem"
            :model="formItem"
            :rules="ruleValidate"
            :label-width="150"
            label-position="right"
        >
            <Form-item v-if="formItem.number" prop="number" label="推荐员编号">
                <Input
                    v-model="formItem.number"
                    class="add-210"
                    disabled
                />
            </Form-item>

            <Form-item prop="vipId" label="推荐员">
                <Select
                    v-model="formItem.vipId"
                    class="add-210"
                    placeholder="选择推荐人"
                    filterable
                    clearable
                >
                    <Option
                        v-for="item in vipList"
                        :value="item.id"
                        :key="item.id"
                    >{{ item.nickname }}</Option>
                </Select>
            </Form-item>
            
            <Form-item>
                <Button
                    class="ad-submit"
                    type="primary"
                    size="large"
                    @click="handleSubmit"
                >
                    提交
                </Button>
            </Form-item>
        </Form>
    </div>
</template>

<script>
export default {
    name: 'AddTuijianyuan',
    components: {
    },
    data() {
        const confirmVipId = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请选择推荐员'));
            }
            else {
                callback();
            }
        };

        return {
            formItem: {
                vipId: ''
            },
            ruleValidate: {
                vipId: [
                    {required: true, validator: confirmVipId, trigger: 'change'}
                ]
            },
            vipList: []
        }
    },
    mounted() {
        const {id} = this.$route.query;
        if (id) {
            this.$axios({
                url: '/api/tuijianyuan/list',
                method: 'post',
                data: {
                    id
                }
            }).then(res => {
                if (res.data.code === 0 || res.data.list.length === 0) {
                    return;
                }
                this.formItem = res.data.list[0];

                this.getVipList(this.formItem.vipId);
            }).catch(() => {});
        }
        else {
            this.getVipList();
        }
    },
    methods: {
        handleSubmit() {
            this.$refs['formItem'].validate(valid => {
                if (!valid) {
                    this.$Message.error('存在填写错误，请检查');
                    return;
                }

                this.$axios({
                    url: '/api/tuijianyuan/add',
                    method: 'post',
                    data: this.formItem
                }).then(res => {
                    if (res.data.code === 0) {
                        return;
                    }

                    this.$router.push({
                        name: 'TuijianyuanList',
                        params: {
                            pn: this.$route.query.pn
                        }
                    });

                    this.$Message.success(res.data.message);
                }).catch(() => {});
            })
        },
        onChange(value) {
            this.formItem.logo = value;
        },
        getVipList(id) {
            this.$axios({
                url: '/api/tuijianyuan/vipList',
                method: 'post',
                data: {
                    id
                }
            }).then(res => {
                this.vipList = res.data.list;
            }).catch(() => {});
        },
    }
}
</script>

<style lang="less" scoped>
    .ad-form {
        margin-top: 20px;

        .ad-submit {
            width: 150px;
        }
    }
    .add-210 {
        width: 210px;
    }
</style>
