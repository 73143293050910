<template>
    <div class="add-container">
        <Form
            class="ad-form"
            ref="formItem"
            :model="formItem"
            :rules="ruleValidate"
            :label-width="150"
            label-position="right"
        >
            <Form-item prop="type" label="广告类型">
                <Select
                    v-model="formItem.type"
                    class="width-210"
                >
                    <Option value="多店幻灯片" key="banner">多店幻灯片</Option>
                </Select>
            </Form-item>
            <Form-item prop="title" label="标题">
                <Input v-model="formItem.title" placeholder="请输入广告标题" />
            </Form-item>
            <Form-item prop="logo" label="图片">
                <CUpload
                    :default-list="formItem.logo"
                    :maxLength="1"
                    :width="300"
                    :height="120"
                    sizeTip
                    :ratio="[750, 300]"
                    @change="onChange"
                />
            </Form-item>
            <Form-item prop="href" label="跳转链接">
                <Input v-model="formItem.href" placeholder="请输入跳转链接" />
                <ul class="tips">
                    <li>个人中心: /pages/my/my</li>
                    <li>合作加盟: /pages/index/hezuo</li>
                    <li>店铺: /pages/home/index?id=店铺id</li>
                </ul>
            </Form-item>
            <Form-item prop="sort" label="排序">
                <Input
                    v-model.number="formItem.sort"
                    class="width-150"
                    type="number"
                    placeholder="请输入排序值"
                />
            </Form-item>
            <Form-item prop="showAd" label="是否禁用">
                <RadioGroup v-model="formItem.showAd">
                    <Radio class="ad-radio" label="1">启用</Radio>
                    <Radio class="ad-radio" label="0">禁用</Radio>
                </RadioGroup>
            </Form-item>
            
            <Form-item>
                <Button
                    class="ad-submit"
                    type="primary"
                    size="large"
                    @click="handleSubmit"
                >
                    提交
                </Button>
            </Form-item>
        </Form>
    </div>
</template>

<script>
import CUpload from '@/components/upload/index';

export default {
    name: 'AddStore',
    components: {
        CUpload
    },
    data() {
        const confirmLogo = (rule, value, callback) => {
            if (this.formItem.logo.length > 0) {
                callback();
            }
            else {
                callback(new Error('图片不能为空'));
            }
        };

        return {
            formItem: {
                type: '',
                title: '',
                logo: [],
                href: '',
                sort: 0,
                showAd: '1'
            },
            ruleValidate: {
                type: [
                    {required: true, message: '广告类型不能为空', trigger: 'change'}
                ],
                title: [
                    {required: true, message: '标题不能为空', trigger: 'change'}
                ],
                logo: [
                    {required: true, validator: confirmLogo, trigger: 'blur'}
                ],
                href: [
                    {required: true, message: '跳转链接不能为空', trigger: 'change'}
                ]
            },
            cacheData: null,
            typeList: null,
            showStore: [
                {
                    name: '是',
                    key: 'store0'
                },
                {
                    name: '否',
                    key: 'store1'
                }
            ],
            bindShop: null
        }
    },
    mounted() {
        const {id} = this.$route.query;
        if (id) {
            this.$axios({
                url: '/api/ad/list',
                method: 'post',
                data: {
                    id
                }
            }).then(res => {
                if (res.data.code === 0 || res.data.list.length === 0) {
                    return;
                }
                this.formItem = res.data.list[0];
            }).catch(() => {});
        }
    },
    methods: {
        handleSubmit() {
            this.$refs['formItem'].validate(valid => {
                if (!valid) {
                    this.$Message.error('存在填写错误，请检查');
                    return;
                }

                this.$axios({
                    url: '/api/ad/add',
                    method: 'post',
                    data: this.formItem
                }).then(res => {
                    if (res.data.code === 0) {
                        return;
                    }

                    this.$router.push({
                        name: 'AdList',
                        params: {
                            pn: this.$route.query.pn
                        }
                    });

                    this.$Message.success(res.data.message);
                }).catch(() => {});
            })
        },
        onChange(value) {
            this.formItem.logo = value;
        }
    }
}
</script>

<style lang="less" scoped>
    .ad-form {
        margin-top: 20px;

        .ad-radio {
            margin-right: 20px;
        }

        .ad-submit {
            width: 150px;
        }
    }
    .ad-100 {
        width: 100px;
    }
    .width-150 {
        width: 150px;
    }
    .width-210 {
        width: 210px;
    }
    .tips {
        color: #999;
    }
</style>
