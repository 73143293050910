<template>
    <div class="upload">
        <div class="upload-box">
            <template v-if="uploadList.length > 0">
                <div
                    class="upload-list"
                    :style="{
                        width: `${width}px`,
                        height: `${height}px`,
                        lineHeight: `${height}px`
                    }"
                    v-for="(item, index) in uploadList"
                    :key="'uploadList' + index"
                >
                    <template v-if="item.status === 'finished'">
                        <img class="upload-list-img" :src="item.url">
                        <div class="upload-list-cover">
                            <Icon type="ios-trash-outline" size="24" @click.native="handleRemove(item, index)"></Icon>
                        </div>
                    </template>
                    <template v-else>
                        <Progress v-if="item.showProgress" :percent="item.percentage" hide-info/>
                    </template>
                </div>
            </template>
            <Upload
                v-show="uploadList.length < +maxLength"
                ref="upload"
                class="upload-wrap"
                :style="{
                    width: `${width}px`,
                    height: `${height - 2}px`,
                    lineHeight: `${height - 2}px`
                }"
                :show-upload-list="false"
                :default-file-list="list"
                :on-success="handleSuccess"
                :accept="accept"
                :max-size="size"
                :on-format-error="handleFormatError"
                :on-exceeded-size="handleMaxSize"
                multiple
                type="drag"
                :action="uploadUrl"
            >
                <div class="upload-wrap-info">
                    <Icon type="ios-camera" size="28"></Icon>
                </div>
            </Upload>
        </div>
        
        <div v-if="sizeTip || ratio.length > 0" class="flexbox upload-tips">
            <div class="flexbox-fixed">注意：</div>
            <div class="flexbox-auto">
                <p v-if="sizeTip" class="upload-wrap-info-p">图片大小不能超过{{ newSize }}</p>
                <p v-if="ratio.length > 0" class="upload-wrap-info-p">图片尺寸比例要求在{{ ratio[0] }}<span>*</span>{{ ratio[1] }}</p>
            </div>
            <!-- <p v-if="showTips === 'size'" class="upload-wrap-info-p">注意：图片大小不能超过{{ newSize }}</p>
            <p v-else class="upload-wrap-info-p">注意：图片尺寸比例要求在{{ width }}*{{ height }}</p> -->
        </div>
    </div>
</template>

<script>
const _ = require('lodash');

export default {
    name: 'upload',
    props: {
        defaultList: {
            type: Array,
            default: () => {
                return []
            }
        },
        maxLength: {
            type: [String, Number],
            default: 5
        },
        size: {
            type: [String, Number],
            default: 40960
        },
        width: {
            type: [String, Number],
            default: 80
        },
        height: {
            type: [String, Number],
            default: 80
        },
        showTips: {
            type: [String, Number],
            default: ''
        },
        sizeTip: {
            type: Boolean,
            default: false
        },
        ratio: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data () {
        return {
            uploadUrl: `/api/upload`,
            list: this.defaultList,
            uploadList: [],

            /**
             * 图片文件：image/*
             * 视频文件：video/*
             * 音频文件：audio/*
             * 文本文件：text/*
             * PDF文件：application/pdf
             * Word文档：application/msword
             * Excel表格：application/vnd.ms-excel
             * PowerPoint演示文稿：application/vnd.ms-powerpoint
             * 压缩文件：application/zip
             * 所有文件类型：8/8 使用时*代替数字8
             */
            accept: 'image/*'
        }
    },
    computed: {
        newSize() {
            let val = '';
            if (this.size > 1024) {
                val = `${_.toString(_.round(this.size / 1024, 2))}MB`;
            }
            else {
                val = `${this.size}KB`;
            }
            return val;
        }
    },
    watch: {
        defaultList(value) {
            if (value.length === 0) {
                this.$refs.upload.clearFiles();
            }
            else {
                this.$refs.upload.fileList = value;
            }
            
            this.$nextTick(() => {
                this.uploadList = this.$refs.upload.fileList;
            });
        }
    },
    mounted() {
        this.uploadList = this.$refs.upload.fileList;
    },
    methods: {
        handleRemove (file) {
            const fileList = this.$refs.upload.fileList;
            fileList.splice(fileList.indexOf(file), 1);

            this.$emit('change', this.uploadList);
        },
        handleSuccess (res, file) {
            file.url = res.url;
            file.name = res.name;
            
            this.$emit('change', this.uploadList);
        },
        handleFormatError () {
            // this.$Notice.warning({
            //     title: '文件格式不正确',
            //     desc: '文件 ' + file.name + ' 格式不正确，请上传 jpg 或 png 格式的图片。'
            // });
            this.$Message.error({
                content: `图片格式不正确，请上传jpg或png格式的图片。`,
                duration: 3
            });
        },
        handleMaxSize () {
            // this.$Notice.warning({
            //     title: '超出文件大小限制',
            //     desc: `文件 ' + file.name + ' 太大，不能超过 ${this.size}。`
            // });
            this.$Message.error({
                content: `上传文件太大，不能超过${this.newSize}。`,
                duration: 3
            });
        }
    }
}
</script>

<style scoped lang="less">
    .upload-box::after {
        content: "";
        display: table;
        clear: both;
    }
    .upload-list{
        float: left;
        width: 100px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        position: relative;
        margin-right: 4px;
    }
    .upload-list img{
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }
    .upload-list-cover{
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        background: rgba(0, 0, 0, .6);
    }
    .upload-list:hover .upload-list-cover{
        display: block;
    }
    .upload-list-cover i{
        color: #fff;
        font-size: 24px;
        cursor: pointer;
    }
    .upload-wrap {
        float: left;
        width:98px;
        line-height: 98px;

        .upload-wrap-info {
            width: 100%;
            height: 100%;
        }
    }
    
    .upload-tips {
        color: #999;
        line-height: 24px;
        margin-top: 5px;
    }
    .upload-wrap-info-p {
        height: 24px;
    }
    .upload-wrap-info-p span {
        position: relative;
        top: 3px;
        font-weight: bold;
        margin: 0 3px;
    }
</style>
