<template>
    <div v-if="subNavData && subNavData.children && subNavData.children.length > 0" class="sub-container">
        <ul
            class="subnav"
            :style="{
                left: `${navWidth}px`,
                width: `${subNavWidth}px`,
                transform: `translateX(${isMobile && !openWiseNav ? '-' + navWidth + subNavWidth + 'px' : '0'})`
            }"
        >
            <template v-for="(item, index) in subNavData.children">
                <li
                    v-show="item.meta.isShow"
                    :key="item.name + index"
                    :style="{
                        width: `${subNavWidth}px`
                    }"
                >
                    <router-link
                        :to="{
                            path: item.path,
                            query: item.meta.query ? item.meta.query : {}
                        }"
                        @click.native="closeNav"
                    >
                        {{ item.meta.name }}
                        <Icon class="subnav-icon" type="ios-arrow-forward" size="14" />
                    </router-link>
                </li>
            </template>
        </ul>
        <div v-if="isMobile && openWiseNav" class="nav-bg" @click="closeNav"></div>

        <div
            class="sub-content"
            :style="{
                marginLeft: `${isMobile ? 15 : subNavWidth + 15}px`
            }"
        >
            <div class="bread">
                <Breadcrumb
                    v-if="bread"
                    class="bread-box"
                    separator=">"
                >
                    <BreadcrumbItem>{{ bread.name1 }}</BreadcrumbItem>
                    <BreadcrumbItem>{{ bread.name2 }}</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <router-view/>
        </div>
    </div>
</template>

<script>
import {getSubNav, getBread} from '@/utils/utils';

export default {
    props: {
        navName: {
            type: String,
            default: ''
        },
        subName: {
            type: String,
            default: ''
        }
    },
    computed: {
        navWidth() {
            return this.$store.state.navWidth;
        },
        subNavWidth() {
            return this.$store.state.subNavWidth;
        },
        isMobile() {
            return this.$store.state.isMobile;
        },
        openWiseNav() {
            return this.$store.state.openWiseNav;
        }
    },
    watch: {
        '$route': {
            handler(router) {
                if (router.meta && router.meta.name) {
                    this.bread = Object.assign({}, this.bread, {
                        name2: router.meta.name
                    });
                }
            }
        },
        subName: {
            immediate: true,
            handler(value) {
                if (!value) return;

                // 获取面包屑数据 {}
                this.bread = getBread.call(this, this.navName, value);
            }
        }
    },
    data() {
        return {
            bread: null,
            subNavData: {}
        }
    },
    mounted() {

        // 获取二级路由数据 {}
        this.subNavData = getSubNav.call(this, this.navName);
    },
    methods: {
        closeNav() {
            if (this.isMobile) {
                this.$store.commit('enableScroll');
                this.$store.commit('openWiseNavFn', false);
            }
        }
    }
}
</script>

<style scoped lang="less">
    @media (max-width: 767px) {
        .nav-bg {
            position: fixed;
            left: 0;
            top: 0;
            z-index: 8;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .2);
        }
    }
</style>
