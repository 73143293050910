<template>
    <div class="login">
        <Form
            class="login-form"
            ref="formItem"
            :model="formItem"
            :rules="ruleValidate"
            :label-width="100"
            label-position="right"
            autocomplete="off"
        >
            <h1 class="login-title">中台项目管理</h1>
            <Form-item prop="name" label="账号">
                <Input
                    class="name"
                    v-model="formItem.name"
                    placeholder="请输入账号"
                />
            </Form-item>
            <Form-item prop="password" label="密码">
                <Input
                    class="password"
                    type="password"
                    v-model="formItem.password"
                    placeholder="请输入密码"
                />
            </Form-item>
            <Form-item>
                <Button
                    class="login-btn"
                    type="primary"
                    @click="handleSubmit"
                >
                    登录
                </Button>
                <Button
                    v-if="query.register && query.register === '1'"
                    class="login-btn"
                    @click="register"
                >
                    去注册
                </Button>
            </Form-item>
        </Form>
    </div>
</template>

<script>
export default {
    name: 'login',
    data () {
        return {
            formItem: {
                name: '',
                password: ''
            },
            ruleValidate: {
                name: [
                    {required: true, message: '请输入账号', trigger: 'blur'}
                ],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'}
                ]
            },
            query: {}
        }
    },
    mounted() {
        this.query = this.$route.query;
    },
    methods: {
        handleSubmit() {
            this.$refs['formItem'].validate(valid => {
                if (!valid) {
                    this.$Message.error('存在填写错误，请检查');
                    return;
                }

                // const {name, password} = this.formItem;
                this.$axios({
                    url: '/api/login',
                    method: 'post',
                    // data: {
                    //     type: 'login',
                    //     name,
                    //     password: this.$md5(password)
                    // },
                    data: Object.assign({}, this.formItem, {
                        type: 'login'
                    })
                }).then(res => {
                    if (res.data.code === 0) {
                        return;
                    }
                    
                    this.$Message.success(`账号 <b>${name}</b> ${res.data.message}`);

                    // 保存账号信息和token
                    this.$store.commit('updataAccount', res.data.list[0]);
                    this.$store.commit('updataToken', res.headers['token']);

                    if (this.query.redirect) {
                        this.$router.push({
                            path: this.query.redirect
                        });
                    }
                    else {
                        this.$router.push({ path: '/' });
                    }
                }).catch(() => {});
            })
        },
        register() {
            this.$router.push({
                path: '/adminRegister'
            });
        }
    }
}
</script>

<style scoped lang="less">
.login {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url('../../assets/bg.jpg') no-repeat 0 0;
    background-size: 100% 100%;

    .login-title {
        text-align: center;
        font-size: 20px;
        padding-bottom: 10px;
    }

    .login-form {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 350px;
        max-height: 80%;
        overflow: hidden;
        overflow-y: auto;
        transform: translate(-50%, -80%);
        border-radius: 10px;
        padding-top: 20px;
        background-color: #fff;
    }

    .login-btn {
        margin-right: 10px;
    }
}
</style>
<style lang="less">
.login-form .ivu-form-item {
    margin-right: 50px;
}
</style>
