<template>
    <div class="list-container">
        <Button
            class="add-btn"
            type="primary"
            @click="handleModal"
        >
            添加广告
        </Button>
        <Table border :context="self" :columns="columns" :data="list"/>
        <div v-if="list && list.length > 0 && total > rn" class="list-page">
            <Page
                :current="pn + 1"
                :total="total"
                :page-size="rn"
                show-total
                @on-change="onPageChange"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'TypeList',
    components: {
    },
    data() {
        return {
            self: this,
            columns: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '排序',
                    key: 'sort',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '分类',
                    key: 'type',
                    width: 120,
                    align: 'center'
                },
                {
                    title: '标题',
                    key: 'title',
                    align: 'center',
                    minWidth: 250
                },
                {
                    title: '图标',
                    key: 'src',
                    width: 350,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'list-photo'
                        }, [
                            params.row.src ? h('img', {
                                class: 'list-photo-img',
                                attrs: {
                                    src: params.row.src
                                }
                            }) : ''
                        ]);
                    }
                },
                {
                    title: '链接地址',
                    key: 'href',
                    align: 'center',
                    minWidth: 320
                },
                {
                    title: '创建时间',
                    key: 'dateTime',
                    align: 'center',
                    width: 200
                },
                {
                    title: '状态',
                    key: 'showAd',
                    align: 'center',
                    width: 110,
                    fixed: 'right',
                    render: (h, params) => {
                        return h('Button', {
                            class: `${params.row.showAd === '1' ? 'ivu-btn-primary' : 'ivu-btn-error'} ivu-btn-small class-btn`,
                            on: {
                                click: () => {
                                    this.adStatus(params.row, params.index);
                                }
                            }
                        }, `${params.row.showAd === '1' ? '已上架' : '已下架'}`)
                    }
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 150,
                    align: 'center',
                    fixed: 'right',
                    render: (h, params) => {
                        return h('div', {}, [
                            h('Button', {
                                class: 'ivu-btn-primary ivu-btn-small class-btn',
                                on: {
                                    click: () => {
                                        this.edit(params.row.id);
                                    }
                                }
                            }, '编辑'),
                            h('Button', {
                                class: 'ivu-btn-error ivu-btn-small class-btn',
                                on: {
                                    click: () => {
                                        this.remove(params.index);
                                    }
                                }
                            }, '删除')
                        ]);
                    }
                }
            ],
            list: [],
            total: 0,
            pn: 0,
            rn: 10
        }
    },
    mounted() {
        const {pn} = this.$route.params;
        if (pn) {
            this.pn = +pn;
        }
        this.getData(this.pn);
    },
    methods: {
        // 编辑当前分类
        edit(id) {
            this.$router.push({
                path: '/ad/addAd',
                query: {
                    id,
                    pn: this.pn
                }
            });
        },

        // 删除当前广告
        remove(index) {
            this.$Modal.confirm({
                title: '提示',
                content: '确认要删除吗？',
                onOk: () => {
                    this.$axios({
                        url: '/api/ad/delete',
                        method: 'post',
                        data: {
                            id: this.list[index].id
                        }
                    }).then(res => {
                        const {total, message} = res.data;
                        if (this.pn > 1) {
                            this.pn = Math.ceil(total / this.rn) > this.pn ? this.pn : Math.ceil(total / this.rn);
                        }
                        this.getData(this.pn);
                        this.$Message.success(message);
                    }).catch(() => {});
                }
            });
        },
        adStatus(data, index) {
            let tips = '确定要下架当前广告吗';
            let showAd = '0';
            if (data.showAd === '0') {
                tips = '确定要上架当前广告吗';
                showAd = '1';
            }
            this.$Modal.confirm({
                title: '提示',
                content: tips,
                onOk: () => {
                    this.$axios({
                        url: '/api/ad/updata',
                        method: 'post',
                        data: {
                            id: data.id,
                            showAd
                        }
                    }).then(res => {
                        this.list[index].showAd = showAd;
                        this.$Message.success(res.data.message);
                    }).catch(() => {});
                }
            });
        },
        onPageChange(pn) {
            this.pn = pn - 1;
            this.getData(this.pn);
        },
        getData(pn) {
            this.$axios({
                url: '/api/ad/list',
                method: 'post',
                data: {
                    pn,
                    rn: this.rn
                }
            }).then(res => {
                const {list, total} = res.data;
                if (list.length === 0) {
                    return;
                }
                this.list = list;
                if (!this.total) {
                    this.total = total;
                    console.log('this.total', this.total);
                }
            }).catch(() => {});
        },
        handleModal() {
            this.$router.push({
                path: '/ad/addAd'
            });
        }
    }
}
</script>

<style lang="less" scoped>
    .list-page {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }
    ::v-deep {
        .list-photo {
            position: relative;
            display: inline-block;
            width: 300px;
            height: 120px;
            overflow: hidden;
            margin: 10px 0 4px;
            box-sizing: initial;
            border-radius: 5px;

            &-img {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                height: 100%;
                border-radius: 5px;
            }
        }
        .class-btn {
            margin: 0 5px;
        }
    }
</style>
