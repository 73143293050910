<template>
    <div class="list-container">
        <!-- default-time="0" -->
        <Filters
            showTime
            @search="searchFun"
        />

        <Divider :top="0" />

        <div class="finance-info">
            <div class="finance-title">资金状态</div>

            <Table
                border
                stripe
                :context="self"
                :columns="financeInfoColumns"
                :data="financeInfoList"
            />
        </div>

        <Divider />

        <div class="finance-history">
            <Tabs v-model="tabIndex">
                <TabPane label="已提现记录" name="0">
                    <Table
                        border
                        stripe
                        :row-class-name="rowClassName"
                        :context="self"
                        :columns="columns"
                        :data="list"
                    />

                    <div v-if="list && list.length > 0 && total > rn" class="list-page">
                        <Page
                            :current="pn + 1"
                            :total="total"
                            :page-size="rn"
                            show-total
                            @on-change="onPageChange"
                        />
                    </div>
                </TabPane>
                <TabPane label="未提现订单" name="1">
                    <Table
                        border
                        stripe
                        :context="self"
                        :columns="columns2"
                        :data="list2"
                    />

                    <div v-if="list2 && list2.length > 0 && total2 > rn" class="list-page">
                        <Page
                            :current="pn2 + 1"
                            :total="total2"
                            :page-size="rn"
                            show-total
                            @on-change="onPageChange2"
                        />
                    </div>
                </TabPane>
            </Tabs>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import Divider from '@/components/divider/index';
import Filters from '../components/filters';

export default {
    name: 'Finance',
    components: {
        Divider,
        Filters
    },
    data() {
        return {
            self: this,
            financeInfoColumns: [
                {
                    title: '可提现金额',
                    key: 'balance',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {
                            class: 'finance-balance'
                        }, params.row.balance + '元');
                    }
                },
                {
                    title: '已提现',
                    key: 'cashoutEd',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {}, params.row.cashoutEd + '元');
                    }
                }
            ],
            financeInfoList: [],
            searchItem: {
                // time: [moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss')],
                time: []
            },
            columns: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '店铺名称',
                    key: 'storeName',
                    align: 'center',
                    minWidth: 250
                },
                {
                    title: '店长',
                    key: 'name',
                    align: 'center',
                    minWidth: 200
                },
                {
                    title: '提现金额',
                    key: 'total',
                    minWidth: 120,
                    align: 'center',
                    render: (h, params) => {
                        const {total, status} = params.row;
                        return h('div', {
                            class: `${status === '2' ? 'table-total' : ''}`
                        }, `${total}元`);
                    }
                },
                {
                    title: '批次单号',
                    key: 'out_batch_no',
                    align: 'center',
                    minWidth: 220
                },
                {
                    title: '明细单号',
                    key: 'out_detail_no',
                    align: 'center',
                    minWidth: 220
                },
                {
                    title: '提现时间',
                    key: 'dateTime',
                    minWidth: 190,
                    align: 'center'
                },
                {
                    title: '状态',
                    key: 'status',
                    align: 'center',
                    fixed: 'right',
                    width: 130,
                    render: (h, params) => {
                        const {status, message} = params.row;
                        return h('div', {}, [
                            h('Tag', {
                                props: {
                                    color: `${status === '1' ? 'warning' : (status === '2' ? 'success' : 'error')}`
                                }
                            }, `${status === '1' ? '申请中' : (status === '2' ? '已提现' : message)}`),
                        ]);
                    }
                }
            ],
            list: [],
            pn: 0,
            rn: 10,
            total: 0,

            columns2: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '订单号',
                    key: 'out_trade_no',
                    minWidth: 200,
                    align: 'center'
                },
                {
                    title: '用户头像',
                    key: 'src',
                    width: 100,
                    align: 'center',
                    render: (h, params) => {
                        const {src} = params.row.userInfo;
                        return h('div', {
                            class: 'list-photo'
                        }, [
                            src ? h('img', {
                                class: 'list-photo-img',
                                attrs: {
                                    src: src
                                }
                            }) : ''
                        ]);
                    }
                },
                {
                    title: '用户昵称',
                    key: 'nickname',
                    minWidth: 150,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {}, params.row.userInfo.nickname);
                    }
                },
                // {
                //     title: '所属团长',
                //     key: 'zhangOpenid',
                //     minWidth: 200,
                //     align: 'center',
                //     render: (h, params) => {
                //         return h('span', {}, params.row.zhangOpenid || '无');
                //     }
                // },
                {
                    title: '所属团长',
                    key: 'zhangOpenid',
                    minWidth: 200,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {}, params.row.tzName || '无');
                    },
                    render: (h, params) => {
                        const {tzId, tzName} = params.row;
                        return h('span', {
                            class: `${tzName ? 'link' : ''}`,
                            on: {
                                click: () => {
                                    this.pushPage(tzId);
                                }
                            }
                        }, tzName || '无');
                    }
                },
                {
                    title: '店铺名称',
                    key: 'storeName',
                    minWidth: 250,
                    align: 'center'
                },
                {
                    title: '余额支付(元)',
                    key: 'youhui',
                    minWidth: 120,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {
                            class: 'table-total'
                        }, params.row.yue);
                    }
                },
                {
                    title: '实际支付(元)',
                    key: 'total',
                    minWidth: 120,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {
                            class: 'table-total'
                        }, params.row.total);
                    }
                },
                {
                    title: '订单金额(元)',
                    key: 'shouldPay',
                    minWidth: 120,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {
                            class: 'table-total'
                        }, params.row.shouldPay);
                    }
                },
                {
                    title: '平台折扣金额(元)',
                    key: 'discountAmount',
                    minWidth: 150,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {
                            class: 'table-total'
                        }, params.row.discountAmount);
                    }
                },
                {
                    title: '优惠卷(元)',
                    key: 'youhui',
                    minWidth: 120,
                    align: 'center',
                    // render: (h, params) => {
                    //     return h('span', {
                    //         class: 'table-total'
                    //     }, `${params.row.youhui}(店铺满减)`); // params.row.manjian ? params.row.youhui : ''
                    // },
                    render: (h, params) => {
                        const {manjian, youhui, isStoreCoupon} = params.row;
                        return h('div', {
                            class: 'table-total'
                        }, [
                            h('div', {}, youhui || '无'),
                            youhui ? h('div', {}, `${isStoreCoupon ? '店铺' : '通用'}${manjian ? '满减' : '无限制'}`) : null
                        ]);
                    }
                },
                {
                    title: '订单类型',
                    key: 'qianggou',
                    minWidth: 120,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {
                            class: `${params.row.qianggou ? 'table-total' : ''}`
                        }, params.row.qianggou ? '抢购订单' : '普通订单');
                    }
                },
                {
                    title: '下单时间',
                    key: 'dateTime',
                    minWidth: 190,
                    align: 'center'
                },
                {
                    title: '订单状态',
                    key: 'status',
                    align: 'center',
                    width: 110,
                    fixed: 'right',
                    render: (h, params) => {
                        const {qianggou, status} = params.row;
                        return h('Tag', {
                            props: {
                                color: `${status === '1' ? 'primary' : (status === '2' ? 'success' : 'error')}`
                            }
                        }, `${qianggou ? (status === '0' ? '取消支付' : (status === '1' ? '待核销' : (status === '2' ? '已核销' : '已退款'))) : (status === '0' ? '取消支付' : (status === '1' ? '待支付' : (status === '2' ? '已支付' : '已退款')))}`)
                    }
                }
            ],
            list2: [],
            pn2: 0,
            total2: 0,
            tabIndex: '0'
        }
    },
    computed: {
        getStoreData() {
            return this.$store.state.storeData;
        }
    },
    watch: {
        getStoreData: {
            immediate: true,
            handler(storeData) {
                console.log('storeData', storeData);
                if (storeData.id) {
                    this.searchItem.storeId = storeData.id;
                }

                this.getFinanceStatus();

                // 已提现记录
                this.getData(this.pn);

                // 未提现记录
                this.getData2(this.pn2);
            }
        }
    },
    mounted() {
    },
    methods: {
        getTime(value) {
            this.searchItem.time = value;
        },
        searchFun(options) {
            console.log('搜索', this.searchItem);
            this.searchItem = options;
            this.getFinanceStatus();

            this.pn = 0;
            this.getData(this.pn);
            this.pn2 = 0;
            this.getData2(this.pn2);
        },
        onPageChange(pn) {
            this.pn = pn - 1;
            this.getData(this.pn);
        },
        onPageChange2(pn) {
            this.pn2 = pn - 1;
            this.getData2(this.pn2);
        },

        // 获取提现记录
        getData(pn) {
            console.log('this.searchItem', this.searchItem);

            this.$axios({
                url: '/api/capital/finance/cashoutList',
                method: 'post',
                data: Object.assign({}, this.searchItem, {
                    pn,
                    rn: this.rn
                })
            }).then(res => {
                const {list, total} = res.data;
                this.list = list;
                console.log('this.list', this.list);
                if (+this.total !== +total) {
                    this.total = total;
                }
            }).catch(() => {});
        },
        getData2(pn) {
            console.log('this.searchItem', this.searchItem);

            this.$axios({
                url: '/api/capital/finance/notCashoutList',
                method: 'post',
                data: Object.assign({}, this.searchItem, {
                    pn,
                    rn: this.rn
                })
            }).then(res => {
                const {list, total} = res.data;
                this.list2 = list;
                console.log('this.list', this.list2);
                if (+this.total2 !== +total) {
                    this.total2 = total;
                }
            }).catch(() => {});
        },

        // 获取顶部统计数据
        getFinanceStatus() {
            this.$axios({
                url: '/api/capital/finance/financeStatus',
                method: 'post',
                data: this.searchItem
            }).then(res => {
                this.financeInfoList = res.data.list;
            }).catch(() => {});
        },
        rowClassName(row, index) {
            if (row.status === '3') {
                return 'table-red';
            }
            return '';
        },
        pushPage(id) {
            if (!id) {
                return;
            }
            this.$router.push({
                path: `/tuan/tuanDetail?id=${id}`
            });
        }
    }
}
</script>

<style lang="less" scoped>
    .finance-title {
        font-size: 18px;
        font-weight: bold;
        line-height: 1;
        padding-bottom: 20px;
    }

    .search-form {
        .finance-history-search-date {
            float: left;
        }
        .finance-history-search-datepicker {
            width: 360px;
        }
        .finance-history-search-btn {
            border-radius: 0 4px 4px 0;
        }

        .search-coupon {
            width: 120px;
        }
    }
    .finance-info {
        margin-top: 20px;
    }
    
    .finance-history {
        margin-top: 20px;

        .finance-history-list {
            // margin-top: 20px;
        }
        .list-page {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 30px 0;
        }
    }

    .add-form {
        margin-right: 20px;

        .add-submit {
            width: 100px;
        }
    }
    .cancel {
        margin-left: 20px;
    }
    
    ::v-deep {
        .finance-balance {
            color: #f65b1a;
            font-size: 25px;
            font-weight: bold;
            padding: 10px;
        }
        .finance-history-search-datepicker .ivu-input {
            border-radius: 4px 0 0 4px;
        }
        .table-total {
            color: #f65b1a;
            font-weight: bold;
        }
        .table-bold {
            font-weight: bold;
        }
        .table-right5 {
            margin-right: 5px;
        }
        .ivu-table .table-red td {
            background-color: rgba(255, 0, 0, 1) !important;
            color: #fff !important;
        }

        .list-photo {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 40px;
            overflow: hidden;
            margin: 10px 0 4px;
            box-sizing: initial;
            border-radius: 5px;

            &-img {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                height: auto;
                border-radius: 5px;
            }
        }
        .table-total {
            color: #f65b1a;
            font-weight: bold;
        }
        .link {
            color: #19be6b;
            font-weight: bold;
            cursor: pointer;
        }
    }
</style>
