<template>
    <div class="filter">

        <Form
            class="search-form"
            ref="searchItem"
            inline
            :model="searchItem"
            @keydown.native.enter.prevent
        >
            <div class="search-box">
                <Form-item v-if="showTime">

                    <!-- 昨天、今天、7天内检索条件 -->
                    <div class="filter-foo-time">
                        <Button
                            v-for="item in filterTime"
                            :key="item.text"
                            class="filter-status-btn"
                            type="primary"
                            :ghost="JSON.stringify(searchItem.time) === JSON.stringify(item.value) ? false : true"
                            @click="getTime(item.value)"
                        >
                            {{ item.text }}
                        </Button>
                    </div>

                    <!-- 按日期检索 -->
                    <div class="filter-foo-date">
                        <DatePicker
                            :value="searchItem.time"
                            class="filter-foo-datepicker"
                            type="datetimerange"
                            confirm
                            split-panels
                            placement="bottom-end"
                            placeholder="默认不限制"
                            @on-change="getTime"
                            @on-ok="searchFun"
                            @on-clear="celarTime"
                        />
                    </div>

                    <Button
                        class="filter-foo-btn"
                        type="primary"
                        @click="searchFun"
                    >
                        查找
                    </Button>
                </Form-item>
            </div>
            
            <div v-if="showInput" class="search-box">
                <Form-item>
                    <Input
                        v-model="searchItem.query"
                        class="flexbox-fixed search-input"
                        clearable
                        search
                        enter-button="搜索"
                        :placeholder="placeholder"
                        @on-search="searchFun"
                        @on-clear="searchFun"
                    />
                </Form-item>
            </div>
        </Form>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    props: {
        type: {
            type: String,
            default: 'store'
        },
        showTime: {
            type: Boolean,
            default: false
        },
        showInput: {
            type: Boolean,
            default: false
        },
        defaultTime: {
            type: [String, Number],
            default: ''
        },
        placeholder: {
            type: String,
            default: '请输入店铺名称'
        }
    },
    components: {
    },
    data() {
        return {
            searchItem: {
                time: [],
                query: ''
            },
            filterTime: [
                {
                    value: [moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss')],
                    text: '昨天'
                },
                {
                    value: [moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')],
                    text: '今天'
                },
                {
                    value: [moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')],
                    text: '近七天'
                }
            ]
        }
    },
    computed: {
    },
    watch: {
        defaultTime: {
            immediate: true,
            handler(value) {
                if (!value) return;

                this.searchItem.time = this.filterTime[value].value;
            }
        }
    },
    mounted() {
    },
    methods: {
        searchFun() {
            // let params = {
            //     time: this.searchItem.time
            // };
            // if (this.type === 'pt') {
            //     params.storeName = this.searchItem.query;
            // }
            // else if (this.type === 'tuan') {
            //     params.zhangOpenid = this.searchItem.query;
            // }
            // else {
            //     params.query = this.searchItem.query;
            // }
            // this.$emit('search', params);

            this.$emit('search', this.searchItem);
        },
        getTime(value, isDate) {
            this.searchItem.time = value;
            if (!isDate) {
                this.searchFun();
            }
        },
        celarTime() {
            this.searchItem.time = [];
            this.searchFun();
        }
    }
}
</script>

<style lang="less" scoped>
    .search-box::after {
        content: " ";
        display: block;
        clear: both;
    }
    .filter {
        margin-top: 20px;
    }
    .filter-status {
        float: left;
        margin-left: 20px;
    }
    .filter-status-btn {
        margin-right: 10px;
    }
    .filter-foo {
        margin-top: 15px;
    }
    .filter-head::after,
    .filter-foo::after,
    .filter-foo-time::after {
        content: " ";
        display: block;
        clear: both;
    }
    .filter-foo-search {
        float: left;
        width: 300px;
    }
    .filter-foo-time {
        float: left;
    }
    .filter-foo-date {
        float: left;
        padding-left: 10px;
    }
    .filter-foo-datepicker {
        width: 360px;
    }
    .filter-foo-btn {
        border-radius: 0 4px 4px 0;
    }
    .search-input {
        width: 350px;
    }
    .search-openid {
        float: left;

        .search-select {
            width: 200px;
        }
    }

    ::v-deep {
        .search-openid .ivu-form-item-content {
            line-height: 30px;
        }
        .filter-foo-datepicker .ivu-input {
            border-radius: 4px 0 0 4px;
        }
    }
</style>
