<template>
    <div class="list-container">
        <Form
            class="search-form"
            ref="searchItem"
            inline
            :model="searchItem"
            @keydown.native.enter.prevent
        >
            <Form-item prop="query">
                <Input
                    v-model="searchItem.query"
                    class="search-input"
                    clearable
                    search
                    enter-button="搜索"
                    placeholder="请输入账户关键字"
                    @on-search="onSearch"
                    @on-clear="onSearch"
                />
            </Form-item>
        </Form>

        <Table border :context="self" :columns="columns" :data="list"/>

        <div v-if="list && list.length > 0 && total > rn" class="list-page">
            <Page
                :current="pn + 1"
                :total="total"
                :page-size="rn"
                show-total
                @on-change="onPageChange"
            />
        </div>

        <Modal
            v-model="showModal"
            title="修改账户"
            :mask-closable="false"
            @on-ok="handleSubmit"
            @on-cancel="closeModal"
        >
            <Form
                class="add-form"
                ref="formItem"
                :model="formItem"
                :rules="ruleValidate"
                :label-width="100"
                label-position="right"
            >
                <Form-item prop="name" label="账号">
                    <div class="account-name">{{ formItem.name }}</div>
                </Form-item>

                <!-- <Form-item prop="oldPassword" label="旧密码">
                    <Input
                        v-model="formItem.oldPassword"
                        class="add-200"
                        type="password"
                        placeholder="请输入旧密码"
                    />
                </Form-item> -->

                <Form-item prop="password" label="新密码">
                    <Input
                        v-model="formItem.password"
                        class="add-200"
                        type="password"
                        placeholder="请输入新密码"
                    />
                </Form-item>

                <Form-item prop="againPassword" label="确认密码">
                    <Input
                        v-model="formItem.againPassword"
                        class="add-200"
                        type="password"
                        placeholder="请再次输入密码"
                    />
                </Form-item>
            </Form>

            <div slot="footer" class="modal-footer">
                <Button
                    class="add-submit"
                    type="primary"
                    long
                    @click="handleSubmit"
                >
                    提交
                </Button>
            </div>
        </Modal>
    </div>
</template>

<script>
export default {
    name: 'AccountList',
    components: {
    },
    data() {
        const confirmOldPassword = (rule, value, callback) => {
            if ((!value && !this.formItem.password && !this.formItem.againPassword) || !value && this.formItem.password) {
                callback(new Error('请输入旧密码'));
            }
            else {
                callback();
            }
        };

        const confirmPassword = (rule, value, callback) => {
            if (this.formItem.oldPassword && !value) {
                callback(new Error('请输入新密码'));
            }
            else {
                callback();
            }
        };

        const confirmAgainPassword = (rule, value, callback) => {
            if (this.formItem.password && !value) {
                callback(new Error('请确认新密码'));
            }
            else if ((this.formItem.password || value) && value !== this.formItem.password) {
                callback(new Error('两次输入的密码不一致'));
            }
            else {
                callback();
            }
        };

        return {
            self: this,
            columns: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '账户名称',
                    key: 'name',
                    minWidth: 150,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {
                            class: 'account-name'
                        }, params.row.name);
                    }
                },
                {
                    title: '账户密码',
                    key: 'password',
                    minWidth: 150,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {}, params.row.password);
                    }
                },
                {
                    title: '店铺名称',
                    key: 'storeName',
                    minWidth: 250,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {}, params.row.storeName || '无');
                    }
                },
                {
                    title: '账户权限',
                    key: 'storeName',
                    minWidth: 250,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', {}, params.row.rank === '1' ? '平台管理员' : '店铺管理员');
                    }
                },
                {
                    title: '创建时间',
                    key: 'dateTime',
                    align: 'center',
                    width: 200
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 150,
                    align: 'center',
                    fixed: 'right',
                    render: (h, params) => {
                        return h('div', {}, [
                            h('Button', {
                                class: 'ivu-btn-primary ivu-btn-small class-btn',
                                on: {
                                    click: () => {
                                        this.openModal(params.row);
                                    }
                                }
                            }, '编辑'),
                            h('Button', {
                                class: 'ivu-btn-error ivu-btn-small class-btn',
                                on: {
                                    click: () => {
                                        this.remove(params.index);
                                    }
                                }
                            }, '删除')
                        ]);
                    }
                }
            ],
            list: [],
            total: 0,
            pn: 0,
            rn: 10,
            searchItem: {
                query: ''
            },
            showModal: false,
            formItem: {
                oldPassword: '',
                password: '',
                againPassword: ''
            },
            ruleValidate: {
                oldPassword: [
                    {min: 6, message: '密码不能低于6位数', trigger: 'blur'},
                    {required: false, validator: confirmOldPassword, trigger: 'change'}
                ],
                password: [
                    {min: 6, message: '密码不能低于6位数', trigger: 'blur'},
                    {required: false, validator: confirmPassword, trigger: 'change'}
                ],
                againPassword: [
                    {required: false, validator: confirmAgainPassword, trigger: 'blur'}
                ]
            },
            cacheItem: null
        }
    },
    computed: {
    },
    mounted() {
        this.cacheItem = JSON.parse(JSON.stringify(this.searchItem));
        this.getData();
    },
    methods: {
        openModal(data) {
            console.log('data', data);
            this.formItem = JSON.parse(JSON.stringify(data));
            console.log('this.formItem', this.formItem);
            this.showModal = true;
        },
        
        // 删除当前用户
        remove(index) {
            this.$Modal.confirm({
                title: '提示',
                content: '确认要删除吗？',
                onOk: () => {
                    this.$axios({
                        url: '/api/account/delete',
                        method: 'post',
                        data: {
                            accountName: this.list[index].name
                        }
                    }).then(res => {
                        const {total, message} = res.data;
                        if (this.pn > 1) {
                            this.pn = Math.ceil(total / this.rn) > this.pn ? this.pn : Math.ceil(total / this.rn);
                        }
                        this.getData(this.pn);
                        this.$Message.success(message);
                    }).catch(() => {});
                }
            });
        },
        handleSubmit() {
            console.log('this.formItem', this.formItem);
            this.$refs['formItem'].validate(valid => {
                if (!valid) {
                    this.$Message.error('存在填写错误，请检查');
                    return;
                }

                this.$axios({
                    url: '/api/account/add',
                    method: 'post',
                    data: Object.assign({}, this.formItem, {
                        level: 1
                    })
                }).then(res => {
                    if (res.data.code === 0) {
                        return;
                    }
                    console.log('res', res);
                    const formItem = JSON.parse(JSON.stringify(this.formItem));
                    console.log('formItem', formItem);
                    if (formItem.id) {
                        const findIndex = this.list.findIndex(item => item.id === formItem.id);
                        this.list.splice(findIndex, 1, formItem);
                    }
                    this.formItem.password = '';
                    this.formItem.againPassword = '';
                    this.$Message.success(res.data.message);
                    this.closeModal();
                }).catch(() => {});
            });
        },
        closeModal() {
            this.showModal = false;
            this.$refs['formItem'].resetFields();
        },
        onPageChange(pn) {
            this.pn = pn - 1;
            this.getData(this.pn);
        },
        getData(pn) {
            this.$axios({
                url: '/api/account/list',
                method: 'post',
                data: Object.assign({}, this.searchItem, {
                    pn,
                    rn: this.rn
                })
            }).then(res => {
                const {list, total} = res.data;
                this.list = list;
                if (+this.total !== +total) {
                    this.total = total;
                }
            }).catch(() => {});
        },
        onSearch() {
            this.pn = 0;
            this.getData(this.pn);
        },
        vipPush(data) {
            this.$router.push({
                path: '/tuan/tuanDetail',
                query: {
                    id: data.id
                }
            });
        }
    }
}
</script>

<style lang="less" scoped>
    .search-form {
        padding-top: 8px;
    }
    .search-input {
        width: 350px;
    }
    .list-page {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }
    .add-form {
        padding-right: 30px;
        margin-top: 10px;
    }
    ::v-deep {
        .account-name {
            font-weight: bold;
        }
        .class-btn {
            margin: 0 5px;
        }
    }
</style>
