<template>
    <div class="list-container">
        <Button
            class="add-btn"
            type="primary"
            @click="openCoupon"
        >
            添加优惠券设置
        </Button>

        <Table border :context="self" :columns="columns" :data="list"/>

        <div v-if="list && list.length > 0 && total > rn" class="list-page">
            <Page
                :current="pn + 1"
                :total="total"
                :page-size="rn"
                show-total
                @on-change="onPageChange"
            />
        </div>

        <!-- 添加优惠券 -->
        <CouponModal
            :data="formItem"
            :visibility="visibility"
            @ok="okModal"
            @close="closeModal"
        />
    </div>
</template>

<script>
import CouponModal from '@/components/couponModal/index';

export default {
    name: 'CouponInfo',
    components: {
        CouponModal
    },
    data() {
        return {
            self: this,
            columns: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '类型',
                    key: 'type',
                    minWidth: 100,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {}, params.row.type === '1' ? '满减优惠' : '免单');
                    }
                },
                {
                    title: '满足优惠金额(元)',
                    key: 'amount',
                    minWidth: 150,
                    align: 'center'
                },
                {
                    title: '满减(元)',
                    key: 'manjian',
                    minWidth: 100,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', {}, params.row.type === '1' ? params.row.manjian : '');
                    }
                },
                {
                    title: '优惠(元)',
                    key: 'youhui',
                    minWidth: 100,
                    align: 'center'
                },
                {
                    title: '截止日期',
                    key: 'endTime',
                    minWidth: 180,
                    align: 'center'
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 150,
                    align: 'center',
                    fixed: 'right',
                    render: (h, params) => {
                        return h('div', {}, [
                            h('Button', {
                                class: 'ivu-btn-primary ivu-btn-small class-btn',
                                on: {
                                    click: () => {
                                        this.edit(params.index);
                                    }
                                }
                            }, '编辑'),
                            h('Button', {
                                class: 'ivu-btn-error ivu-btn-small class-btn',
                                on: {
                                    click: () => {
                                        this.remove(params.index);
                                    }
                                }
                            }, '删除')
                        ]);
                    }
                }
            ],
            list: [],
            total: 0,
            pn: 0,
            rn: 10,
            formItem: {},
            visibility: false
        }
    },
    mounted() {
        this.getData(this.pn);
    },
    methods: {
        openCoupon() {
            this.visibility = true;
        },
        okModal(data) {
            this.closeModal();

            this.$axios({
                url: '/api/coupon/addSetup',
                method: 'post',
                data
            }).then(res => {
                if (res.data.code === 0) {
                    return;
                }

                this.getData(this.pn);
                
                this.$Message.success(res.data.message);
            }).catch(() => {});
        },
        closeModal() {
            this.visibility = false;
        },
        edit(index) {
            this.formItem = JSON.parse(JSON.stringify(this.list[index]));
            console.log('this.formItem', this.formItem);
            this.visibility = true;
        },
        remove(index) {
            this.$Modal.confirm({
                title: '提示',
                content: '确认要删除吗？',
                onOk: () => {
                    this.$axios({
                        url: '/api/coupon/deleteSetup',
                        method: 'post',
                        data: {
                            id: this.list[index].id
                        }
                    }).then(res => {
                        const {total, message} = res.data;
                        if (this.pn > 1) {
                            this.pn = Math.ceil(total / this.rn) > this.pn ? this.pn : Math.ceil(total / this.rn);
                        }
                        this.getData(this.pn);
                        this.$Message.success(message);
                    }).catch(() => {});
                }
            });
        },
        onPageChange(pn) {
            this.pn = pn - 1;
            this.getData(this.pn);
        },
        getData(pn) {
            this.$axios({
                url: '/api/coupon/setupList',
                method: 'post',
                data: {
                    pn,
                    rn: this.rn
                }
            }).then(res => {
                const {list, total} = res.data;
                if (list.length === 0) {
                    return;
                }
                this.list = list;
                if (!this.total) {
                    this.total = total;
                    console.log('this.total', this.total);
                }
            }).catch(() => {});
        }
    }
}
</script>

<style lang="less" scoped>
    .list-page {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }
    ::v-deep {
        .class-btn {
            margin: 0 5px;
        }
    }
</style>
