var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"upload"},[_c('div',{staticClass:"upload-box"},[(_vm.uploadList.length > 0)?_vm._l((_vm.uploadList),function(item,index){return _c('div',{key:'uploadList' + index,staticClass:"upload-list",style:({
                    width: (_vm.width + "px"),
                    height: (_vm.height + "px"),
                    lineHeight: (_vm.height + "px")
                })},[(item.status === 'finished')?[_c('img',{staticClass:"upload-list-img",attrs:{"src":item.url}}),_c('div',{staticClass:"upload-list-cover"},[_c('Icon',{attrs:{"type":"ios-trash-outline","size":"24"},nativeOn:{"click":function($event){return _vm.handleRemove(item, index)}}})],1)]:[(item.showProgress)?_c('Progress',{attrs:{"percent":item.percentage,"hide-info":""}}):_vm._e()]],2)}):_vm._e(),_c('Upload',{directives:[{name:"show",rawName:"v-show",value:(_vm.uploadList.length < +_vm.maxLength),expression:"uploadList.length < +maxLength"}],ref:"upload",staticClass:"upload-wrap",style:({
                width: (_vm.width + "px"),
                height: ((_vm.height - 2) + "px"),
                lineHeight: ((_vm.height - 2) + "px")
            }),attrs:{"show-upload-list":false,"default-file-list":_vm.list,"on-success":_vm.handleSuccess,"accept":_vm.accept,"max-size":_vm.size,"on-format-error":_vm.handleFormatError,"on-exceeded-size":_vm.handleMaxSize,"multiple":"","type":"drag","action":_vm.uploadUrl}},[_c('div',{staticClass:"upload-wrap-info"},[_c('Icon',{attrs:{"type":"ios-camera","size":"28"}})],1)])],2),(_vm.sizeTip || _vm.ratio.length > 0)?_c('div',{staticClass:"flexbox upload-tips"},[_c('div',{staticClass:"flexbox-fixed"},[_vm._v("注意：")]),_c('div',{staticClass:"flexbox-auto"},[(_vm.sizeTip)?_c('p',{staticClass:"upload-wrap-info-p"},[_vm._v("图片大小不能超过"+_vm._s(_vm.newSize))]):_vm._e(),(_vm.ratio.length > 0)?_c('p',{staticClass:"upload-wrap-info-p"},[_vm._v("图片尺寸比例要求在"+_vm._s(_vm.ratio[0])),_c('span',[_vm._v("*")]),_vm._v(_vm._s(_vm.ratio[1]))]):_vm._e()])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }