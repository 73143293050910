<template>
    <div class="register">
        <Form
            class="register-form"
            ref="formItem"
            :model="formItem"
            :rules="ruleValidate"
            :label-width="100"
            label-position="right"
            autocomplete="off"
        >
            <h1 class="register-title">账号注册</h1>
            <Form-item prop="nickname" label="昵称">
                <Input v-model="formItem.nickname" placeholder="请输入昵称"/>
            </Form-item>
            <Form-item prop="name" label="账号">
                <Input v-model="formItem.name" placeholder="请输入账号"/>
            </Form-item>
            <Form-item prop="password" label="密码">
                
                <Input
                    type="password"
                    v-model="formItem.password"
                    placeholder="请输入密码"
                />
            </Form-item>
            <Form-item prop="againPassword" label="确认密码">
                <Input
                    type="password"
                    v-model="formItem.againPassword"
                    placeholder="请再次输入密码"
                />
            </Form-item>
            <Form-item prop="rank" label="类型">
                <RadioGroup v-model="formItem.rank">
                    <Radio class="setup-radio" label="1">系统超级管理员</Radio>
                    <Radio class="setup-radio" label="2">商铺管理员</Radio>
                </RadioGroup>
            </Form-item>
            <Form-item prop="pic" label="头像">
                <CUpload
                    :default-list="formItem.pic"
                    :maxLength="1"
                    :size="300"
                    sizeTip
                    :ratio="[150, 150]"
                    @change="onChange"
                />
            </Form-item>
            <Form-item>
                <Button
                    class="register-btn"
                    type="primary"
                    @click="handleSubmit"
                >
                    注册
                </Button>
                <Button class="register-btn" @click="login">
                    去登录
                </Button>
            </Form-item>
        </Form>
    </div>
</template>

<script>
import CUpload from '@/components/upload/index';

export default {
    name: 'register',
    components: {
        CUpload
    },
    data () {
        const confirmPassword = (rule, value, callback) => {
            if (this.formItem.password === value) {
                callback();
            }
            else {
                callback(new Error('两次输入的密码不一致'));
            }
        };

        return {
            formItem: {
                nickname: '',
                name: '',
                password: '',
                againPassword: '',
                rank: '1',
                pic: []
            },
            ruleValidate: {
                name: [
                    {required: true, message: '请输入账号', trigger: 'blur'},
                    {min: 3, message: '账号不能低于3位数', trigger: 'blur'}
                ],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                    {min: 6, message: '密码不能低于6位数', trigger: 'blur'}
                ],
                againPassword: [
                    {required: true, validator: confirmPassword, trigger: 'blur'}
                ]
            }
        }
    },
    mounted() {
    },
    methods: {
        handleSubmit() {
            this.$refs['formItem'].validate(valid => {
                if (!valid) {
                    this.$Message.error('存在填写错误，请检查');
                    return;
                }
                // this.formItem.password = this.$md5(this.formItem.password);
                // this.formItem.againPassword = this.$md5(this.formItem.againPassword);

                this.$axios({
                    url: '/api/login',
                    method: 'post',
                    data: Object.assign({}, this.formItem, {
                        type: 'register'
                    })
                }).then(res => {
                    if (res.data.code === 0) {
                        return;
                    }
                    
                    this.$Message.success(`账号 <b>${this.formItem.name}</b> ${res.data.message}`);

                    // 保存账号信息和token
                    this.$store.commit('updataAccount', res.data.list[0]);
                    this.$store.commit('updataToken', res.headers['token']);

                    this.$router.push({ path: '/' });
                }).catch(() => {});
            })
        },
        onChange(value) {
            this.formItem.pic = value;
        },
        login() {
            this.$router.push({
                path: '/login'
            });
        }
    }
}
</script>

<style scoped lang="less">
.register {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url('../../assets/bg.jpg') no-repeat 0 0;
    background-size: 100% 100%;

    .register-title {
        text-align: center;
        font-size: 20px;
        padding-bottom: 10px;
    }

    .register-form {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 70%;
        max-width: 600px;
        max-height: 80%;
        overflow: hidden;
        overflow-y: auto;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        padding-top: 20px;
        background-color: #fff;
    }

    .register-btn {
        margin-right: 10px;
    }
}
</style>
<style lang="less">
.register-form .ivu-form-item {
    margin-right: 50px;
}
</style>
