<template>
    <div class="comment-wrap">
        <div v-if="list.length > 0" class="comment-list">

            <div
                v-for="(item, index) in list"
                class="comment-box"
                :key="item.id"
            >
                <div class="comment-box-left">
                    <Avatar
                        size="80"
                        icon="ios-person"
                        :src="item.src"
                    />
                </div>
                <div class="comment-box-right">
                    <div class="comment-box-top">
                        <div class="comment-box-title">{{ item.name }}{{ item.goodsName ? ` (${item.goodsName})` : '' }}</div>
                        <div class="comment-box-time">评论时间 {{ item.dateTime }}</div>
                    </div>
                    <Rate
                        v-model="item.rate"
                        allow-half
                        disabled
                    />
                    <div class="comment-box-comment">{{ item.comment }}</div>
                    <div class="comment-box-btns">
                        <span class="comment-status">
                            状态: 
                            <span
                                :class="[item.status === '0' ? 'comment-status-text' : (item.status === '1' ? 'comment-status-text1' : 'comment-status-text2')]"
                            >
                                {{ item.status === '0' ? '未审核' : (item.status === '1' ? '审核通过' : '审核未通过') }}
                            </span>
                        </span>
                        <span class="comment-examine" @click="onExamine(item, index)">{{ item.status === '1' ? '取消审核' : '审核' }}</span>
                        <span class="comment-delete" @click="onDelete(index)">删除</span>
                    </div>
                </div>
            </div>

        </div>
        <div v-if="list && list.length > 0 && total > rn" class="list-page">
            <Page
                :current="pn + 1"
                :total="total"
                :page-size="rn"
                show-total
                @on-change="onPageChange"
            />
        </div>
        <Modal
            v-model="showModal"
            title="审核"
            :mask-closable="false"
            @on-ok="handleSubmit"
            @on-cancel="closeModal"
        >
            <Form
                class="add-form"
                ref="formItem"
                :model="formItem"
                :rules="ruleValidate"
                :label-width="80"
                label-position="right"
            >
                <Form-item prop="status" label="审核">
                    <RadioGroup v-model="formItem.status">
                        <Radio class="setup-radio" label="1">通过</Radio>
                        <Radio class="setup-radio" label="2">不通过</Radio>
                    </RadioGroup>
                </Form-item>
            </Form>
            <div slot="footer" class="modal-footer">
                <Button
                    class="add-submit"
                    type="primary"
                    long
                    @click="handleSubmit"
                >
                    提交
                </Button>
            </div>
        </Modal>
    </div>
</template>

<script>
export default {
    props: {

        // 评论的分类 为''表示获取全部分类 '1'表示获取已审核分类 '2'表示获取未审核分类
        classify: {
            type: String,
            default: '0'
        }
    },
    data() {
        return {
            rate: 3,
            list: [],
            total: 0,
            pn: 0,
            rn: 10,
            storeId: null,
            showModal: false,
            cacheData: null,
            formItem: {
                status: ''
            },
            ruleValidate: {
                status: [
                    {required: true, message: '请选择审核状态', trigger: 'blur'},
                ]
            }
        }
    },
    computed: {
        getStoreData() {
            return this.$store.state.storeData;
        }
    },
    watch: {
        getStoreData: {
            immediate: true,
            handler(storeData) {
                if (!storeData) return;
                this.cacheData = JSON.parse(JSON.stringify(this.formItem));
                this.storeId = +storeData.id;
                this.getData(this.pn);
            }
        }
    },
    mounted() {

    },
    methods: {
        getData(pn) {
            this.$axios({
                url: '/api/comment/list',
                method: 'post',
                data: Object.assign({}, this.searchItem, {
                    pn,
                    rn: this.rn,
                    storeId: this.storeId,
                    status: this.classify
                })
            }).then(res => {
                const {list, total} = res.data;
                this.list = list;
                if (+this.total !== +total) {
                    this.total = total;
                }
            }).catch(() => {});
        },
        onPageChange(pn) {
            this.pn = pn - 1;
            this.getData(this.pn);
        },
        onExamine(data, index) {
            console.log('data', data, index);
            this.showModal = true;
            this.formItem = Object.assign({}, this.formItem, {
                id: data.id,
                status: data.status
            });
        },
        onDelete(index) {
            console.log('index', index);
            this.$Modal.confirm({
                title: '提示',
                content: '确认要删除吗？',
                onOk: () => {
                    this.$axios({
                        url: '/api/comment/delete',
                        method: 'post',
                        data: {
                            id: this.list[index].id
                        }
                    }).then(res => {
                        const {total, message} = res.data;
                        if (this.pn > 1) {
                            this.pn = Math.ceil(total / this.rn) > this.pn ? this.pn : Math.ceil(total / this.rn);
                        }
                        this.getData(this.pn);
                        this.$Message.success(message);
                    }).catch(() => {});
                }
            });
        },
        closeModal() {
            this.showModal = false;
            this.formItem = JSON.parse(JSON.stringify(this.cacheData));
        },
        handleSubmit() {
            console.log('this.formItem', this.formItem);
            this.$refs['formItem'].validate(valid => {
                if (!valid) {
                    this.$Message.error('存在填写错误，请检查');
                    return;
                }

                this.$axios({
                    url: '/api/comment/add',
                    method: 'post',
                    data: this.formItem
                }).then(res => {
                    if (res.data.code === 0) {
                        return;
                    }

                    this.showModal = false;
                    this.formItem = JSON.parse(JSON.stringify(this.cacheData));
                    
                    this.getData(this.pn);

                    this.$Message.success(res.data.message);
                }).catch(() => {});
            })
        }
    }
}
</script>

<style lang="less" scoped>
    .comment-box {
        position: relative;
    }
    .comment-box:not(:first-child) {
        margin-top: 15px;
    }
    .comment-box::after {
        content: " ";
        display: block;
        clear: both;
    }
    .comment-box-left {
        position: absolute;
        left: 0;
        top: 0;
        width: 80px;
        height: 80px;
    }
    .comment-box-right {
        min-height: 80px;
        margin-left: 90px;
        border-bottom: 1px solid #cdcdcd;
    }
    .comment-box-top::after {
        content: " ";
        display: block;
        clear: both;
    }
    .comment-box-title {
        width: 50%;
        float: left;
    }
    .comment-box-time {
        width: 220px;
        float: right;
        text-align: right;
        color: #888;
    }
    .comment-box-btns {
        text-align: right;
        padding-bottom: 10px;

        .comment-status {
            color: #888;
        }
        .comment-status-text {
            color: #555;
        }
        .comment-status-text1 {
            color: #19be6b;
        }
        .comment-status-text2 {
            color: #f00;
        }
        .comment-examine,
        .comment-delete {
            margin-left: 10px;
            color: #48a9f7;
            cursor: pointer;
        }
    }
    .list-page {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }
    .add-form {
        margin-top: 20px;

        .setup-radio {
            margin-right: 20px;
        }
    }
    .modal-footer {
        text-align: right;
    }
    .add-submit {
        width: 100px;
    }
</style>

<style lang="less">
    .comment-box-left .ivu-avatar>img {
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
    }
</style>
