<template>
    <div id="app">
        <div v-if="isShow" id="head">
            <div class="header">
                <div class="header-pc">
                    <div class="header-left">
                        <img src="./assets/logo.png">
                        <span>{{ title }}</span>
                    </div>
                    <div v-if="account && +account.rank === 1" class="header-back">
                        <router-link to="/">返回首页</router-link>
                    </div>
                    <div v-if="account" class="header-right">
                        <ul class="account">
                            <li class="account-li">
                                <!-- <Poptip v-model="visible">
                                    <div class="account-box">
                                        <Icon
                                            class="account-icon"
                                            type="md-contact"
                                            size="18"
                                        />
                                        {{ account.nickname ? account.nickname : account.name }}({{ account.rank === '1' ? '系统超级管理员' : '商铺管理员' }})
                                    </div>
                                    <template #title>
                                        <div>我的账户</div>
                                    </template>
                                    <template #content>
                                        <a @click="close">close</a>
                                    </template>
                                </Poptip> -->
                                <div class="account-box">
                                    <Icon
                                        class="account-icon"
                                        type="md-contact"
                                        size="18"
                                    />
                                    {{ account.nickname ? account.nickname : account.name }}({{ account.rank === '1' ? '系统超级管理员' : '商铺管理员' }})
                                </div>
                            </li>
                            <li class="account-li account-li-close" @click="logout">
                                <Tooltip content="退出登录" placement="bottom">
                                    <Icon
                                        class="account-close"
                                        type="md-power"
                                        size="16"
                                    />
                                </Tooltip>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="header-wise">
                    <Icon
                        class="header-wise-menu"
                        type="md-menu"
                        :style="{
                            color: `${isMobile && !openWiseNav ? '' : '#fff'}`,
                            backgroundColor: `${isMobile && !openWiseNav ? '' : '#24303c'}`
                        }"
                        @click="openNav"
                    />
                    <div class="header-wise-title">{{ title }}</div>
                </div>
            </div>
        </div>
        <div
            v-if="isShow && navData && navData.length > 0"
            class="nav"
            :style="{
                width: `${navWidth}px`,
                transform: `translateX(${isMobile && !openWiseNav ? '-100%' : '0'})`
            }"
        >
            <div v-if="isStore || +account.rank === 2" class="avatar-wrap">
                <Avatar
                    class="avatar-phone"
                    :src="storeData && storeData.src"
                    shape="square"
                    icon="ios-person"
                />
                <div class="clamp2 avatar-name">{{ storeData && storeData.name }}</div>
            </div>
            <ul>
                <li v-for="(item, index) in navData" :key="item.meta.name + index">
                    <!-- width: `${navWidth - 20}px`, -->
                    <router-link
                        v-if="item.meta"
                        :to="{
                            path: item.path,
                            query: item.meta.query ? item.meta.query : {}
                        }"
                        :style="{
                            width: '100%'
                        }"
                        @click.native="closeNav"
                    >
                        <Icon class="nav-icon" :type="item.meta.iconfont" size="20" />{{ item.meta.title }}
                    </router-link>
                </li>
            </ul>
        </div>

        <div
            class="container"
            :style="{
                paddingLeft: `${isMobile ? 0 : navWidth}px`
            }"
        >
            <router-view/>
            <Spin
                v-if="spinShow"
                class="spin"
                size="large"
                fix
                :style="{
                    left: `${navWidth + subNavWidth}px !important`,
                    right: '0 !important',
                    width: 'auto'
                }"
            />
        </div>
    </div>
</template>

<script>
import project from '../project';

export default {
    name: 'App',
    data() {
        return {
            // avatar: null,
            // navData: [],
            // storeData: null,
            visible: false,
            // isStore: null
        }
    },
    computed: {
        title() {
            return project.title;
        },
        isShow() {
            return this.$store.state.show;
        },
        navData() {
            return this.$store.state.navData;
        },
        navWidth() {
            return this.$store.state.navWidth;
        },
        subNavWidth() {
            return this.$store.state.subNavWidth;
        },
        storeData() {
            return this.$store.state.storeData;
        },
        isStore() {
            return this.$store.state.isStore;
        },
        account() {
            return this.$store.state.account;
        },
        spinShow() {
            return this.$store.state.spinShow;
        },
        isMobile() {
            return this.$store.state.isMobile;
        },
        openWiseNav() {
            return this.$store.state.openWiseNav;
        }
    },
    mounted() {
        this.$store.commit('getIsMobile');
        console.log('title', this.title);
    },
    methods: {
        close () {
            this.visible = false;
        },
        logout() {
            this.$store.commit('updataToken', null);
            this.$store.commit('updataAccount', null);
            this.$store.commit('updataStoreData', null);

            this.$router.push({
                path: '/login'
            });
        },
        openNav() {
            this.$store.commit(this.openWiseNav ? 'enableScroll' : 'disableScroll');
            this.$store.commit('openWiseNavFn', this.openWiseNav ? false : true);
        },
        closeNav() {
            if (this.isMobile) {
                this.$store.commit('enableScroll');
                this.$store.commit('openWiseNavFn', false);
            }
        }
    }
}
</script>

<style>
    @import "./css/common.less";
</style>

<style scoped lang="less">
    #head {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
        height: 50px;
        background-color: #fff;
        border-bottom: 1px solid #eee;
    }
    .header {
        width: auto;
        height: 50px;
        color: #666;
        padding: 0 20px;

        &-wise {
            display: none;
        }

        &-left,
        &-back,
        &-right {
            height: 100%;
            float: left;
        }

        &-right {
            float: right;
        }

        &-left {
            padding-top: 8px;
        }
        &-left img {
            width: 34px;
            height: 34px;
            // border-radius: 50%;
            background-color: #009B4D;
        }

        &-left span {
            margin-left: 10px;
            margin-top: 5px;
            font-size: 14px;
        }

        &-back {
            position: relative;
            margin-left: 10px;
        }
        &-back a {
            display: inline-block;
            height: 100%;
            line-height: 48px;
            font-size: 14px;
            text-decoration: none;
            color: #666;
            padding: 0 20px;
        }
        &-back a:hover {
            color: #3296fa;
        }
        &-back::after {
            content: "";
            width: 1px;
            height: 30px;
            background: #eee;
            position: absolute;
            right: 0;
            top: 10px;
        }
    }
    .header::after {
        content: " ";
        display: block;
        clear: both;
    }

    .nav {
        position: fixed;
        left: 0;
        top: 50px;
        bottom: 0;
        z-index: 10;
        width: 150px;
        overflow: hidden;
        overflow-y: scroll;
        background-color: #24303c;
    }
    .nav ul {
        padding-top: 10px;
    }
    .nav ul li {
        height: 50px;
        line-height: 50px;
        font-size: 14px;
        cursor: pointer;
    }
    .nav ul li a {
        color: #ddd;
        display: block;
        width: 130px;
        border-left: 3px solid #24303c;
    }
    .nav ul li .nav-icon {
        position: relative;
        top: 1px;
        color: #8d8d8d;
        margin: 0 8px;
    }
    .container {
        padding-top: 50px;
        padding-left: 150px;
    }
    .account::after {
        content: " ";
        display: block;
        clear: both;
    }
    .account,
    .account-box {
        height: 100%;
        line-height: 48px;
        color: #333;
    }
    // .account-box,
    .account-li-close {
        cursor: pointer;
    }
    .account-icon {
        position: relative;
        top: 1px;
    }
    .account-li {
        position: relative;
        float: left;
        padding: 0 15px;
    }
    .account .account-li:not(:first-child)::before {
        content: "";
        width: 1px;
        height: 30px;
        background: #eee;
        position: absolute;
        left: 0;
        top: 10px;
    }

    .avatar-wrap {
        text-align: center;
        color: #fff;
        padding: 20px 0 4px;
    }
    .avatar-phone {
        width: 60px !important;
        height: 60px !important;
    }
    .avatar-name {
        margin-top: 10px;
        min-height: 21px;
        padding: 0 7px 0 5px;
    }
    .spin {
        left: 260px !important;
        right: 0 !important;
        width: auto;
    }

    ::v-deep {
        .nav ul li a:hover,
        .nav ul li a.router-link-active {
            color: #fff;
            background-color: #39485D;
            border-left-color: #5fabf1;

            .nav-icon {
                color: #ddd;
            }
        }
    }

    @media (max-width: 767px) {
        .header-pc {
            display: none;
        }

        .header-wise {
            position: relative;
            display: block;
            height: 50px;
            line-height: 50px;

            &-menu {
                position: absolute;
                left: -20px;
                top: 0;
                font-size: 26px;
                cursor: pointer;
                width: 50px;
                height: 50px;
                box-sizing: border-box;
                padding: 12px;
            }

            &-title {
                text-align: center;
                font-size: 16px;
                font-weight: bold;
            }
        }

        .nav {
            transform: translateX(-100%);
        }
    }
</style>
