<template>
    <div class="info">
        <div class="title">资金统计</div>

        <Table
            stripe
            :context="self"
            :columns="columns"
            :data="list"
        />
    </div>
</template>

<script>
export default {
    props: {
        columns: {
            type: Array,
            default: () => {
                return []
            }
        },
        list: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    components: {
    },
    data() {
        return {
            self: this
        }
    },
    mounted() {
    },
    methods: {
    }
}
</script>

<style lang="less" scoped>
    .info {
        margin-top: 20px;
    }

    ::v-deep {
        .table-total {
            color: #f65b1a;
            font-weight: bold;
        }
        .red {
            color: #f65b1a;
            font-weight: bold;
        }
        .fuhao {
            position: relative;
            top: -2px;
            font-size: 26px;
        }
    }
</style>
